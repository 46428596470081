var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "content"
  }, [_c('back-button'), _c('div', {
    staticClass: "title"
  }, [_vm._v("Solshares at Site")]), _c('div', {
    staticClass: "wrapper"
  }, _vm._l(_vm.$store.state.systemInfo.solsharesAtSite, function (solshare, i) {
    return _c('div', {
      key: i,
      staticClass: "item",
      on: {
        "click": function ($event) {
          return _vm.debounced(solshare.serial);
        }
      }
    }, [_c('span', [_vm._v(_vm._s(solshare.serial))]), _c('div', {
      staticClass: "icon"
    }, [_c('PowerIcon', {
      attrs: {
        "color": _vm.isCritical(solshare) ? '#ff462c' : _vm.isNonCritical(solshare) ? '#ffe86c' : '#005149'
      }
    })], 1), _c('div', {
      staticClass: "icon"
    }, [_c('WifiIcon', {
      attrs: {
        "width": "17",
        "height": "12",
        "color": _vm.isOffline(solshare) ? '#FFE86C' : '#005149'
      }
    })], 1), _c('div', {
      staticClass: "icon"
    }, [_c('NetworkIcon', {
      attrs: {
        "color": _vm.isOffline(solshare) ? '#FFE86C' : '#005149'
      }
    })], 1), _c('SmallArrow')], 1);
  }), 0)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }