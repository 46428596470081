<template>
  <div class="content">
    <progress-bar :percent="60" />

    <div class="info-text">Which CT's were used for this SolShare?</div>

    <white-button
      v-if="$store.getters.is75A"
      data-tn="ct-rating-75a"
      bordered
      class="white-button"
      to="/commission/19"
      @click="() => ratingClicked(CT_RATING.A75)"
    >
      75A (DEFAULT)
    </white-button>

    <white-button
      v-else-if="!$store.getters.is2P"
      data-tn="ct-rating-120a"
      bordered
      class="white-button"
      to="/commission/19"
      @click="() => ratingClicked(CT_RATING.A120)"
    >
      120A (DEFAULT)
    </white-button>

    <white-button
      data-tn="ct-rating-200a"
      bordered
      class="white-button"
      to="/commission/19"
      @click="() => ratingClicked(CT_RATING.A200)"
    >
      {{ $store.getters.is2P ? "200A (DEFAULT)" : "200A" }}
    </white-button>

    <WarningSVG />
    <span class="small-text">If unsure, click the ‘Unsure’ button below:</span>

    <white-button
      bordered
      class="white-button"
      to="/commission/ct-rating-unsure"
      bg-color="rgba(229,248,240,0.7)"
    >
      UNSURE
    </white-button>

    <back-button />
  </div>
</template>

<script>
import WarningSVG from "../assets/svg/black-warning.svg";
import { CT_RATING } from "../util/constants";
export default {
  components: { WarningSVG },
  data() {
    return { CT_RATING };
  },
  created() {
    if (
      this.$store.getters.is2P &&
      this.$store.state.project.CTRating !== CT_RATING.A200
    ) {
      this.ratingClicked(CT_RATING.A200);
      this.$router.push("/commission/19");
    }
  },
  methods: {
    ratingClicked(rating) {
      this.$store.commit("setCTRating", rating);
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  .info-text {
    margin-top: 29px;
    margin-bottom: 30px;
  }

  .small-text {
    letter-spacing: -0.35px;
    margin-left: 5px;
  }

  .white-button {
    margin-top: 8px;
    margin-bottom: 13px;
  }
}
</style>
