var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "content"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v("Create account")]), _vm._m(0), _c('yellow-button', {
    staticClass: "submit",
    attrs: {
      "loading": _vm.loading,
      "to": "/login"
    }
  }, [_vm._v(" LOGIN ")])], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "info-text"
  }, [_c('p', [_vm._v("Your account has been created successfully!")])]);

}]

export { render, staticRenderFns }