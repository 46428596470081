var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "content"
  }, [_c('div', {
    staticClass: "info-text"
  }, [_vm._v(" Allow phone to open camera and scan QR code here. ")]), _c('qrcode-stream', {
    on: {
      "decode": _vm.onDecode,
      "init": _vm.onInit
    }
  }, [_vm.loading ? _c('div', {
    staticClass: "loading-indicator"
  }, [_vm._v("Opening Camera...")]) : _vm._e()]), _vm.error ? _c('div', [_vm._v(" " + _vm._s(_vm.error) + " ")]) : _vm._e(), _c('back-button')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }