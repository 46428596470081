var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "content"
  }, [_c('progress-bar', {
    attrs: {
      "percent": 20
    }
  }), _c('div', {
    staticClass: "center"
  }, [_c('CrossSvg', {
    staticClass: "cross"
  }), _c('div', {
    staticClass: "text"
  }, [_vm._v("Oh no! the update wasn't successful")])], 1), _c('yellow-button', {
    staticClass: "try-again",
    attrs: {
      "loading": _vm.loading
    },
    on: {
      "click": _vm.tryAgainClicked
    }
  }, [_vm._v(" OK, LET'S TRY AGAIN ")]), _vm.$store.state.softwareUpdate.forceUpdate === false ? _c('white-button', {
    staticClass: "continue",
    attrs: {
      "bordered": "",
      "to": "/commission/10"
    }
  }, [_vm._v(" CONTINUE ANYWAY ")]) : _vm._e(), _c('back-button')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }