var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "content"
  }, [_c('progress-bar', {
    attrs: {
      "percent": 60
    }
  }), _c('div', {
    staticClass: "info-text"
  }, [_vm._v("What solar delivery method is required?")]), !_vm.$store.getters.is2P ? _c('white-button', {
    staticClass: "white-button",
    attrs: {
      "data-tn": "demand-based-delivery",
      "bordered": "",
      "to": "/commission/ct-rating"
    },
    on: {
      "click": function () {
        return _vm.methodClicked(_vm.ALGORITHM_TYPE.DEMAND_BASED_DELIVERY);
      }
    }
  }, [_vm._v(" DEMAND-BASED DELIVERY ")]) : _vm._e(), _c('white-button', {
    staticClass: "white-button",
    attrs: {
      "data-tn": "custom-allocation",
      "bordered": "",
      "to": _vm.$store.getters.isUnequalAlgorithm ? '/commission/unequal-allocation-review' : '/commission/ct-rating'
    },
    on: {
      "click": function () {
        return _vm.methodClicked(_vm.ALGORITHM_TYPE.CUSTOM_ALLOCATION);
      }
    }
  }, [_vm._v(" CUSTOM ALLOCATION ")]), _c('WarningSVG'), _c('span', {
    staticClass: "small-text"
  }, [_vm._v("If unsure, click the ‘Unsure’ button below:")]), _c('white-button', {
    staticClass: "white-button",
    attrs: {
      "bordered": "",
      "to": "/commission/18",
      "bg-color": "rgba(229,248,240,0.7)",
      "data-tn": "solar-delivery-unsure"
    }
  }, [_vm._v(" UNSURE ")]), _c('back-button')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }