var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "content"
  }, [_c('progress-bar', {
    attrs: {
      "percent": 10
    }
  }), _c('TickSVG', {
    staticClass: "ok-icon"
  }), _c('div', {
    staticClass: "info-text"
  }, [_vm._v("This SolShare has already been commissioned.")]), _c('div', {
    staticClass: "small-text"
  }, [_vm._v("To reconfigure the unit, contact Allume.")]), _c('yellow-button', {
    staticClass: "contact-button",
    on: {
      "click": _vm.systemInfoClicked
    }
  }, [_vm.loading ? _c('a-icon', {
    attrs: {
      "type": "loading"
    }
  }) : _c('span', [_vm._v("VIEW SYSTEM INFO")])], 1), _c('yellow-button', {
    staticClass: "contact-button",
    attrs: {
      "to": "/contact"
    }
  }, [_vm._v(" CONTACT NOW ")]), _c('yellow-button', {
    attrs: {
      "to": "/commission/1"
    }
  }, [_vm._v(" COMMISSION ANOTHER UNIT ")]), _c('back-button', {
    style: {
      textAlign: 'left'
    },
    attrs: {
      "step": -2
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }