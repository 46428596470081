import { COLORS } from "./constants";

/**
 * get label prefix (R for AUS or L1- for UK, etc)
 * @param phaseIdx: phase index, starting at 0
 * @param isRWB: boolean
 * @returns {string}
 */
export function labelPrefix(phaseIdx, isRWB) {
  if (isRWB) {
    return "RWB".charAt(phaseIdx);
  }

  return `L${phaseIdx + 1}-`;
}

/**
 * get label text color for AUS (R, W, B)
 * @param phaseIdx: phase index, starting at 0
 * @param isRWB: boolean
 * @returns {string}
 */
export function labelColor(phaseIdx, isRWB) {
  if (isRWB) {
    return [COLORS.RED, COLORS.WHITE, COLORS.BLUE][phaseIdx];
  }

  return COLORS.WHITE;
}
