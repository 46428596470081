var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    style: {
      transform: `scale(${_vm.width ? _vm.width / 26 : _vm.height / 19})`
    },
    attrs: {
      "width": "26",
      "height": "19",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "m4.676 10.148 2.307 2.307a8.16 8.16 0 0 1 11.535 0l2.307-2.307c-4.458-4.459-11.69-4.459-16.15 0Zm4.614 4.614 3.46 3.46 3.461-3.46a4.898 4.898 0 0 0-6.921 0ZM.062 5.534 2.369 7.84c5.733-5.733 15.03-5.733 20.763 0l2.308-2.307c-7.008-7.008-18.37-7.008-25.378 0Z",
      "fill": _vm.color,
      "fill-rule": "evenodd"
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }