var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('a-modal', {
    attrs: {
      "width": "calc(100vw - 44px)",
      "body-style": {
        fontSize: '21px',
        lineHeight: '25px',
        padding: _vm.padding,
        background: _vm.color,
        fontFamily: 'Helvetica'
      },
      "mask-style": {
        background: 'rgba(62, 62, 62, 0.55)'
      },
      "dialog-style": {
        top: `${_vm.top}px`
      },
      "closable": _vm.closable,
      "after-close": _vm.afterClose
    },
    scopedSlots: _vm._u([{
      key: "closeIcon",
      fn: function () {
        return [_c('CloseSVG')];
      },
      proxy: true
    }, {
      key: "footer",
      fn: function () {
        return [_vm._t("footer", function () {
          return [_c('div', {
            staticStyle: {
              "display": "none"
            }
          })];
        })];
      },
      proxy: true
    }], null, true),
    model: {
      value: _vm.isVisible,
      callback: function ($$v) {
        _vm.isVisible = $$v;
      },
      expression: "isVisible"
    }
  }, [_vm.icon ? _c('BlackILineSVG', {
    style: {
      marginBottom: '9px'
    }
  }) : _vm._e(), _vm._t("default")], 2), _vm.isVisible && _vm.rightArrow && _vm.dom ? _c('ArrowRightSVG', {
    staticClass: "modal__arrow",
    style: {
      top: _vm.arrowTop,
      left: _vm.arrowLeft
    }
  }) : _vm._e(), _vm.isVisible && _vm.leftArrow && _vm.dom ? _c('ArrowLeftSVG', {
    staticClass: "modal__arrow",
    style: {
      top: _vm.arrowTop,
      left: _vm.arrowLeft
    }
  }) : _vm._e(), _vm.isVisible && _vm.downArrow && _vm.dom ? _c('ArrowDownSVG', {
    staticClass: "modal__arrow",
    style: {
      top: _vm.arrowBottom,
      left: _vm.arrowLeft
    }
  }) : _vm._e(), _vm.isVisible && _vm.target && _vm.dom ? _c(_vm.target, {
    tag: "component",
    staticClass: "modal__target-icon",
    style: {
      top: _vm.domTop,
      left: _vm.domLeft
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }