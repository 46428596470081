<template>
  <div class="green-border-box">
    <slot />
  </div>
</template>

<script>
export default {
  name: "GreenBorderBox",
};
</script>

<style lang="less" scoped>
.green-border-box {
  width: 100%;
  height: 50px;
  color: #005149;
  border: 1px solid #005149;
  font-family: "Helvetica Bold", sans-serif;
  font-size: 17px;
  line-height: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
}
</style>
