<template>
  <div class="content">
    <div class="text-1">
      Before commissioning ensure that this label is placed
      <span class="bold">on the electricity meter panel</span>:
    </div>

    <img src="../assets/png/safety-label-1.png" class="safety-label" />

    <div class="text-2">
      And this label is placed
      <span class="bold">adjacent the Tenancy Main Switches</span>
    </div>
    <img src="../assets/png/safety-label-2.png" class="safety-label" />

    <yellow-button
      v-if="!$route.query.info"
      to="/commission/1"
      data-tn="safety-label-confirm"
    >
      CONFIRM
    </yellow-button>
    <back-button />
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.content {
  padding-top: 52px;

  .text-1 {
    margin-bottom: 5px;
  }

  .text-2 {
    margin-top: 2px;
    margin-bottom: 5px;
  }
  .safety-label {
    width: 100%;
    margin-bottom: 30px;
  }
}
</style>
