var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "content"
  }, [_c('progress-bar', {
    attrs: {
      "percent": 5
    }
  }), _c('div', {
    staticClass: "title"
  }, [_vm._v("Sites")]), _c('div', {
    staticClass: "wrapper"
  }, _vm._l(_vm.$store.state.fleet, function (fleet, i) {
    return _c('div', {
      key: i,
      staticClass: "item",
      on: {
        "click": function () {
          return _vm.itemClicked(fleet);
        }
      }
    }, [_c('span', [_vm._v(_vm._s(fleet.address))]), _c('SmallArrow')], 1);
  }), 0), _vm._m(0), _c('yellow-button', {
    attrs: {
      "to": "/add-a-site",
      "data-tn": "add-site-button"
    },
    on: {
      "click": _vm.clearSite
    }
  }, [_vm._v(" ADD A SITE ")]), _c('back-button')], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "or-container"
  }, [_c('p', {
    staticClass: "big-or"
  }, [_vm._v("OR")])]);

}]

export { render, staticRenderFns }