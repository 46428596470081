var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('a-input', {
    ref: "autocomplete",
    class: _vm.classname,
    attrs: {
      "id": _vm.id,
      "data-tn": "google-autocomplete",
      "size": "large",
      "type": "text",
      "placeholder": _vm.placeholder,
      "disabled": _vm.disabled
    },
    on: {
      "focus": _vm.onFocus,
      "blur": _vm.onBlur,
      "change": _vm.onChange,
      "keypress": _vm.onKeyPress,
      "keyup": _vm.onKeyUp
    },
    model: {
      value: _vm.autocompleteText,
      callback: function ($$v) {
        _vm.autocompleteText = $$v;
      },
      expression: "autocompleteText"
    }
  });

}
var staticRenderFns = []

export { render, staticRenderFns }