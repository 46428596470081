<template>
  <div class="box">
    <a-icon
      :component="getContact(type).svg"
      :style="{ fontSize: '45px', marginBottom: '14px' }"
    />
    <div class="contact-title">
      {{ getContact(type).title }}
    </div>
    <div>
      <a class="contact-detail" :href="getContact(type).href">{{
        getContact(type).description
      }}</a>
    </div>
  </div>
</template>

<script>
import PhoneSVG from "../assets/svg/phone.svg";
import EmailSVG from "../assets/svg/email.svg";
import { COUNTRY_CODE, REGION_CODE, PHONE_NUMBERS } from "../util/constants";
export default {
  name: "GreyContactBox",
  props: {
    type: { type: String, default: null },
    region: { type: String, default: COUNTRY_CODE.Australia },
  },
  methods: {
    getContact(type) {
      if (type === "phone") {
        switch (this.region) {
          case REGION_CODE.Europe:
            return {
              svg: PhoneSVG,
              title: "Phone number",
              description: PHONE_NUMBERS.Europe,
              href: `tel:${PHONE_NUMBERS.Europe}`,
            };

          case REGION_CODE.America:
            return {
              svg: PhoneSVG,
              title: "Phone number",
              description: PHONE_NUMBERS.America,
              href: `tel:${PHONE_NUMBERS.America}`,
            };

          case REGION_CODE.Oceania:
          default:
            return {
              svg: PhoneSVG,
              title: "Phone number",
              description: `tel:${PHONE_NUMBERS.Oceania}`,
              href: `tel:${PHONE_NUMBERS.Oceania}`,
            };
        }
      }
      if (type === "email") {
        switch (this.region) {
          case REGION_CODE.Europe:
            return {
              svg: EmailSVG,
              title: "Email",
              description: "info@allumeenergy.com",
              href: "mailto:info@allumeenergy.com",
            };

          case REGION_CODE.America:
            return {
              svg: EmailSVG,
              title: "Email",
              description: "info@allumeenergy.com",
              href: "mailto:info@allumeenergy.com",
            };

          case REGION_CODE.Oceania:
          default:
            return {
              svg: EmailSVG,
              title: "Email",
              description: "support@allumeenergy.com.au",
              href: "mailto:support@allumeenergy.com.au",
            };
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
.box {
  background: #f3f4f4;
  text-align: center;
  padding: 25px;
  margin-bottom: 6px;
  height: 154px;
  font-size: 18px;

  .contact-title {
    font-family: "Helvetica Bold", sans-serif;
  }
  .contact-detail {
    color: #000;
  }
}
</style>
