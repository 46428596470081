var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "content"
  }, [_c('progress-bar', {
    attrs: {
      "percent": 50
    }
  }), _c('div', {
    staticClass: "info-text"
  }, [_vm._v("Edit connections:")]), _vm.isRecommissioning ? _c('green-info-box', {
    staticClass: "re-commission-note"
  }, [_vm._v(" Please note that edit connections in this page will trigger a re-commission for the Solshare " + _vm._s(_vm.$store.state.project.serialNumber) + ". ")]) : _vm._e(), _vm.$store.getters.is2P ? _c('div', [_c('unit-input-group-2P', {
    ref: "group1",
    staticClass: "phase-group",
    attrs: {
      "connection": 1,
      "hint": ""
    },
    model: {
      value: _vm.connection0,
      callback: function ($$v) {
        _vm.connection0 = $$v;
      },
      expression: "connection0"
    }
  }), _c('unit-input-group-2P', {
    ref: "group2",
    staticClass: "phase-group",
    attrs: {
      "connection": 3,
      "hint": ""
    },
    model: {
      value: _vm.connection1,
      callback: function ($$v) {
        _vm.connection1 = $$v;
      },
      expression: "connection1"
    }
  }), _c('unit-input-group-2P', {
    ref: "group3",
    staticClass: "phase-group",
    attrs: {
      "connection": 5,
      "hint": ""
    },
    model: {
      value: _vm.connection2,
      callback: function ($$v) {
        _vm.connection2 = $$v;
      },
      expression: "connection2"
    }
  }), _c('unit-input-group-2P', {
    ref: "group4",
    staticClass: "phase-group",
    attrs: {
      "connection": 7,
      "hint": ""
    },
    model: {
      value: _vm.connection3,
      callback: function ($$v) {
        _vm.connection3 = $$v;
      },
      expression: "connection3"
    }
  }), _c('unit-input-group-2P', {
    ref: "group5",
    staticClass: "phase-group",
    attrs: {
      "connection": 9,
      "hint": ""
    },
    model: {
      value: _vm.connection4,
      callback: function ($$v) {
        _vm.connection4 = $$v;
      },
      expression: "connection4"
    }
  })], 1) : _c('div', [_c('unit-input-group-3P', {
    ref: "group1",
    staticClass: "phase-group",
    attrs: {
      "connection": 1,
      "hint": ""
    },
    model: {
      value: _vm.connection0,
      callback: function ($$v) {
        _vm.connection0 = $$v;
      },
      expression: "connection0"
    }
  }), _c('unit-input-group-3P', {
    ref: "group2",
    staticClass: "phase-group",
    attrs: {
      "connection": 2,
      "hint": ""
    },
    model: {
      value: _vm.connection1,
      callback: function ($$v) {
        _vm.connection1 = $$v;
      },
      expression: "connection1"
    }
  }), _c('unit-input-group-3P', {
    ref: "group3",
    staticClass: "phase-group",
    attrs: {
      "connection": 3,
      "hint": ""
    },
    model: {
      value: _vm.connection2,
      callback: function ($$v) {
        _vm.connection2 = $$v;
      },
      expression: "connection2"
    }
  }), _c('unit-input-group-3P', {
    ref: "group4",
    staticClass: "phase-group",
    attrs: {
      "connection": 4,
      "hint": ""
    },
    model: {
      value: _vm.connection3,
      callback: function ($$v) {
        _vm.connection3 = $$v;
      },
      expression: "connection3"
    }
  }), _c('unit-input-group-3P', {
    ref: "group5",
    staticClass: "phase-group",
    attrs: {
      "connection": 5,
      "hint": ""
    },
    model: {
      value: _vm.connection4,
      callback: function ($$v) {
        _vm.connection4 = $$v;
      },
      expression: "connection4"
    }
  })], 1), _c('div', [_c('a-form-model', {
    ref: "form",
    attrs: {
      "model": {},
      "rules": _vm.rules
    }
  }, [_c('a-form-model-item', {
    attrs: {
      "prop": "connections"
    }
  }, [false ? _c('a-input') : _vm._e()], 1)], 1), _c('div', {
    staticClass: "small-text"
  }, [_vm._v("Please confirm once connections are correct:")]), _c('a-row', {
    attrs: {
      "type": "flex",
      "gutter": 10
    }
  }, [_c('a-col', {
    attrs: {
      "span": 12
    }
  }, [_c('yellow-button', {
    attrs: {
      "loading": _vm.loading,
      "data-tn": "confirm"
    },
    on: {
      "click": _vm.confirmClicked
    }
  }, [_vm._v(" CONFIRM ")])], 1), _c('a-col', {
    attrs: {
      "span": 12
    }
  })], 1), _c('back-button', {
    attrs: {
      "click-handler": _vm.backClicked
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }