<template>
  <div class="content">
    <progress-bar :percent="10" />

    <div class="info-text">
      How would you like to enter the unit’s serial number?
    </div>

    <BlackISVG class="info-icon" @click="noteVisible = true" />
    <yellow-button
      class="manual-button"
      to="/commission/3"
      data-tn="serial-number-manually"
    >
      MANUALLY
    </yellow-button>
    <yellow-button to="/commission/4">
      SCAN THE QR CODE <QRCodeSVG class="qrcode-icon" />
    </yellow-button>
    <back-button />

    <yellow-modal v-model="noteVisible" icon padding="62px 26px 21px 26px">
      <div :style="{ fontSize: '21px', marginBottom: '1em' }">
        The QR Code and Serial number can be found on the bottom right-hand side
        of the SolShare.
      </div>

      <template #footer>
        <img
          src="../assets/png/qrcode-location.png"
          :style="{ width: '100%' }"
        />
      </template>
    </yellow-modal>
  </div>
</template>

<script>
import BlackISVG from "../assets/svg/black-i-filled.svg";
import QRCodeSVG from "../assets/svg/qrcode.svg";

export default {
  components: { BlackISVG, QRCodeSVG },
  data() {
    return {
      noteVisible: false,
    };
  },
};
</script>

<style lang="less" scoped>
.content {
  .info-text {
    margin-top: 29px;
  }

  .info-icon {
    margin-top: 10px;
    margin-bottom: 20px;
  }
  .manual-button {
    margin-bottom: 15px;
  }
  .qrcode-icon {
    margin-left: 1em;
  }
}
</style>
