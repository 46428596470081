var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.hint ? _c('a-row', {
    attrs: {
      "type": "flex",
      "align": "middle",
      "gutter": [8, 8]
    }
  }, [_c('a-col', {
    attrs: {
      "offset": 18,
      "span": 6
    }
  }, [_c('div', {
    staticClass: "small-text"
  }, [_vm._v("Connected")])])], 1) : _vm._e(), _vm._l(_vm.PHASE, function (phase) {
    return _c('a-row', {
      key: phase,
      attrs: {
        "type": "flex",
        "align": "middle",
        "gutter": [8, 8]
      }
    }, [_c('a-col', {
      attrs: {
        "span": _vm.disabled ? 24 : 18
      }
    }, [phase === _vm.PHASE.L1 ? _c('UnitInputBox', {
      attrs: {
        "label": `${_vm.labelPrefix(0, _vm.$store.getters.isRWB)}${_vm.connection}`,
        "color": _vm.labelColor(0, _vm.$store.getters.isRWB),
        "phase": 1,
        "checked": _vm.value[phase] !== _vm.NO_CONNECTION,
        "disabled": _vm.disabled
      },
      model: {
        value: _vm.L1,
        callback: function ($$v) {
          _vm.L1 = $$v;
        },
        expression: "L1"
      }
    }) : phase === _vm.PHASE.L2 ? _c('UnitInputBox', {
      attrs: {
        "label": `${_vm.labelPrefix(1, _vm.$store.getters.isRWB)}${_vm.connection}`,
        "color": _vm.labelColor(1, _vm.$store.getters.isRWB),
        "phase": 2,
        "checked": _vm.value[phase] !== _vm.NO_CONNECTION,
        "disabled": _vm.disabled
      },
      model: {
        value: _vm.L2,
        callback: function ($$v) {
          _vm.L2 = $$v;
        },
        expression: "L2"
      }
    }) : phase === _vm.PHASE.L3 ? _c('UnitInputBox', {
      attrs: {
        "label": `${_vm.labelPrefix(2, _vm.$store.getters.isRWB)}${_vm.connection}`,
        "color": _vm.labelColor(2, _vm.$store.getters.isRWB),
        "phase": 3,
        "checked": _vm.value[phase] !== _vm.NO_CONNECTION,
        "disabled": _vm.disabled
      },
      model: {
        value: _vm.L3,
        callback: function ($$v) {
          _vm.L3 = $$v;
        },
        expression: "L3"
      }
    }) : _vm._e()], 1), !_vm.disabled ? _c('a-col', {
      staticClass: "grey-box",
      attrs: {
        "span": 6
      }
    }, [_c('div', {
      staticClass: "center-box"
    }, [_c('a-checkbox', {
      attrs: {
        "checked": _vm.value[phase] !== _vm.NO_CONNECTION
      },
      on: {
        "change": function () {
          return _vm.toggleConnected(phase);
        }
      }
    })], 1)]) : _vm._e()], 1);
  }), _c('a-form-model', {
    ref: "form",
    attrs: {
      "model": _vm.value,
      "rules": _vm.rules
    }
  }, [_c('a-form-model-item', {
    attrs: {
      "prop": "connections"
    }
  }, [false ? _c('a-input') : _vm._e()], 1)], 1)], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }