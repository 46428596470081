var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "content"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v("Create account")]), _c('a-form-model', {
    ref: "form",
    attrs: {
      "model": _vm.form,
      "layout": "vertical",
      "rules": _vm.rules
    }
  }, [_c('a-form-model-item', {
    attrs: {
      "label": "Your name",
      "prop": "name"
    }
  }, [_c('a-input', {
    staticClass: "input-box",
    attrs: {
      "size": "large"
    },
    model: {
      value: _vm.form.name,
      callback: function ($$v) {
        _vm.$set(_vm.form, "name", typeof $$v === 'string' ? $$v.trim() : $$v);
      },
      expression: "form.name"
    }
  })], 1), _c('a-form-model-item', {
    attrs: {
      "label": "Company name",
      "prop": "company"
    }
  }, [_c('a-input', {
    staticClass: "input-box",
    attrs: {
      "size": "large"
    },
    model: {
      value: _vm.form.company,
      callback: function ($$v) {
        _vm.$set(_vm.form, "company", typeof $$v === 'string' ? $$v.trim() : $$v);
      },
      expression: "form.company"
    }
  })], 1), _c('a-form-model-item', {
    attrs: {
      "prop": "licenseNumber"
    },
    scopedSlots: _vm._u([{
      key: "label",
      fn: function () {
        return [_vm._v(" Company Electrical License number "), _c('a-icon', {
          style: {
            fontSize: '25px'
          },
          attrs: {
            "component": _vm.BlackISVG
          },
          on: {
            "click": function ($event) {
              _vm.electricalNumberVisible = true;
            }
          }
        })];
      },
      proxy: true
    }])
  }, [_c('a-input', {
    staticClass: "input-box",
    attrs: {
      "size": "large"
    },
    model: {
      value: _vm.form.licenseNumber,
      callback: function ($$v) {
        _vm.$set(_vm.form, "licenseNumber", typeof $$v === 'string' ? $$v.trim() : $$v);
      },
      expression: "form.licenseNumber"
    }
  })], 1), _c('a-form-model-item', {
    attrs: {
      "prop": "trainingCert"
    },
    scopedSlots: _vm._u([{
      key: "label",
      fn: function () {
        return [_vm._v(" Allume Training Cert number ")];
      },
      proxy: true
    }])
  }, [_c('a-input', {
    staticClass: "input-box",
    attrs: {
      "size": "large"
    },
    model: {
      value: _vm.form.trainingCert,
      callback: function ($$v) {
        _vm.$set(_vm.form, "trainingCert", typeof $$v === 'string' ? $$v.trim() : $$v);
      },
      expression: "form.trainingCert"
    }
  })], 1), _c('a-form-model-item', {
    attrs: {
      "label": "Email address",
      "prop": "email"
    }
  }, [_c('a-input', {
    staticClass: "input-box",
    attrs: {
      "size": "large"
    },
    model: {
      value: _vm.form.email,
      callback: function ($$v) {
        _vm.$set(_vm.form, "email", typeof $$v === 'string' ? $$v.trim() : $$v);
      },
      expression: "form.email"
    }
  })], 1), _c('a-form-model-item', {
    attrs: {
      "label": "Password",
      "prop": "password"
    }
  }, [_c('a-input-password', {
    staticClass: "input-box",
    attrs: {
      "size": "large"
    },
    model: {
      value: _vm.form.password,
      callback: function ($$v) {
        _vm.$set(_vm.form, "password", $$v);
      },
      expression: "form.password"
    }
  })], 1), _c('a-form-model-item', {
    attrs: {
      "label": "Re-enter password",
      "prop": "passwordConfirm"
    }
  }, [_c('a-input-password', {
    staticClass: "input-box",
    attrs: {
      "size": "large"
    },
    model: {
      value: _vm.form.passwordConfirm,
      callback: function ($$v) {
        _vm.$set(_vm.form, "passwordConfirm", $$v);
      },
      expression: "form.passwordConfirm"
    }
  })], 1)], 1), _c('yellow-button', {
    staticClass: "submit",
    attrs: {
      "loading": _vm.loading
    },
    on: {
      "click": _vm.signUpClicked
    }
  }, [_vm._v(" SIGN UP ")]), _c('back-button'), _c('yellow-modal', {
    attrs: {
      "icon": true
    },
    model: {
      value: _vm.electricalNumberVisible,
      callback: function ($$v) {
        _vm.electricalNumberVisible = $$v;
      },
      expression: "electricalNumberVisible"
    }
  }, [_c('div', [_vm._v("Please provide your company’s electrical licence number.")])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }