<template>
  <div class="nav-wrapper">
    <a-row type="flex" justify="space-around" align="middle" class="nav">
      <a-col :span="2">
        <NavSVG
          class="nav-btn"
          data-tn="nav-bar-expand"
          @click="toggleOverlay"
        />
      </a-col>

      <a-col :span="12">
        <img
          class="nav__logo"
          src="../assets/png/allume-logo.png"
          width="100"
        />
      </a-col>

      <a-col :span="3">
        <component
          :is="
            $store.state.wifiStatus.connected
              ? 'WifiNavSVG'
              : 'WifiNavNoGoodSVG'
          "
          id="nav-wifi"
          class="nav-btn"
          :class="{
            hidden: !$store.state.showWifiIcon,
            blink: !$store.state.wifiStatus.connected,
          }"
          @click="wifiClick"
        />
      </a-col>

      <a-col :span="3">
        <ContactSVG id="nav-contact" class="nav-btn" @click="contactClick" />
      </a-col>

      <a-col :span="3">
        <FeedbackSVG id="nav-feedback" class="nav-btn" @click="feedbackClick" />
      </a-col>
    </a-row>
    <NavBarOverlay v-model="showOverlay" />
  </div>
</template>

<script>
import NavBarOverlay from "./NavBarOverlay";
import ContactSVG from "../assets/svg/contact.svg";
import FeedbackSVG from "../assets/svg/feedback.svg";
import NavSVG from "../assets/svg/nav.svg";
import WifiNavSVG from "../assets/svg/wifi-nav.svg";
import WifiNavNoGoodSVG from "../assets/svg/wifi-nav-no-good.svg";

export default {
  name: "NavBar",
  components: {
    NavBarOverlay,
    ContactSVG,
    FeedbackSVG,
    NavSVG,
    WifiNavSVG,
    WifiNavNoGoodSVG,
  },
  data() {
    return {
      showOverlay: false,
    };
  },
  methods: {
    toggleOverlay() {
      this.showOverlay = !this.showOverlay;
    },
    feedbackClick() {
      if (this.$store.getters.isLoggedIn) {
        this.$store.commit("setStep", this.$route.fullPath);
        if (!this.$route.path.startsWith("/feedback")) {
          if (
            !this.$route.path.startsWith("/contact") ||
            !this.$route.path.startsWith("/wifi-status")
          ) {
            this.$router.push("/feedback/1");
          } else {
            this.$router.replace("/feedback/1");
          }
        }
      } else {
        this.$message.warning("please login first");
      }
    },
    contactClick() {
      if (!this.$route.path.startsWith("/contact")) {
        if (
          !this.$route.path.startsWith("/feedback") ||
          !this.$route.path.startsWith("/wifi-status")
        ) {
          this.$router.push("/contact");
        } else {
          this.$router.replace("/contact");
        }
      }
    },
    wifiClick() {
      if (!this.$route.path.startsWith("/wifi-status")) {
        if (
          !this.$route.path.startsWith("/feedback") ||
          !this.$route.path.startsWith("/contact")
        ) {
          this.$router.push("/wifi-status");
        } else {
          this.$router.replace("/wifi-status");
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
.nav-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 100;

  .nav {
    background: #005149;
    color: #fff;
    height: 70px;
    font-size: 24px;
    box-shadow: 0 0.2px 15px rgba(0, 0, 0, 0.5);
    text-align: center;
    padding: 0 20px;

    .nav-btn {
      cursor: pointer;
    }

    .hidden {
      visibility: hidden;
    }

    .blink {
      animation-timing-function: ease-in-out;
      animation-name: breathe;
      animation-duration: 0.8s;
      animation-iteration-count: infinite;
      animation-direction: alternate;
    }
  }
}

@-webkit-keyframes breathe {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
</style>
