<template>
  <div class="content">
    <progress-bar :percent="30" />
    <div class="title">System Specifications</div>

    <a-form-model ref="form" :colon="false" :model="form" :rules="rules">
      <a-form-model-item prop="solshareName">
        <template #label>
          Solshare Name <BlackISVG @click="note4Visible = true" />
        </template>
        <a-input
          v-model.trim="form.solshareName"
          size="large"
          class="input-box"
          placeholder="e.g. Solshare 1"
          data-tn="system-building-address-solshare-name"
        />
      </a-form-model-item>

      <a-form-model-item prop="dcSize">
        <template #label>
          DC System size (kW) <BlackISVG @click="note1Visible = true" />
        </template>
        <a-input
          v-model.trim="form.dcSize"
          data-tn="spec-dc-input"
          size="large"
          class="input-box"
          placeholder="For this SolShare only"
        />
      </a-form-model-item>

      <a-form-model-item prop="acSize">
        <template #label>
          AC inverter capacity (kW) <BlackISVG @click="note2Visible = true" />
        </template>
        <a-input
          v-model.trim="form.acSize"
          data-tn="spec-ac-input"
          size="large"
          class="input-box"
          placeholder="For this SolShare only"
        />
      </a-form-model-item>

      <a-form-model-item prop="inverter">
        <template #label>
          Inverter Brand <BlackISVG @click="note3Visible = true" />
        </template>
        <a-radio-group
          v-model="form.inverter"
          button-style="solid"
          size="large"
        >
          <a-row type="flex" :gutter="[7, 7]">
            <a-col v-for="inverter of inverters" :key="inverter" :span="12">
              <a-radio-button
                :value="inverter"
                :data-tn="`spec-inverter-${inverter}`"
              >
                {{ inverter }}
              </a-radio-button>
            </a-col>
          </a-row>
        </a-radio-group>
      </a-form-model-item>

      <a-form-model-item
        v-if="form.inverter === inverters.Other"
        label="Please state your inverter brand"
        prop="inputInverter"
      >
        <a-input
          v-model.trim="form.inputInverter"
          size="large"
          class="input-box"
          placeholder="Inverter brand name"
        />
      </a-form-model-item>
    </a-form-model>

    <a-row type="flex" align="middle">
      <a-col :span="12">
        <back-button :style="{ marginTop: 0 }" />
      </a-col>
      <a-col :span="12">
        <yellow-button data-tn="spec-next-button" @click="nextClicked">
          NEXT
        </yellow-button>
      </a-col>
    </a-row>

    <yellow-modal v-model="note1Visible" icon padding="62px 26px 21px 26px">
      <div :style="{ fontSize: '21px', marginBottom: '1em' }">
        Please enter the PV panel capacity in kW DC.
      </div>
      <div :style="{ fontSize: '21px', marginBottom: '1em' }">
        This value should be for the system generation connected to this
        SolShare only.
      </div>
    </yellow-modal>

    <yellow-modal v-model="note2Visible" icon padding="62px 26px 21px 26px">
      <div :style="{ fontSize: '21px', marginBottom: '1em' }">
        Please enter the inverter capacity in kW AC.
      </div>
      <div :style="{ fontSize: '21px', marginBottom: '1em' }">
        This value should be for the system generation connected to this
        SolShare only.
      </div>
    </yellow-modal>

    <yellow-modal v-model="note3Visible" icon padding="62px 26px 21px 26px">
      <div :style="{ fontSize: '21px', marginBottom: '1em' }">
        Please enter the brand of inverter. If not listed please select other.
      </div>
    </yellow-modal>

    <yellow-modal v-model="note4Visible" icon padding="62px 26px 21px 26px">
      <div :style="{ fontSize: '21px', marginBottom: '1em' }">
        This is a descriptive label/name for each SolShare - use this if you
        would like to distinguish the SolShare between others installed on site.
        Examples include "SolShare 1", "SolShare closest to the MSB", "SolShare
        for Building A", etc.
      </div>
    </yellow-modal>
  </div>
</template>

<script>
import BlackISVG from "../assets/svg/black-i-filled.svg";
import { isPositiveNumber } from "../util/validator";
import { INVERTERS } from "../util/constants";
export default {
  components: {
    BlackISVG,
  },
  data() {
    return {
      inverters: INVERTERS,
      note1Visible: false,
      note2Visible: false,
      note3Visible: false,
      note4Visible: false,
      rules: {
        dcSize: [
          {
            required: true,
            trigger: "blur",
            message: "please input DC system size",
          },
          {
            validator: isPositiveNumber,
            trigger: "blur",
          },
        ],
        acSize: [
          {
            required: true,
            trigger: "blur",
            message: "please input AC capacity",
          },
          {
            validator: isPositiveNumber,
            trigger: "blur",
          },
        ],
        inverter: [
          {
            required: true,
            trigger: "change",
            message: "please select inverter brand",
          },
        ],
        inputInverter: [
          {
            required: true,
            trigger: "blur",
            message: "please input inverter brand",
          },
        ],
      },
      form: {
        dcSize: this.$store.state.project.spec.dcSize,
        acSize: this.$store.state.project.spec.acSize,
        inverter: this.$store.state.project.spec.inverter,
        solshareName: this.$store.state.project.address.solshareName,
      },
    };
  },
  methods: {
    nextClicked() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          this.$store.commit("setAddress", {
            solshareName: this.form.solshareName,
          });
          this.$store.commit("setSpec", this.form);
          await this.$router.push("/commission/11");
          await this.$store.dispatch("uploadAddress");
          await this.$store.dispatch("uploadSpec");
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  .title {
    line-height: 38px;
    margin-top: 20px;
    margin-bottom: 27px;
  }
}
</style>
