<template>
  <div class="content">
    <progress-bar :percent="5" />
    <div class="title">Sites</div>

    <div class="wrapper">
      <div
        v-for="(fleet, i) of $store.state.fleet"
        :key="i"
        class="item"
        @click="() => itemClicked(fleet)"
      >
        <span>{{ fleet.address }}</span>
        <SmallArrow />
      </div>
    </div>

    <div class="or-container">
      <p class="big-or">OR</p>
    </div>

    <yellow-button
      to="/add-a-site"
      data-tn="add-site-button"
      @click="clearSite"
    >
      ADD A SITE
    </yellow-button>
    <back-button />
  </div>
</template>

<script>
import SmallArrow from "../assets/svg/small-arrow.svg";

export default {
  name: "MySites",
  components: { SmallArrow },
  mounted() {
    this.$store.dispatch("getFleets");
  },
  methods: {
    itemClicked(fleet) {
      this.$store.commit("setAddress", {
        address: fleet.address,
        country: fleet.country,
      });
      this.$router.push("/commission/2");
    },
    clearSite() {
      this.$store.commit("setAddress", {
        address: "",
        latitude: null,
        longitude: null,
        country: null,
      });
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  .title {
    margin-bottom: 25px;
  }

  .wrapper {
    margin-bottom: 50px;

    .info-text {
      margin-top: 40px;
    }

    .item {
      height: 50px;
      border-radius: 10px;
      background: rgba(157, 157, 157, 0.2);
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 17px;
      padding: 11px 10px 11px 19px;
      margin-bottom: 21px;
      cursor: pointer;
    }
  }

  .or-container {
    text-align: center;
    margin: 0 auto 30px auto;
    position: relative;
  }

  .big-or {
    display: inline-block;
    position: relative;
    background-color: #fff;
    padding: 0 10px;
    width: 95%;
  }

  .big-or::before,
  .big-or::after {
    content: "";
    position: absolute;
    top: 50%;
    width: 44%;
    height: 1px;
    background-color: #2c3e50;
  }

  .big-or::before {
    left: 0;
  }

  .big-or::after {
    right: 0;
  }
}
</style>
