<template>
  <div class="content">
    <div class="title">Create account</div>

    <div class="info-text">
      <p>Your account has been created successfully!</p>
    </div>

    <yellow-button class="submit" :loading="loading" to="/login">
      LOGIN
    </yellow-button>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style lang="less" scoped>
.content {
  .title {
    margin-bottom: 45px;
  }

  .submit {
    margin-top: 80px;
  }
}
</style>
