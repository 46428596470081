var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "content"
  }, [_c('div', {
    staticClass: "info-text"
  }, [_vm._v(" Welcome to the SolShare commissioning app. What would you like to do? "), _c('div', {
    staticClass: "instruction-icon",
    style: {
      opacity: 0,
      position: _vm.noteThreeVisible ? 'unset' : 'absolute'
    }
  }, [_c('BlackIFilledSVG', {
    attrs: {
      "id": "info-icon"
    }
  })], 1)]), _vm.$store.getters.isCommissioner ? _c('div', {
    attrs: {
      "data-tn": "welcome-commission-solshare"
    },
    on: {
      "click": _vm.commissionClicked
    }
  }, [_c('a-row', {
    staticClass: "green-box",
    attrs: {
      "type": "flex",
      "align": "middle",
      "data-tn": "welcome-commission"
    }
  }, [_c('a-col', {
    attrs: {
      "span": 6,
      "offset": 2
    }
  }, [_c('CommissionSVG')], 1), _c('a-col', {
    attrs: {
      "span": 8,
      "offset": 2
    }
  }, [_vm._v(" COMMISSION A SOLSHARE ")])], 1)], 1) : _vm._e(), _c('div', {
    on: {
      "click": _vm.viewFleetClicked
    }
  }, [_c('a-row', {
    staticClass: "green-box",
    attrs: {
      "type": "flex",
      "align": "middle"
    }
  }, [_c('a-col', {
    attrs: {
      "span": 6,
      "offset": 2
    }
  }, [_c('FleetSVG')], 1), _c('a-col', {
    attrs: {
      "span": 8,
      "offset": 2
    }
  }, [_vm._v(" VIEW FLEET ")])], 1)], 1), _c('div', {
    on: {
      "click": _vm.reconfigureClicked
    }
  }, [_c('a-row', {
    staticClass: "green-box",
    attrs: {
      "type": "flex",
      "align": "middle",
      "data-tn": "reconfigure-a-solshare"
    }
  }, [_c('a-col', {
    attrs: {
      "span": 6,
      "offset": 2
    }
  }, [_c('ReconfigureSVG')], 1), _c('a-col', {
    attrs: {
      "span": 8,
      "offset": 2
    }
  }, [_vm._v(" RECONFIGURE A SOLSHARE ")])], 1)], 1), _c('div', {
    on: {
      "click": _vm.infoCentreClicked
    }
  }, [_c('a-row', {
    staticClass: "green-box",
    attrs: {
      "type": "flex",
      "align": "middle"
    }
  }, [_c('a-col', {
    attrs: {
      "span": 6,
      "offset": 2
    }
  }, [_c('InfoCentreSVG')], 1), _c('a-col', {
    attrs: {
      "span": 8,
      "offset": 2
    }
  }, [_vm._v(" INFO CENTRE ")])], 1)], 1), _c('div', {
    on: {
      "click": _vm.checkInternetClicked
    }
  }, [_c('a-row', {
    staticClass: "green-box",
    attrs: {
      "type": "flex",
      "align": "middle"
    }
  }, [_c('a-col', {
    attrs: {
      "span": 6,
      "offset": 2
    }
  }, [_c('CheckInternetSVG')], 1), _c('a-col', {
    attrs: {
      "span": 8,
      "offset": 2
    }
  }, [_vm._v(" CHECK INTERNET ")])], 1)], 1), _c('yellow-modal', {
    attrs: {
      "target": _vm.ContactHighlightedSVG,
      "right-arrow": "nav-contact",
      "closable": false
    },
    model: {
      value: _vm.noteOneVisible,
      callback: function ($$v) {
        _vm.noteOneVisible = $$v;
      },
      expression: "noteOneVisible"
    }
  }, [_c('div', {
    style: {
      fontFamily: 'Helvetica Medium',
      fontSize: '25px',
      textAlign: 'center'
    }
  }, [_vm._v(" Before you start ")]), _c('div', {
    style: {
      marginTop: '15px',
      marginBottom: '40px',
      textAlign: 'center'
    }
  }, [_vm._v(" If you have any issues or queries during the commissioning process, contact Allume by clicking on the icon above. ")]), _c('white-button', {
    style: {
      margin: '0 auto'
    },
    attrs: {
      "data-tn": "welcome-modal1-got-it",
      "block": false
    },
    on: {
      "click": _vm.noteOneClicked
    }
  }, [_vm._v(" GOT IT ")])], 1), _c('yellow-modal', {
    attrs: {
      "target": _vm.FeedbackHighlightedSVG,
      "right-arrow": "nav-feedback",
      "closable": false
    },
    model: {
      value: _vm.noteTwoVisible,
      callback: function ($$v) {
        _vm.noteTwoVisible = $$v;
      },
      expression: "noteTwoVisible"
    }
  }, [_c('div', {
    style: {
      fontFamily: 'Helvetica Medium',
      fontSize: '25px',
      textAlign: 'center'
    }
  }, [_vm._v(" Also, ")]), _c('div', {
    style: {
      textAlign: 'center',
      marginTop: '15px',
      marginBottom: '40px'
    }
  }, [_vm._v(" If at any point you have feedback on how we can improve this app, simply click here. Your progress won’t be lost. ")]), _c('white-button', {
    style: {
      margin: '0 auto'
    },
    attrs: {
      "data-tn": "welcome-modal2-proceed",
      "block": false
    },
    on: {
      "click": _vm.noteTwoClicked
    }
  }, [_vm._v(" OK, PROCEED ")])], 1), _c('yellow-modal', {
    attrs: {
      "target": _vm.BlackIFilledSVG,
      "left-arrow": "info-icon",
      "padding": "46px 29px 33px 29px",
      "top": 225,
      "closable": false
    },
    model: {
      value: _vm.noteThreeVisible,
      callback: function ($$v) {
        _vm.noteThreeVisible = $$v;
      },
      expression: "noteThreeVisible"
    }
  }, [_c('div', {
    style: {
      fontFamily: 'Helvetica Medium',
      fontSize: '25px',
      textAlign: 'center'
    }
  }, [_vm._v(" And finally, ")]), _c('div', {
    style: {
      textAlign: 'center',
      margin: 'auto',
      marginTop: '15px',
      marginBottom: '33px',
      width: '272px'
    }
  }, [_vm._v(" Look out for this info symbol throughout the commissioning process. It’s there to give you extra information on certain steps. ")]), _c('white-button', {
    style: {
      margin: '19px auto 0 auto'
    },
    attrs: {
      "data-tn": "welcome-modal3-got-it",
      "block": false
    },
    on: {
      "click": _vm.noteThreeClicked
    }
  }, [_vm._v(" GOT IT ")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }