var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "content"
  }, [_c('a-icon', {
    style: {
      fontSize: '61px',
      marginTop: '71px'
    },
    attrs: {
      "component": _vm.TickSVG
    }
  }), _c('div', {
    staticClass: "info-text green"
  }, [_vm._v("Your feedback has been submitted!")]), _vm._m(0), _c('yellow-button', {
    attrs: {
      "go": -1
    }
  }, [_vm._v(" RETURN TO COMMISSIONING ")])], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "info-text black"
  }, [_vm._v(" Thank you for "), _c('br'), _vm._v(" helping us improve our "), _c('br'), _vm._v("commissioning process. ")]);

}]

export { render, staticRenderFns }