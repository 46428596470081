<template>
  <div class="content">
    <progress-bar :percent="60" />

    <IndexFingerSVG class="finder-icon" />

    <div class="info-text">
      It is important that the correct CT type is selected. This information
      will be written on the CT.
    </div>

    <white-button
      v-if="$store.getters.is75A"
      data-tn="ct-rating-75a"
      bordered
      class="white-button"
      to="/commission/19"
      @click="() => ratingClicked(CT_RATING.A75)"
    >
      75A (DEFAULT)
    </white-button>

    <white-button
      v-else-if="!$store.getters.is2P"
      data-tn="ct-rating-120a"
      bordered
      class="white-button"
      to="/commission/19"
      @click="() => ratingClicked(CT_RATING.A120)"
    >
      120A (DEFAULT)
    </white-button>

    <white-button
      data-tn="ct-rating-200a"
      bordered
      class="white-button"
      to="/commission/19"
      @click="() => ratingClicked(CT_RATING.A200)"
    >
      {{ $store.getters.is2P ? "200A (DEFAULT)" : "200A" }}
    </white-button>

    <back-button />
  </div>
</template>

<script>
import IndexFingerSVG from "../assets/svg/index-finger.svg";
import { CT_RATING } from "../util/constants";
export default {
  components: { IndexFingerSVG },
  data() {
    return { CT_RATING };
  },
  methods: {
    ratingClicked(rating) {
      this.$store.commit("setCTRating", rating);
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  .finder-icon {
    margin-top: 33px;
    margin-bottom: 14px;
  }

  .info-text {
    margin-bottom: 27px;
  }

  .white-button {
    margin-bottom: 13px;
  }
}
</style>
