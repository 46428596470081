var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "content"
  }, [_c('progress-bar', {
    attrs: {
      "percent": 70
    }
  }), _c('div', {
    staticClass: "info-text"
  }, [_vm._v("Commissioning in progress")]), _c('div', {
    staticClass: "center"
  }, [_c('circle-progress', {
    ref: "progressBar"
  }), _c('div', {
    staticClass: "notice",
    attrs: {
      "data-tn": "commission-in-progress-progress-detail"
    }
  }, [_vm._v(" " + _vm._s(_vm.progressDetail) + " ")]), _c('div', {
    staticClass: "note",
    attrs: {
      "data-tn": "commission-in-progress-progress-estimate"
    }
  }, [_vm._v(" " + _vm._s(_vm.taskEstimatedTime) + " ")]), _c('div', {
    staticClass: "note",
    attrs: {
      "data-tn": "commission-in-progress-progress-total-estimate"
    }
  }, [_vm._v(" " + _vm._s(_vm.totalEstimatedTime) + " ")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }