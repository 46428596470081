<template>
  <div class="content">
    <progress-bar :percent="10" />

    <div class="info-text">
      <p>
        <b>
          SolShare requires a power cycle to proceed. Please follow the shutdown
          procedure below:
        </b>
      </p>

      <p>1. Turn off all grid maintenance isolators.</p>
      <p>2. Turn off the main AC isolator on the inverter.</p>
      <p>3. Wait for 30 seconds before re-energizing.</p>
    </div>

    <white-button bordered data-tn="try-again" @click="tryAgainClicked">
      OK, LET’S TRY AGAIN
    </white-button>
  </div>
</template>

<script>
export default {
  methods: {
    tryAgainClicked() {
      this.$router.replace("/commission/2");
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  .info-text {
    margin-top: 26px;
    margin-bottom: 40px;
  }
}
</style>
