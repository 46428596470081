<template>
  <div class="content">
    <div class="title">Reset password</div>

    <a-form-model ref="form" :model="form" layout="vertical" :rules="rules">
      <a-form-model-item label="Password" prop="password">
        <a-input-password
          v-model="form.password"
          size="large"
          class="input-box"
        />
      </a-form-model-item>

      <a-form-model-item label="Re-enter password" prop="passwordConfirm">
        <a-input-password
          v-model="form.passwordConfirm"
          size="large"
          class="input-box"
        />
      </a-form-model-item>
    </a-form-model>

    <yellow-button class="submit" :loading="loading" @click="submitClicked">
      Submit
    </yellow-button>
  </div>
</template>

<script>
import BlackISVG from "../assets/svg/black-i-filled.svg";
export default {
  data() {
    return {
      BlackISVG,
      electricalNumberVisible: false,
      loading: false,
      form: {
        password: "",
        passwordConfirm: "",
      },
      rules: {
        password: [
          { required: true, message: "Please input password", trigger: "blur" },
        ],
        passwordConfirm: [
          {
            required: true,
            message: "Please input password again",
            trigger: "blur",
          },
          {
            validator: (rule, value, callback) => {
              if (value !== this.form.password) {
                callback(new Error("Two inputs don't match!"));
              } else {
                callback();
              }
            },
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    submitClicked() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          this.loading = true;
          await this.$http.post(this.$api.resetPassword, {
            password: this.form.password,
            token: this.$route.params.token,
          });
          this.$message.success("password reset successfully");
          await this.$router.push("/login");
          this.loading = false;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  .title {
    margin-bottom: 45px;
  }

  /deep/ .ant-form-item {
    margin-bottom: 21px;
  }

  .submit {
    margin-top: 7px;
  }
}
</style>
