import Vue from "vue";
import * as Sentry from "@sentry/vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import {
  Icon,
  Col,
  Row,
  Button,
  Badge,
  Drawer,
  FormModel,
  Input,
  InputNumber,
  Modal,
  Progress,
  Select,
  Radio,
  Checkbox,
  Rate,
  message,
} from "ant-design-vue";
import YellowButton from "./components/YellowButton";
import WhiteButton from "./components/WhiteButton";
import GreenInfoBox from "./components/GreenInfoBox";
import BackButton from "./components/BackButton";
import GreyContactBox from "./components/GreyContactBox";
import YellowModal from "./components/YellowModal";
import ProgressBar from "./components/ProgressBar";
import BackAndNext from "./components/BackAndNext";
import UnitInputBox from "./components/UnitInputBox";
import UnitInputGroup2P from "./components/UnitInputGroup2P";
import UnitInputGroup3P from "./components/UnitInputGroup3P";
import UnitEnableGroup2P from "./components/UnitEnableGroup2P";
import UnitEnableGroup3P from "./components/UnitEnableGroup3P";
import CommissionItem from "./components/CommissionItem";
import GreenBorderBox from "./components/GreenBorderBox";
import AutoComplete from "./components/AutoComplete";
import CircleProgress from "./components/CircleProgress";
import UnitInputGroupUnequalAllocation from "./components/UnitInputGroupUnequalAllocation";
import VueSocialSharing from "vue-social-sharing";
import VueSlider from "vue-slider-component";

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    Vue,
    dsn: "https://00b7e8020e2743d59ba061d5a4749765@o4503967361007616.ingest.sentry.io/5259108",
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    environment: process.env.NODE_ENV,
    integrations: [new Sentry.Replay()],
  });
}

Sentry.setContext("Environment", {
  commissioner: store.state.user.email,
  Solshare: store.state.project.serialNumber,
  address: store.state.project.address,
  deliverMethod: store.state.project.solarDeliveryMethod,
  WIFIConnected: store.state.wifiStatus.connected,
  SSID: store.state.wifiStatus.ssid,
});

Vue.use(VueSocialSharing);

Vue.use(Badge);
Vue.use(Button);
Vue.use(Icon);
Vue.use(Col);
Vue.use(Row);
Vue.use(Drawer);
Vue.use(FormModel);
Vue.use(Input);
Vue.use(InputNumber);
Vue.use(Modal);
Vue.use(Progress);
Vue.use(Select);
Vue.use(Radio);
Vue.use(Checkbox);
Vue.use(Rate);
Vue.prototype.$message = message;

Vue.component("VueSlider", VueSlider);
Vue.component(YellowButton.name, YellowButton);
Vue.component(WhiteButton.name, WhiteButton);
Vue.component(GreenInfoBox.name, GreenInfoBox);
Vue.component(BackButton.name, BackButton);
Vue.component(GreyContactBox.name, GreyContactBox);
Vue.component(YellowModal.name, YellowModal);
Vue.component(ProgressBar.name, ProgressBar);
Vue.component(BackAndNext.name, BackAndNext);
Vue.component(UnitInputGroup2P.name, UnitInputGroup2P);
Vue.component(UnitInputGroup3P.name, UnitInputGroup3P);
Vue.component(UnitEnableGroup2P.name, UnitEnableGroup2P);
Vue.component(UnitEnableGroup3P.name, UnitEnableGroup3P);
Vue.component(CommissionItem.name, CommissionItem);
Vue.component(UnitInputBox.name, UnitInputBox);
Vue.component(GreenBorderBox.name, GreenBorderBox);
Vue.component(AutoComplete.name, AutoComplete);
Vue.component(CircleProgress.name, CircleProgress);
Vue.component(
  UnitInputGroupUnequalAllocation.name,
  UnitInputGroupUnequalAllocation
);

Vue.config.productionTip = false;

import api from "./http/api";
import http from "./http/http";
import "./http/axios";

Vue.prototype.$api = api;
Vue.prototype.$http = http;

new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");

if (navigator.serviceWorker) {
  navigator.serviceWorker.getRegistrations
    ? navigator.serviceWorker.getRegistrations().then((sws) => {
        sws.forEach((sw) => sw.unregister());
      })
    : navigator.serviceWorker.getRegistration().then((sw) => {
        sw.forEach((sw) => sw.unregister());
      });
  caches.keys().then((keys) => keys.forEach((key) => caches.delete(key)));
}
