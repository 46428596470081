<template>
  <div class="content">
    <back-button />
    <div class="title">Solshares at Site</div>

    <div class="wrapper">
      <div
        v-for="(solshare, i) of $store.state.systemInfo.solsharesAtSite"
        :key="i"
        class="item"
        @click="debounced(solshare.serial)"
      >
        <span>{{ solshare.serial }}</span>
        <div class="icon">
          <PowerIcon
            :color="
              isCritical(solshare)
                ? '#ff462c'
                : isNonCritical(solshare)
                ? '#ffe86c'
                : '#005149'
            "
          />
        </div>
        <div class="icon">
          <WifiIcon
            width="17"
            height="12"
            :color="isOffline(solshare) ? '#FFE86C' : '#005149'"
          />
        </div>
        <div class="icon">
          <NetworkIcon :color="isOffline(solshare) ? '#FFE86C' : '#005149'" />
        </div>
        <SmallArrow />
      </div>
    </div>
  </div>
</template>

<script>
import WifiIcon from "../components/Icons/WiFi";
import PowerIcon from "../components/Icons/Power";
import NetworkIcon from "../components/Icons/Network";
import SmallArrow from "../assets/svg/small-arrow.svg";
import { debounce } from "debounce";
import { faultMixin } from "../mixins/faults";

export default {
  name: "SolsharesAtSite",
  components: { WifiIcon, PowerIcon, NetworkIcon, SmallArrow },
  mixins: [faultMixin],
  computed: {
    debounced() {
      return debounce(this.itemClicked, 1000, true);
    },
  },
  methods: {
    async itemClicked(serial) {
      if (this.$route.query.from === "system-info") {
        // manual redirect to avoid too many history
        await this.$store.dispatch("getSystemInfo", {
          serial,
          redirect: false,
        });
        await this.$router.go(-1);
      } else {
        // use default behaviour
        await this.$store.dispatch("getSystemInfo", { serial, redirect: true });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  .title {
    margin-bottom: 25px;
  }

  .wrapper {
    .item {
      height: 50px;
      border-radius: 10px;
      background: rgba(157, 157, 157, 0.2);
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 17px;
      padding: 11px 10px 11px 19px;
      margin-bottom: 21px;
      cursor: pointer;

      .icon {
        background: rgba(255, 255, 255, 0.47);
        box-shadow: 0 0 11px 0 #ffffff;
        height: 28px;
        width: 28px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
</style>
