var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "content"
  }, [_c('progress-bar', {
    attrs: {
      "percent": 20
    }
  }), _c('div', {
    staticClass: "title"
  }, [_vm._v(" Add a Site "), _c('BlackISVG', {
    on: {
      "click": function ($event) {
        _vm.note1Visible = true;
      }
    }
  })], 1), _c('a-form-model', {
    ref: "form",
    attrs: {
      "colon": false,
      "rules": _vm.rules,
      "model": _vm.form
    }
  }, [_c('a-form-model-item', {
    attrs: {
      "label": "Address",
      "prop": "address"
    }
  }, [_c('google-autocomplete', {
    attrs: {
      "id": "address-input",
      "classname": "input-box",
      "country": Object.values(_vm.COUNTRY_CODE),
      "enable-geolocation": true,
      "data-tn": "system-building-address-solshare-address"
    },
    on: {
      "placechanged": _vm.placeChanged,
      "blur": _vm.placeInputBlurred
    },
    model: {
      value: _vm.form.address,
      callback: function ($$v) {
        _vm.$set(_vm.form, "address", typeof $$v === 'string' ? $$v.trim() : $$v);
      },
      expression: "form.address"
    }
  })], 1), _vm.showCountryInput ? _c('a-form-model-item', {
    attrs: {
      "prop": "country",
      "label": "Country"
    }
  }, [_c('a-select', {
    staticClass: "input-box",
    attrs: {
      "size": "large",
      "placeholder": "Please input country",
      "data-tn": "system-building-address-country"
    },
    model: {
      value: _vm.form.country,
      callback: function ($$v) {
        _vm.$set(_vm.form, "country", typeof $$v === 'string' ? $$v.trim() : $$v);
      },
      expression: "form.country"
    }
  }, _vm._l(_vm.COUNTRY_CODE, function (code, country) {
    return _c('a-select-option', {
      key: code,
      attrs: {
        "value": code,
        "data-tn": `system-building-address-country-${code}`
      }
    }, [_vm._v(" " + _vm._s(country) + " ")]);
  }), 1)], 1) : _vm._e()], 1), _c('a-row', {
    attrs: {
      "type": "flex",
      "align": "middle"
    }
  }, [_c('a-col', {
    attrs: {
      "span": 12
    }
  }, [_c('back-button', {
    style: {
      marginTop: 0
    }
  })], 1), _c('a-col', {
    attrs: {
      "span": 12
    }
  }, [_c('yellow-button', {
    attrs: {
      "data-tn": "building-address-next",
      "disabled": _vm.isSaveDisabled
    },
    on: {
      "click": _vm.nextClicked
    }
  }, [_vm._v(" NEXT ")])], 1)], 1), _c('yellow-modal', {
    attrs: {
      "icon": "",
      "padding": "62px 26px 21px 26px"
    },
    model: {
      value: _vm.note1Visible,
      callback: function ($$v) {
        _vm.note1Visible = $$v;
      },
      expression: "note1Visible"
    }
  }, [_c('div', {
    style: {
      fontSize: '21px',
      marginBottom: '1em'
    }
  }, [_vm._v(" Only enter the main building address details. Do not include any individual unit details here. ")])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }