<template>
  <div class="content">
    <div class="info-text">
      Allow phone to open camera and scan QR code here.
    </div>
    <qrcode-stream @decode="onDecode" @init="onInit">
      <div v-if="loading" class="loading-indicator">Opening Camera...</div>
    </qrcode-stream>
    <div v-if="error">
      {{ error }}
    </div>
    <back-button />
  </div>
</template>

<script>
import { QrcodeStream } from "vue-qrcode-reader";
export default {
  components: { QrcodeStream },
  data() {
    return {
      error: "",
      loading: false,
    };
  },
  methods: {
    validate(string) {
      return /https:\/\/allumeenergy\.com\.au\/commission\/[A-Z0-9-]+/.test(
        string
      );
    },
    async onDecode(decodedString) {
      if (this.validate(decodedString)) {
        await this.$store.dispatch("searchMeter", {
          serial: decodedString.substring(decodedString.lastIndexOf("/") + 1),
        });
      }
    },
    async onInit(promise) {
      try {
        this.loading = true;
        await promise;
      } catch (error) {
        if (error.name === "NotAllowedError") {
          this.error = "ERROR: you need to grant camera access permisson";
        } else if (error.name === "NotFoundError") {
          this.error = "ERROR: no camera on this device";
        } else if (error.name === "NotSupportedError") {
          this.error = "ERROR: secure context required (HTTPS, localhost)";
        } else if (error.name === "NotReadableError") {
          this.error = "ERROR: is the camera already in use?";
        } else if (error.name === "OverconstrainedError") {
          this.error = "ERROR: installed cameras are not suitable";
        } else if (error.name === "StreamApiNotSupportedError") {
          this.error = "ERROR: Stream API is not supported in this browser";
        }
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  .info-text {
    margin: 60px 0;
  }

  .loading-indicator {
    text-align: center;
  }
}
</style>
