<template>
  <div class="content">
    <progress-bar :percent="50" />

    <div v-if="$store.getters.isRWB" class="info-text">
      What is connected to R{{ currentIndex + 1 }}, W{{ currentIndex + 1 }}, B{{
        currentIndex + 1
      }}?
    </div>
    <div v-else-if="$store.getters.is2P" class="info-text">
      What is connected to L1-{{ currentIndex * 2 + 1 }}, L2-{{
        currentIndex * 2 + 2
      }}?
    </div>
    <div v-else class="info-text">
      What is connected to L1-{{ currentIndex + 1 }}, L2-{{ currentIndex + 1 }},
      L3-{{ currentIndex + 1 }}?
    </div>

    <BlackISVG class="info-icon" @click="noteVisible = true" />

    <a-form-model ref="form" :model="form" :rules="rules" class="input-box">
      <a-form-model-item prop="connections">
        <a-input
          v-model="form.connections"
          size="large"
          placeholder="Type in unit name"
          :data-tn="`unit-input-${currentIndex + 1}`"
        />
      </a-form-model-item>
    </a-form-model>

    <a-row type="flex" align="middle" class="back-n-next">
      <a-col :span="12">
        <back-button :style="{ marginTop: 0 }" />
      </a-col>
      <a-col :span="12">
        <yellow-button data-tn="phase-type-single-next" @click="nextClicked">
          NEXT
        </yellow-button>
      </a-col>
    </a-row>

    <yellow-modal v-model="noteVisible" icon padding="62px 26px 21px 26px">
      <div :style="{ fontSize: '21px', marginBottom: '1em' }">
        Enter the connected unit details eg:<br />
        - Apt 1<br />
        - Unit B<br />
        - Common light & power
      </div>
    </yellow-modal>
  </div>
</template>

<script>
import BlackISVG from "../assets/svg/black-i-filled.svg";
export default {
  components: { BlackISVG },
  data() {
    return {
      noteVisible: false,
      form: {
        connections: "",
      },
      rules: {
        connections: [
          {
            required: true,
            message: "Please input connection",
            trigger: "blur",
          },
        ],
      },
    };
  },
  computed: {
    currentIndex() {
      return Number(this.$route.params.index);
    },
  },
  created() {
    this.form.connections =
      this.$store.state.project.connections[this.currentIndex].L1;
  },
  methods: {
    nextClicked() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$store.commit("setConnection", {
            index: this.currentIndex,
            connection: {
              L1: this.form.connections,
              L2: this.form.connections,
              L3: this.form.connections,
            },
          });
          this.navigate();
          this.reset();
        } else {
          return false;
        }
      });
    },
    reset() {
      this.form.connections = "";
    },
    navigate() {
      this.$store.dispatch("uploadConnection");

      if (this.currentIndex === 4) {
        this.$router.push(`/commission/15`);
      } else {
        this.$router.push(`/commission/12/${this.currentIndex + 1}`);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  .info-text {
    margin-top: 29px;
    margin-bottom: 10px;
  }

  .info-icon {
    margin-bottom: 20px;
  }
  .white-button {
    margin-bottom: 13px;
  }
  .input-box {
    margin-bottom: 37px;
  }
}
</style>
