var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('a-row', {
    staticClass: "commission-item__row",
    attrs: {
      "type": "flex",
      "align": "middle",
      "justify": "center"
    }
  }, [_c('a-col', {
    style: {
      color: _vm.statusColor(_vm.status)
    },
    attrs: {
      "span": 16
    }
  }, [_vm._v(" " + _vm._s(_vm.item) + " ")]), _c('a-col', {
    attrs: {
      "span": 4,
      "offset": 4
    }
  }, [_vm.status === _vm.COMMISSION_STATUS.SUCCESS ? _c('SuccessSVG') : _vm.status === _vm.COMMISSION_STATUS.PENDING ? _c('a-icon', {
    staticStyle: {
      "font-size": "30px"
    },
    attrs: {
      "type": "loading"
    }
  }) : _c('FailedSVG')], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }