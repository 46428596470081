<template>
  <div class="content">
    <div class="title">Important!</div>

    <div class="info-text">
      Software update required.<br />
      To do this safely, <b>only close</b> SolShare maintenance isolation point
      belonging to SolShare <b>output L1-1</b>. <br />
      All other SolShare outputs <b>must remain open</b> (disconnected) for the
      duration of the update.
      <span class="info-icon">
        <BlackISVG class="info-icon" @click="noteVisible = true" />
      </span>
    </div>

    <back-and-next
      next-text="OK, I have done that"
      to="/software-update"
      data-tn="ok-i-have-done-that"
    />

    <yellow-modal v-model="noteVisible" icon padding="62px 26px 21px 26px">
      <div :style="{ fontSize: '21px', marginBottom: '1em' }">
        <span style="font-family: Helvetica Bold, serif">Only</span> close the
        isolation point highlighted by the arrow. Keep all other isolation
        points open while software update in progress.
      </div>

      <template #footer>
        <img src="../assets/png/relay.png" :style="{ width: '100%' }" />
      </template>
    </yellow-modal>
  </div>
</template>

<script>
import BlackISVG from "../assets/svg/black-i-filled.svg";
export default {
  name: "SoftwareUpdateInstruction",
  components: { BlackISVG },
  data() {
    return {
      noteVisible: false,
    };
  },
};
</script>

<style lang="less" scoped>
.content {
  .title {
    color: #005149;
    margin-top: 53px;
    margin-bottom: 40px;
  }

  .info-text {
    margin-bottom: 40px;
  }

  .info-icon {
    position: relative;
    top: 2px;
  }

  .social-network {
    margin-right: 17px;
    margin-bottom: 77px;
  }
}
</style>
