import Vue from "vue";
import Vuex from "vuex";
import waitFor from "p-wait-for";
import axios from "axios";
import { debounce } from "debounce";
import createPersistedState from "vuex-persistedstate";
import {
  COMMISSION_STATUS,
  SEARCH_METER_CODE,
  WIFI_STATUS_INTERVAL_MS,
  SEARCH_METER_INTERVAL_MS,
  SEARCH_METER_TIMEOUT_MS,
  SOLAR_DELIVERY_METHOD,
  USER_ROLE,
  COUNTRY_CODE,
  JOB_NAME,
  UPLOAD_CONNECTION_DEBOUNCE_MS,
  COMMISSION_LOG_ERROR_CODE,
  UPLOAD_CONFIG_INTERVAL_MS,
  UPLOAD_CONFIG_TIMEOUT_MS,
  COMMISSION_STAGE,
  COMMISSION_LOG_INTERVAL_MS,
  COMMISSIONING_PROCESS_TIMEOUT,
  INVERTERS,
} from "../util/constants";
import router from "../router";
import {
  is75A,
  isRWB,
  isUnequalAlgorithm,
  shouldNotShowUpdateInstructions,
} from "../util/solshare-serial";
import { backendToFrontend } from "../util/array";
import { project } from "./constant";
import { mutations } from "./mutations";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: {
      token: "",
      email: "",
      exp: 0,
      role: [],
    },
    region: "",
    project: { ...project },
    step: "",
    exception: "",
    exceptionHTML: false,
    exceptionTryAgain: true,
    configStatus: COMMISSION_STATUS.PENDING,
    isPollingConfigStatus: false,
    isPollingCommissionLog: false,
    commissionLog: {
      status: COMMISSION_STATUS.PENDING,
      startPercent: null,
      endPercent: null,
      duration: null,
      logs: [],
    },
    showWifiIcon: false,
    wifiStatus: { connected: false, ssid: "" },
    wifiIntervalID: null,
    systemInfo: {
      meter: {
        commissioner: {
          email: "",
          company: "",
        },
        alias: "",
        systemSize: "",
        inverterCapacity: "",
        softwareVersion: "",
        firmwareVersion: "",
        commissioned: false,
        project: { address: "" },
      },
      solsharesAtSite: [
        {
          serial: "",
          criticalFaults: [],
          nonCriticalFaults: [],
        },
      ],
      config: {
        algorithm_type: "",
        connected_units: [],
        enabled_units: [],
        algo_weight: [],
      },
      unit: [
        {
          L1: "",
          L2: "",
          L3: "",
        },
      ],
    },
    fleet: [],
    softwareUpdate: {
      updateTask: [],
      forceUpdate: false,
    },
  },

  mutations,

  actions: {
    async throwException(
      { commit },
      { message, tryAgain = true, rawHTML = false }
    ) {
      commit("setException", { exception: message, tryAgain, rawHTML });
      await router.push("/error");
    },
    async loginExpire({ commit }) {
      commit("setUser", { email: "", token: "", exp: 0 });
      await router.push("/login");
    },
    async startPollingWifiConnection({ commit, state }) {
      const getWifiStatus = async () => {
        const {
          data: { connected, ssid },
        } = await this._vm.$http.get(
          this._vm.$api.meterWifiStatus(state.project.serialNumber)
        );
        commit("setWifiStatus", { connected, ssid });
      };

      setImmediate(getWifiStatus);
      clearInterval(state.wifiIntervalID);
      const intervalId = setInterval(getWifiStatus, WIFI_STATUS_INTERVAL_MS);
      commit("setWifiIntervalID", intervalId);
    },
    async stopPollingWifiConnection({ commit, state }) {
      clearInterval(state.wifiIntervalID);
      commit("setWifiIntervalID", null);
    },

    async wifiStatus({ state, commit, dispatch }, { serial }) {
      if (serial) {
        commit("setSerialNumber", serial);
      }
      const {
        data: { code, connected, ssid },
      } = await this._vm.$http.get(
        this._vm.$api.meterWifiStatus(state.project.serialNumber)
      );
      commit("setWifiStatus", { connected, ssid });
      if (code === SEARCH_METER_CODE.NOT_FOUND) {
        dispatch("throwException", {
          message: "We cannot find the SolShare you entered.",
        });
      } else {
        await router.push("/wifi-status");
      }
    },

    async searchMeter({ commit, state, dispatch }, { serial }) {
      if (serial) {
        commit("setSerialNumber", serial);
      }

      await router.push("/commission/5");

      try {
        await waitFor(
          async () => {
            /**
             * interface data {
             *     code: number
             *     connected: boolean
             *     ssid: string
             *     updateTask: JOB_NAME[]
             *     forceUpdate: boolean
             *     swVersion: string
             * }
             */
            const {
              data: {
                code,
                connected,
                ssid,
                updateTask,
                forceUpdate,
                swVersion,
              },
            } = await this._vm.$http.get(
              this._vm.$api.searchMeter(state.project.serialNumber)
            );
            commit("setWifiStatus", { connected, ssid });
            commit("setSoftwareUpdate", {
              updateTask,
              forceUpdate,
            });
            commit("setSwVersion", swVersion);
            switch (code) {
              case SEARCH_METER_CODE.NOT_FOUND:
                await router.push("/commission/6/2");
                return true;
              case SEARCH_METER_CODE.ALREADY_COMMISSIONED:
                await router.push("/commission/7");
                return true;
              case SEARCH_METER_CODE.OFFLINE:
                return false;
              case SEARCH_METER_CODE.READY_TO_GO:
                dispatch("restoreConfig");
                await router.push("/commission/8");
                return true;
              case SEARCH_METER_CODE.COMMISSIONING:
                await router.push("/commission/21");
                return true;
              case SEARCH_METER_CODE.NEED_POWER_CYCLE:
                await router.push("/commission/6/3");
                return true;
            }
          },
          {
            interval: SEARCH_METER_INTERVAL_MS,
            timeout: SEARCH_METER_TIMEOUT_MS,
          }
        );
      } catch (e) {
        // offline
        await router.push("/commission/6/1");
      }
    },

    async softwareUpdateTryAgain({ commit, state }) {
      const {
        data: { updateTask, forceUpdate },
      } = await this._vm.$http.get(
        this._vm.$api.searchMeter(state.project.serialNumber)
      );
      commit("setSoftwareUpdate", {
        updateTask,
        forceUpdate,
      });
      await router.push("/software-update");
    },

    async uploadAddress({ state }) {
      await this._vm.$http.post(
        this._vm.$api.uploadAddress(state.project.serialNumber),
        {
          ...state.project,
        }
      );
    },
    async uploadSpec({ state }) {
      await this._vm.$http.post(
        this._vm.$api.uploadSpec(state.project.serialNumber),
        {
          ...state.project,
        }
      );
    },
    uploadConnection: debounce(async ({ dispatch }) => {
      dispatch("doUploadConnection");
    }, UPLOAD_CONNECTION_DEBOUNCE_MS),

    async doUploadConnection({ state }) {
      // in case user logout within 5 sec
      if (state.user.token) {
        this._vm.$http.post(
          this._vm.$api.uploadConnection(state.project.serialNumber),
          { ...state.project }
        );
      }
    },

    async restoreConfig({ state, commit }) {
      const { data } = await this._vm.$http.get(
        this._vm.$api.restoreConfig(state.project.serialNumber)
      );
      if (data !== null && typeof data === "object") {
        commit("resetProject", {
          ...data,
          address: {
            ...data.address,
            address: state.project.address.address,
            country: state.project.address.country,
          },
        });
      }
    },

    async commission({ commit, state, dispatch }, { uploadConfig }) {
      if (uploadConfig) {
        commit("resetConfigStatus");
        commit("setIsPollingConfigStatus", true);
      }
      commit("resetCommissionLog");
      commit("setIsPollingCommissionLog", true);

      try {
        if (uploadConfig) {
          // do the transition first
          await router.push("/commission/21");

          // upload config
          await this._vm.$http.post(this._vm.$api.uploadConfig, {
            ...state.project,
            algorithmWeight: state.project.algorithmWeight,
          });

          // polling config status
          await dispatch("pollingConfigStatus");
          commit("setIsPollingConfigStatus", false);

          // trigger commission
          await this._vm.$http.post(
            this._vm.$api.triggerCommission(state.project.serialNumber)
          );

          // polling commission log
          await dispatch("pollingCommissionLog");
          commit("setIsPollingCommissionLog", false);
        } else {
          commit("setConfigStatus", COMMISSION_STATUS.SUCCESS);

          // do the transition first
          await router.push("/commission/21");

          // trigger commission
          await this._vm.$http.post(
            this._vm.$api.triggerCommission(state.project.serialNumber)
          );

          // polling commission log
          await dispatch("pollingCommissionLog");
          commit("setIsPollingCommissionLog", false);
        }
      } catch (e) {
        console.error(e);
      }
    },

    async pollingConfigStatus({ commit, state }) {
      try {
        await waitFor(
          async () => {
            if (state.project.serialNumber === "") {
              throw new Error("aborted");
            }

            const { data } = await this._vm.$http.get(
              this._vm.$api.configStatus(state.project.serialNumber)
            );
            switch (data.status) {
              case COMMISSION_STATUS.PENDING:
                return false;
              case COMMISSION_STATUS.FAILED:
                throw new Error("failed");
              case COMMISSION_STATUS.SUCCESS:
                return true;
            }
          },
          {
            interval: UPLOAD_CONFIG_INTERVAL_MS,
            timeout: UPLOAD_CONFIG_TIMEOUT_MS,
          }
        );
        commit("setConfigStatus", COMMISSION_STATUS.SUCCESS);
      } catch (e) {
        if (e.message === "aborted") {
          return;
        }

        commit("setConfigStatus", COMMISSION_STATUS.FAILED);
        commit("setCommissionLog", {
          status: COMMISSION_STATUS.FAILED,
          logs: [{ errorcode: COMMISSION_LOG_ERROR_CODE.CONFIG_FAIL }],
        });
        await router.push({
          path: "/commission/20",
          query: { fail: COMMISSION_STAGE.FIRST },
        });
        throw e;
      }
    },

    async pollingCommissionLog({ state, commit }) {
      try {
        await waitFor(
          async () => {
            if (state.project.serialNumber === "") {
              throw new Error("aborted");
            }

            const { data } = await this._vm.$http.get(
              this._vm.$api.commissionLog(state.project.serialNumber)
            );
            commit("setCommissionLog", data);
            switch (data.status) {
              case COMMISSION_STATUS.PENDING:
                return false;
              case COMMISSION_STATUS.FAILED:
                throw new Error("failed");
              case COMMISSION_STATUS.SUCCESS:
                return true;
            }
          },
          {
            interval: COMMISSION_LOG_INTERVAL_MS,
            timeout: COMMISSIONING_PROCESS_TIMEOUT,
          }
        );
        await router.push("/net-promoter-score");
      } catch (e) {
        if (e.message === "aborted") {
          return;
        }

        await router.push({
          path: "/commission/20",
          query: { fail: COMMISSION_STAGE.SECOND },
        });
      }
    },

    async getSystemInfo(
      { commit, state, dispatch },
      { serial, redirect = false }
    ) {
      if (serial) {
        commit("setSerialNumber", serial);
      }
      const {
        data: { code, payload },
      } = await this._vm.$http.get(
        this._vm.$api.systemInfo(state.project.serialNumber)
      );
      if (code === SEARCH_METER_CODE.NOT_FOUND) {
        dispatch("throwException", {
          message: "We cannot find the SolShare you entered.",
        });
      } else if (code === SEARCH_METER_CODE.FORBIDDEN) {
        dispatch("throwException", {
          message:
            "You do not have permission to access the system information. Please contact Allume if you need access to this information.",
          tryAgain: false,
        });
      } else if (code === SEARCH_METER_CODE.NOT_COMMISSIONED) {
        dispatch("throwException", {
          message:
            "It looks like this SolShare hasn’t been commissioned yet, please complete the commissioning of this SolShare.",
          tryAgain: false,
        });
      } else if (code === SEARCH_METER_CODE.READY_TO_GO) {
        dispatch("startPollingWifiConnection");
        commit("setSystemInfo", {
          ...payload,
          config: {
            ...payload.config,
            algorithm_type: serial.startsWith("2P")
              ? Array.isArray(payload.config.algo_weight) &&
                payload.config.algo_weight.length > 0
                ? SOLAR_DELIVERY_METHOD.UNEQUAL_ALLOCATION
                : SOLAR_DELIVERY_METHOD.NET_METERING
              : payload.config.algorithm_type,
          },
        });
        if (Array.isArray(payload.unit)) {
          state.systemInfo.unit.forEach((connection, index) => {
            commit("setConnection", { index, connection });
          });
        }
        commit("setSpec", {
          dcSize: payload.meter.systemSize,
          acSize: payload.meter.inverterCapacity,
          inverter: Object.values(INVERTERS).includes(
            payload.config.inverter_type
          )
            ? payload.config.inverter_type
            : INVERTERS.Other,
          inputInverter: payload.config.inverter_type,
        });
        commit("setAddress", {
          address: payload.meter.project.address,
          solshareName: payload.meter.alias,
          latitude: payload.meter.project.latitude,
          longitude: payload.meter.project.longitude,
          country: payload.meter.project.country,
        });
        commit("setCTRating", payload.config.ct_rating);
        commit(
          "setAllAlgorithmWeight",
          backendToFrontend(payload.config.algo_weight, serial.startsWith("2P"))
        );
        commit("setDeliveryMethod", payload.config.algorithm_type);
        if (redirect) {
          await router.push("/system-info");
        }
      }
    },

    async getFleets({ commit }) {
      const { data: fleet } = await this._vm.$http.get(
        this._vm.$api.viewFleets
      );
      commit("setFleet", fleet);
    },

    async getSolsharesAtSite({ commit }, { projectId }) {
      const { data } = await this._vm.$http.get(
        this._vm.$api.getSolsharesAtSite(projectId)
      );
      commit("setSolsharesAtSite", data);
    },

    async getUserLocation({ commit }) {
      let countryCode = COUNTRY_CODE.Australia;
      try {
        const { data } = await axios.get(this._vm.$api.getUserLocation1);
        countryCode = data.country;
      } catch (e) {
        try {
          const { data } = await axios.get(this._vm.$api.getUserLocation2);
          countryCode = data["YourFuckingCountryCode"];
        } catch (e) {
          console.error("get location from ip failed");
        }
      }
      commit("setUserRegion", countryCode);
    },
  },
  getters: {
    isLoggedIn: (state) =>
      state.user.token !== "" && Date.now() / 1000 < state.user.exp,
    is2P: (state) => state.project.serialNumber.startsWith("2P"),
    is75A: (state) => is75A(state.project.serialNumber),
    isUnequalAlgorithm: (state) =>
      isUnequalAlgorithm(
        state.project.serialNumber,
        state.systemInfo.meter.softwareVersion
      ),
    isRWB: (state) => isRWB(state.project.serialNumber),
    isCommissioner: (state) => state.user.role.includes(USER_ROLE.COMMISSIONER),
    isAssetOwner: (state) => state.user.role.includes(USER_ROLE.PROVIDER),
    shouldUpdateSoftware: (state) =>
      state.softwareUpdate.updateTask.length > 1 ||
      state.softwareUpdate.updateTask[0] === JOB_NAME.monitor,
    shouldNotShowUpdateInstructions: (state) =>
      shouldNotShowUpdateInstructions(
        state.project.serialNumber,
        state.systemInfo.meter.softwareVersion
      ),
  },
  modules: {},
  plugins: [
    createPersistedState({
      reducer: (state) => {
        const reducer = Object.assign({}, state);
        delete reducer.wifiIntervalID;
        reducer.isPollingConfigStatus = false;
        return reducer;
      },
    }),
  ],
});
