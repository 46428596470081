import {
  COMMISSION_LOG_ERROR_CODE,
  GENERATION,
  COUNTRY_CODE,
} from "./constants";
import { getRequiredConnections, generation } from "./solshare-serial";

function NoVoltageError(serial, country, con_name, other_con = null) {
  switch (country) {
    default:
    case COUNTRY_CODE.Australia:
    case COUNTRY_CODE["New Zealand"]:
      if (generation(serial) === GENERATION.ONE) {
        return `Phase ${con_name} has no voltage reference! Please ensure connections ${getRequiredConnections(
          serial
        )} are all connected to the grid, these need no be connected for the SolShare to have a voltage reference`;
      } else {
        return `The SolShare has detected no voltage on ${con_name}. Please ensure all isolation points are switched on and the connection is connected to the grid`;
      }

    case COUNTRY_CODE["United States"]:
      return `Phase ${con_name} has no voltage reference! Please ensure the first output connection on L1 and L2 are connected to the grid, those connections must be grid connected for the SolShare to complete its commissioning.`;
    case COUNTRY_CODE["United Kingdom"]:
      return `The SolShare has detected no voltage on ${con_name}. Please ensure all isolation points are switched on and the flat is grid connected (if pre-paid meter ensure there is credit on meter)`;
    case COUNTRY_CODE.Germany:
      return `The SolShare has detected no voltage on ${con_name}. Please ensure all isolation points are switched on and the flat is grid connected`;
  }
}

function UnknownError(serial, country, con_name, other_con = null) {
  switch (country) {
    default:
    case COUNTRY_CODE.Australia:
    case COUNTRY_CODE["New Zealand"]:
    case COUNTRY_CODE["United States"]:
      return `Connection ${con_name} had irregularities with its current transformer readings. This could be because it is not connected properly to the SolShare, not clipped on properly at the main switch board or clipped to another unit. Please review this CT. If you can't find the problem, give Allume a call!`;
    case COUNTRY_CODE["United Kingdom"]:
    case COUNTRY_CODE.Germany:
      return `Connection ${con_name} had irregularities with its current transformer readings. This could be because it is not connected properly to the SolShare, not clipped on properly or clipped to another connection. Please review this CT.`;
  }
}

export function SwappedError(serial, country, con_name, other_con = null) {
  switch (country) {
    default:
    case COUNTRY_CODE.Australia:
    case COUNTRY_CODE["New Zealand"]:
    case COUNTRY_CODE["United States"]:
      return `Connection ${con_name} and Connection ${other_con} current transformers are swapped, please change these CT's in the main switch board`;
    case COUNTRY_CODE["United Kingdom"]:
    case COUNTRY_CODE.Germany:
      return `Connection ${con_name} and Connection ${other_con} current transformers are swapped, please swap these CT’s so they are on the correct location`;
  }
}

function NoGridError(serial, country, con_name, other_con = null) {
  switch (country) {
    default:
    case COUNTRY_CODE.Australia:
    case COUNTRY_CODE["New Zealand"]:
    case COUNTRY_CODE["United States"]:
      return `Connection ${con_name} current transformer not registered, please check connection at CT connector in SolShare is secure, the CT is clipped properly in main switch board and the CT is on the service side of the unit's MCB as per the install manual`;
    case COUNTRY_CODE["United Kingdom"]:
    case COUNTRY_CODE.Germany:
      return `Connection ${con_name} current transformer not registered, please confirm CT clamp is securely closed on live cable, CT on grid side of solar connection, CT tail is terminated properly at SolShare connector and at any extension point`;
  }
}

function ReversedError(serial, country, con_name, other_con = null) {
  switch (country) {
    default:
    case COUNTRY_CODE.Australia:
    case COUNTRY_CODE["New Zealand"]:
    case COUNTRY_CODE["United States"]:
      return `Connection ${con_name} current transformer is suspected to be reversed, please reverse it at the main switch board`;
    case COUNTRY_CODE["United Kingdom"]:
    case COUNTRY_CODE.Germany:
      return `Connection ${con_name} current transformer is suspected to be reversed, please reverse the CT`;
  }
}

function NoSolarError(serial, country, con_name, other_con = null) {
  switch (country) {
    default:
    case COUNTRY_CODE.Australia:
    case COUNTRY_CODE["New Zealand"]:
    case COUNTRY_CODE["United States"]:
    case COUNTRY_CODE["United Kingdom"]:
    case COUNTRY_CODE.Germany:
      return `Connection ${con_name} could not detect any solar power, it is likely the inverter tripped during the test. Please review output connections of the SolShare to and ensure they match they configuration entered. If the problem persists, give Allume a call!`;
  }
}

function ConfigError(serial, country, con_name, other_con = null) {
  switch (country) {
    default:
    case COUNTRY_CODE.Australia:
    case COUNTRY_CODE["New Zealand"]:
    case COUNTRY_CODE["United States"]:
    case COUNTRY_CODE["United Kingdom"]:
    case COUNTRY_CODE.Germany:
      return `Config update failed. Please check the input`;
  }
}

function InverterTripError(serial, country, con_name, other_con = null) {
  switch (country) {
    default:
    case COUNTRY_CODE.Australia:
    case COUNTRY_CODE["New Zealand"]:
    case COUNTRY_CODE["United States"]:
      return `It looks like the inverter stopped outputting when ${con_name} was connected to the grid. Please review this connection to ensure it is actually connected and the unit solar main switch is on. If the problem persists, give Allume a call!`;
    case COUNTRY_CODE["United Kingdom"]:
    case COUNTRY_CODE.Germany:
      return `It looks like the inverter stopped outputting when ${con_name} was connected to the grid. Please review this connection to ensure it is actually connected to the grid and all isolation points are switched on.`;
  }
}

function InverterOutputError(serial, country, con_name, other_con = null) {
  switch (country) {
    default:
    case COUNTRY_CODE.Australia:
    case COUNTRY_CODE["New Zealand"]:
    case COUNTRY_CODE["United States"]:
    case COUNTRY_CODE["United Kingdom"]:
    case COUNTRY_CODE.Germany:
      return `The inverter failed to output enough solar in the allocated time, please ensure that the inverter is commissioned and able to output more than 300W`;
  }
}

function WrongCTError(serial, country, con_name, other_con = null) {
  switch (country) {
    default:
    case COUNTRY_CODE.Australia:
    case COUNTRY_CODE["New Zealand"]:
    case COUNTRY_CODE["United States"]:
      return `Connection ${con_name} CT readings were scaled incorrectly, it is likely the wrong CT type was selected, please go back to the CT selection page and try again`;
    case COUNTRY_CODE["United Kingdom"]:
    case COUNTRY_CODE.Germany:
      return `Connection ${con_name} CT readings were scaled incorrectly, it is likely the wrong CT type was selected, please give Allume a call and we'll help you work through the issue`;
  }
}

function PhaseSwapError(serial, country, con_name, other_con = null) {
  switch (country) {
    default:
    case COUNTRY_CODE.Australia:
    case COUNTRY_CODE["New Zealand"]:
      return `WARNING: The SolShare has detected that outputs ${other_con} have been wired to the incorrect phase. If left uncorrected, this may result in a system electrical short and damage to the equipment. Please review these connections to make sure the SolShare output phase matches the grid phase of the connection. Ensure the SolShare has been shutdown and isolated before working on the installation.`;
    case COUNTRY_CODE["United States"]:
      return `WARNING: The SolShare has detected that outputs ${other_con} have been wired to the incorrect line. If left uncorrected, this may result in a system electrical short and damage to the equipment. Please review these connections to make sure the SolShare output line matches the grid line of the connection. Ensure the SolShare has been shutdown and isolated before working on the installation.`;
    case COUNTRY_CODE["United Kingdom"]:
    case COUNTRY_CODE.Germany:
      return `WARNING: The SolShare has detected outputs that have been wired to the incorrect phase. Please test all voltages at SolShare terminal blocks to ensure correct phasing. Any miswiring has potential to damage SolShare and void warranty.`;
  }
}

function TimeoutError(serial, country, con_name, other_con = null) {
  switch (country) {
    default:
    case COUNTRY_CODE.Australia:
    case COUNTRY_CODE["New Zealand"]:
    case COUNTRY_CODE["United States"]:
    case COUNTRY_CODE["United Kingdom"]:
    case COUNTRY_CODE.Germany:
      return `Timed out attempting to get status of commissioning`;
  }
}

export const ERROR_MAP = {
  [COMMISSION_LOG_ERROR_CODE.UNKNOWN]: UnknownError,
  [COMMISSION_LOG_ERROR_CODE.NO_VOLTAGE]: NoVoltageError,
  [COMMISSION_LOG_ERROR_CODE.SWAPPED]: SwappedError,
  [COMMISSION_LOG_ERROR_CODE.NO_GRID]: NoGridError,
  [COMMISSION_LOG_ERROR_CODE.REVERSED]: ReversedError,
  [COMMISSION_LOG_ERROR_CODE.NO_SOLAR]: NoSolarError,
  [COMMISSION_LOG_ERROR_CODE.CONFIG_FAIL]: ConfigError,
  [COMMISSION_LOG_ERROR_CODE.INVERTER_TRIP]: InverterTripError,
  [COMMISSION_LOG_ERROR_CODE.INVERTER_NOT_OUTPUTTING]: InverterOutputError,
  [COMMISSION_LOG_ERROR_CODE.WRONG_CT]: WrongCTError,
  [COMMISSION_LOG_ERROR_CODE.PHASE_SWAPPED]: PhaseSwapError,
  [COMMISSION_LOG_ERROR_CODE.TIMEOUT]: TimeoutError,
};
