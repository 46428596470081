var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "content"
  }, [_c('ErrorSVG', {
    staticClass: "error-icon"
  }), _c('div', {
    staticClass: "info-text"
  }, [_vm.$store.state.exceptionHTML ? _c('p', [_vm._v(" Sorry! "), _c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.$store.state.exception)
    }
  })]) : _c('p', {
    attrs: {
      "data-tn": "error-text"
    }
  }, [_vm._v("Sorry! " + _vm._s(_vm.$store.state.exception))]), _vm.$store.state.exceptionTryAgain ? _c('p', [_vm._v("Please go back and try again.")]) : _vm._e()]), _c('back-button', {
    staticClass: "back-button"
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }