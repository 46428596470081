<template>
  <div class="content">
    <div class="title">Log in</div>
    <a-form-model ref="form" :model="form" :rules="rules" layout="vertical">
      <a-form-model-item label="Email address" prop="email">
        <a-input
          v-model.trim="form.email"
          size="large"
          class="input-box"
          data-tn="login-email-input"
        />
      </a-form-model-item>
      <a-form-model-item label="Password" prop="password">
        <a-input-password
          v-model="form.password"
          data-tn="login-password-input"
          size="large"
          class="input-box"
          @keydown.enter="loginClicked"
        />
      </a-form-model-item>
    </a-form-model>

    <a-row justify="end" type="flex">
      <div class="grey" @click="forgetPasswordClicked">Forgot password?</div>
    </a-row>

    <a-row justify="start" type="flex" align="middle">
      <div>
        Or <span class="green" @click="signUpClicked">Sign Up</span> here
      </div>
    </a-row>

    <yellow-button
      class="submit"
      :loading="loginLoading"
      data-tn="login-submit"
      @click="loginClicked"
    >
      SUBMIT
    </yellow-button>

    <yellow-modal
      v-model="forgetPasswordModalVisible"
      color="#fff"
      padding="67px 28px 47px 28px"
    >
      <div
        :style="{
          marginBottom: '28px',
          fontSize: '25px',
          lineHeight: '21px',
          fontFamily: 'Helvetica Medium',
        }"
      >
        Forgotten password?
      </div>
      <div style="margin-bottom: 14px">
        We will send you a link you can use to create a new password.
      </div>

      <a-form-model :model="form" layout="vertical">
        <a-form-model-item label="Email address">
          <a-input v-model.trim="form.email" size="large" class="input-box" />
        </a-form-model-item>
      </a-form-model>

      <yellow-button :loading="sendLoading" @click="forgetSend">
        SEND
      </yellow-button>
    </yellow-modal>
  </div>
</template>

<script>
import CloseSVG from "../assets/svg/close.svg";
import jwt from "jsonwebtoken";

export default {
  data() {
    return {
      CloseSVG,
      loginLoading: false,
      sendLoading: false,
      forgetPasswordModalVisible: false,
      form: { email: "", password: "" },
      rules: {
        password: [
          { required: true, message: "Please input password", trigger: "blur" },
        ],
        email: [
          {
            type: "email",
            required: true,
            message: "Please input a valid email address",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    forgetPasswordClicked() {
      this.forgetPasswordModalVisible = true;
    },
    async forgetSend() {
      this.sendLoading = true;
      await this.$http.post(this.$api.forgetPassword, this.form);
      this.forgetPasswordModalVisible = false;
      this.sendLoading = false;
      this.$message.success("email sent");
    },
    signUpClicked() {
      this.$router.push("/create-account");
    },
    loginClicked() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          this.loginLoading = true;

          const {
            data: { token },
          } = await this.$http.post(this.$api.login, this.form);
          const { exp, email, role } = jwt.decode(token);
          this.$store.commit("setUser", { token, exp, role, email });

          await this.$router.push("/welcome");
          await this.$store.dispatch("getFleets");
          await this.$store.dispatch("getUserLocation");

          this.loginLoading = false;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  .title {
    margin-bottom: 60px;
  }

  .grey {
    color: rgb(173, 173, 173);
    text-decoration: underline;
    font-size: 12px;
    margin-top: 2px;
    margin-bottom: 16px;
    cursor: pointer;
  }

  .green {
    color: rgb(35, 144, 88);
    text-decoration: underline;
    cursor: pointer;
  }

  /deep/ .ant-form-item {
    margin-bottom: 23px;
  }

  .submit {
    margin-top: 44px;
  }
}
</style>
