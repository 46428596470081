<template>
  <a-row type="flex" align="middle">
    <a-col :span="12">
      <back-button :style="{ marginTop: 0 }" :step="backStep" />
    </a-col>
    <a-col :span="12">
      <yellow-button :to="to" :data-tn="dataTn" @click="nextOnClick">
        {{ nextText.toUpperCase() }}
      </yellow-button>
    </a-col>
  </a-row>
</template>

<script>
export default {
  name: "BackAndNext",
  props: {
    backStep: { type: Number, default: -1 },
    to: { type: String, default: null },
    nextText: { type: String, default: "next" },
    nextOnClick: { type: Function, default: () => {} },
    dataTn: { type: String, default: "" },
  },
};
</script>

<style scoped></style>
