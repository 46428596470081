<template>
  <div class="content">
    <div class="info-text">
      Welcome to the SolShare commissioning app. What would you like to do?
      <div
        class="instruction-icon"
        :style="{
          opacity: 0,
          position: noteThreeVisible ? 'unset' : 'absolute',
        }"
      >
        <BlackIFilledSVG id="info-icon" />
      </div>
    </div>

    <div
      v-if="$store.getters.isCommissioner"
      data-tn="welcome-commission-solshare"
      @click="commissionClicked"
    >
      <a-row
        class="green-box"
        type="flex"
        align="middle"
        data-tn="welcome-commission"
      >
        <a-col :span="6" :offset="2">
          <CommissionSVG />
        </a-col>
        <a-col :span="8" :offset="2"> COMMISSION A SOLSHARE </a-col>
      </a-row>
    </div>

    <div @click="viewFleetClicked">
      <a-row class="green-box" type="flex" align="middle">
        <a-col :span="6" :offset="2">
          <FleetSVG />
        </a-col>
        <a-col :span="8" :offset="2"> VIEW FLEET </a-col>
      </a-row>
    </div>

    <div @click="reconfigureClicked">
      <a-row
        class="green-box"
        type="flex"
        align="middle"
        data-tn="reconfigure-a-solshare"
      >
        <a-col :span="6" :offset="2">
          <ReconfigureSVG />
        </a-col>
        <a-col :span="8" :offset="2"> RECONFIGURE A SOLSHARE </a-col>
      </a-row>
    </div>

    <div @click="infoCentreClicked">
      <a-row class="green-box" type="flex" align="middle">
        <a-col :span="6" :offset="2">
          <InfoCentreSVG />
        </a-col>
        <a-col :span="8" :offset="2"> INFO CENTRE </a-col>
      </a-row>
    </div>

    <div @click="checkInternetClicked">
      <a-row class="green-box" type="flex" align="middle">
        <a-col :span="6" :offset="2">
          <CheckInternetSVG />
        </a-col>
        <a-col :span="8" :offset="2"> CHECK INTERNET </a-col>
      </a-row>
    </div>

    <!--  Modal 1  -->

    <yellow-modal
      v-model="noteOneVisible"
      :target="ContactHighlightedSVG"
      right-arrow="nav-contact"
      :closable="false"
    >
      <div
        :style="{
          fontFamily: 'Helvetica Medium',
          fontSize: '25px',
          textAlign: 'center',
        }"
      >
        Before you start
      </div>
      <div
        :style="{
          marginTop: '15px',
          marginBottom: '40px',
          textAlign: 'center',
        }"
      >
        If you have any issues or queries during the commissioning process,
        contact Allume by clicking on the icon above.
      </div>
      <white-button
        data-tn="welcome-modal1-got-it"
        :block="false"
        :style="{ margin: '0 auto' }"
        @click="noteOneClicked"
      >
        GOT IT
      </white-button>
    </yellow-modal>

    <!--  Modal 2  -->

    <yellow-modal
      v-model="noteTwoVisible"
      :target="FeedbackHighlightedSVG"
      right-arrow="nav-feedback"
      :closable="false"
    >
      <div
        :style="{
          fontFamily: 'Helvetica Medium',
          fontSize: '25px',
          textAlign: 'center',
        }"
      >
        Also,
      </div>
      <div
        :style="{
          textAlign: 'center',
          marginTop: '15px',
          marginBottom: '40px',
        }"
      >
        If at any point you have feedback on how we can improve this app, simply
        click here. Your progress won’t be lost.
      </div>
      <white-button
        data-tn="welcome-modal2-proceed"
        :block="false"
        :style="{ margin: '0 auto' }"
        @click="noteTwoClicked"
      >
        OK, PROCEED
      </white-button>
    </yellow-modal>

    <!--  Modal 3  -->

    <yellow-modal
      v-model="noteThreeVisible"
      :target="BlackIFilledSVG"
      left-arrow="info-icon"
      padding="46px 29px 33px 29px"
      :top="225"
      :closable="false"
    >
      <div
        :style="{
          fontFamily: 'Helvetica Medium',
          fontSize: '25px',
          textAlign: 'center',
        }"
      >
        And finally,
      </div>
      <div
        :style="{
          textAlign: 'center',
          margin: 'auto',
          marginTop: '15px',
          marginBottom: '33px',
          width: '272px',
        }"
      >
        Look out for this info symbol throughout the commissioning process. It’s
        there to give you extra information on certain steps.
      </div>
      <white-button
        data-tn="welcome-modal3-got-it"
        :block="false"
        :style="{ margin: '19px auto 0 auto' }"
        @click="noteThreeClicked"
      >
        GOT IT
      </white-button>
    </yellow-modal>
  </div>
</template>

<script>
import CommissionSVG from "../assets/svg/commission.svg";
import ReconfigureSVG from "../assets/svg/reconfigure.svg";
import FleetSVG from "../assets/svg/fleet.svg";
import InfoCentreSVG from "../assets/svg/info-centre.svg";
import CheckInternetSVG from "../assets/svg/check-internet.svg";
import FeedbackHighlightedSVG from "../assets/svg/feedback-highlighted.svg";
import ContactHighlightedSVG from "../assets/svg/contact-highlighted.svg";
import BlackIFilledSVG from "../assets/svg/black-i-filled.svg";

export default {
  components: {
    CommissionSVG,
    ReconfigureSVG,
    InfoCentreSVG,
    CheckInternetSVG,
    BlackIFilledSVG,
    FleetSVG,
  },
  data() {
    return {
      FeedbackHighlightedSVG,
      ContactHighlightedSVG,
      BlackIFilledSVG,
      noteOneVisible: false,
      noteTwoVisible: false,
      noteThreeVisible: false,
    };
  },
  methods: {
    commissionClicked() {
      this.$store.commit("resetProject");
      this.noteOneVisible = true;
    },
    reconfigureClicked() {
      this.$router.push("/reconfigure-enter-serial");
    },
    viewFleetClicked() {
      this.$router.push("/view-fleet");
    },
    infoCentreClicked() {
      this.$router.push("/info-centre");
    },
    checkInternetClicked() {
      this.$router.push("/wifi-enter-serial");
    },
    noteOneClicked() {
      this.noteOneVisible = false;
      this.noteTwoVisible = true;
    },
    noteTwoClicked() {
      this.noteTwoVisible = false;
      this.noteThreeVisible = true;
    },
    noteThreeClicked() {
      this.noteThreeVisible = false;
      this.$router.push("/commission/1");
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  .info-text {
    margin-top: 37px;
    margin-bottom: 29px;

    .instruction-icon {
      margin-top: 10px;
    }
  }

  .green-box {
    background: #f3f4f4;
    height: 97px;
    box-shadow: rgba(162, 162, 162, 0.52) 0.5px 2.3px 4.4px;
    color: #005149;
    font-size: 15px;
    text-align: center;
    letter-spacing: 1px;
    margin-bottom: 10px;
    line-height: 19px;
    cursor: pointer;
  }
}
</style>
