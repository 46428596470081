var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "content"
  }, [_c('back-button'), _c('div', {
    staticClass: "info-text"
  }, [_vm._v("Edit Delivery Method")]), _c('div', {
    staticClass: "solar-wrapper"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("Method")]), _c('a-select', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "size": "large"
    },
    on: {
      "change": _vm.methodChanged
    },
    scopedSlots: _vm._u([{
      key: "suffixIcon",
      fn: function () {
        return [_c('ArrowDropdown')];
      },
      proxy: true
    }]),
    model: {
      value: _vm.form.algorithmType,
      callback: function ($$v) {
        _vm.$set(_vm.form, "algorithmType", $$v);
      },
      expression: "form.algorithmType"
    }
  }, [!_vm.$store.getters.is2P ? _c('a-select-option', {
    attrs: {
      "value": _vm.ALGORITHM_TYPE.DEMAND_BASED_DELIVERY,
      "data-tn": "demand-based-delivery"
    }
  }, [_vm._v(" Demand-based delivery ")]) : _vm._e(), _c('a-select-option', {
    attrs: {
      "value": _vm.ALGORITHM_TYPE.CUSTOM_ALLOCATION,
      "data-tn": "custom-allocation"
    }
  }, [_vm._v(" Custom allocation ")])], 1)], 1), _vm.isReadOnly ? _c('green-info-box', {
    staticClass: "info-box"
  }, [_vm._v(" In order to specify an allocation the software of Solshare needs to be upgraded to above v0.4.5 ")]) : _vm._e(), _vm.form.algorithmType === _vm.ALGORITHM_TYPE.CUSTOM_ALLOCATION ? _c('div', {
    staticClass: "unequal-wrapper"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("Input Unit")]), _c('a-radio-group', {
    staticClass: "radio-group",
    attrs: {
      "size": "large"
    },
    model: {
      value: _vm.suffix,
      callback: function ($$v) {
        _vm.suffix = $$v;
      },
      expression: "suffix"
    }
  }, [_c('a-radio-button', {
    staticClass: "radio-button",
    attrs: {
      "value": _vm.UNEQUAL_ALLOCATION_TYPE.PERCENT
    }
  }, [_vm._v(" % ")]), _c('a-radio-button', {
    staticClass: "radio-button",
    attrs: {
      "value": _vm.UNEQUAL_ALLOCATION_TYPE.KW
    }
  }, [_vm._v(" kW ")])], 1), _vm._l(_vm.$store.getters.is2P ? [0, 1] : [0, 1, 2], function (index) {
    var _vm$$store$state$syst, _vm$$store$state$syst2, _vm$$store$state$syst3;
    return _c('div', {
      key: index
    }, [_c('div', {
      staticClass: "form-label"
    }, [_c('span', [_vm._v("Phase " + _vm._s(index + 1))]), _c('span', {
      staticClass: "total-kw"
    }, [_vm._v(" " + _vm._s(_vm.roundToDown(_vm.$store.state.project.spec.dcSize / (_vm.$store.getters.is2P ? 2 : 3), 2)) + "kW available ")])]), _c('UnitInputGroupUnequalAllocation', {
      ref: "group",
      refInFor: true,
      attrs: {
        "phase-idx": index + 1,
        "suffix": _vm.suffix,
        "available": _vm.$store.state.project.spec.dcSize / (_vm.$store.getters.is2P ? 2 : 3),
        "readonly": _vm.isReadOnly,
        "enabled-units": (_vm$$store$state$syst = _vm.$store.state.systemInfo) === null || _vm$$store$state$syst === void 0 ? void 0 : (_vm$$store$state$syst2 = _vm$$store$state$syst.config) === null || _vm$$store$state$syst2 === void 0 ? void 0 : (_vm$$store$state$syst3 = _vm$$store$state$syst2.enabled_units) === null || _vm$$store$state$syst3 === void 0 ? void 0 : _vm$$store$state$syst3[index],
        "default-even": ""
      },
      model: {
        value: _vm.$store.state.project.algorithmWeight[index],
        callback: function ($$v) {
          _vm.$set(_vm.$store.state.project.algorithmWeight, index, $$v);
        },
        expression: "$store.state.project.algorithmWeight[index]"
      }
    })], 1);
  })], 2) : _vm._e(), _c('a-row', {
    staticClass: "back-button",
    attrs: {
      "type": "flex",
      "align": "middle"
    }
  }, [_c('a-col', {
    attrs: {
      "span": 12
    }
  }, [_c('back-button', {
    style: {
      marginTop: 0
    }
  })], 1), _c('a-col', {
    attrs: {
      "span": 12
    }
  }, [_c('yellow-button', {
    attrs: {
      "loading": _vm.loading
    },
    on: {
      "click": _vm.saveClicked
    }
  }, [_vm._v(" SAVE ")])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }