<template>
  <div class="content">
    <progress-bar :percent="10" />

    <div class="info-text">
      <p>Sorry! We can't find your SolShare online.</p>

      <p>
        Please make sure the antenna is connected and that all unit's tenancy
        solar main switches are on.
      </p>

      <p>
        If the product still isn't online after 5 minutes please contact Allume.
      </p>
    </div>

    <white-button bordered @click="tryAgainClicked">
      OK, LET’S TRY AGAIN
    </white-button>
  </div>
</template>

<script>
export default {
  methods: {
    tryAgainClicked() {
      this.$store.dispatch("searchMeter", {});
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  .info-text {
    margin-top: 26px;
    margin-bottom: 40px;
  }
}
</style>
