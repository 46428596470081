var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "content"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v("Congratulations!")]), _c('div', {
    staticClass: "info-text"
  }, [_vm._v("You’re an official SolShare installer.")]), _vm._m(0), _c('ShareNetwork', {
    attrs: {
      "title": " ",
      "network": "facebook",
      "url": _vm.website,
      "hashtags": _vm.hashtags
    }
  }, [_c('FacebookSVG', {
    staticClass: "social-network"
  })], 1), _c('ShareNetwork', {
    attrs: {
      "title": " ",
      "network": "linkedin",
      "url": _vm.website,
      "hashtags": _vm.hashtags
    }
  }, [_c('LinkedInSVG', {
    staticClass: "social-network"
  })], 1), _c('ShareNetwork', {
    attrs: {
      "network": "twitter",
      "title": " ",
      "url": _vm.website,
      "hashtags": _vm.hashtags,
      "twitter-user": _vm.twitterUser
    }
  }, [_c('TwitterSVG', {
    staticClass: "social-network"
  })], 1), _c('back-and-next', {
    attrs: {
      "next-text": "home",
      "to": "/welcome"
    }
  })], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "normal-text"
  }, [_c('p', [_vm._v("Post a photo of your SolShare installation on social media with:")]), _vm._v(" #allumeenergy "), _c('br'), _vm._v(" #solshare "), _c('br'), _vm._v(" #install"), _c('br'), _c('img', {
    attrs: {
      "src": require("../assets/png/emoji.png"),
      "width": "26"
    }
  })]);

}]

export { render, staticRenderFns }