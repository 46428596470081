import semver from "semver";
import { GENERATION } from "./constants";

export function isRWB(serial) {
  if (!/3P_35A_\d{4}(_test)?/.test(serial)) {
    return false;
  }
  const number = Number(serial.match(/\d{4}/)[0]);
  return number < 400;
}

export function is75A(serial) {
  if (!/3P_35A_\d{4}(_test)?/.test(serial)) {
    return false;
  }
  const number = Number(serial.match(/\d{4}/)[0]);
  return number >= 73 && number <= 304;
}

export function shouldNotShowUpdateInstructions(serial, swVersion) {
  const swVer = semver.valid(swVersion) || "0.0.0";

  return (
    generation(serial) === GENERATION.ONE ||
    semver.gte(swVer, "2.7.1") ||
    semver.eq(swVer, "1.0.7")
  );
}

export function isUnequalAlgorithm(serial, swVersion) {
  if (
    !/3P_35A_\d{4}(_test)?/.test(serial) &&
    !/2P_100_\d{4}(_test)?/.test(serial)
  ) {
    return false;
  }
  const swVer = semver.valid(swVersion) || "0.0.0";
  return semver.gte(swVer, "0.4.5");
}

export function getRequiredConnections(serial) {
  if (isRWB(serial)) {
    return "R1, W1, B1";
  }
  if (serial.startsWith("2P")) {
    return "L1, L2";
  } else {
    return "L1-1, L2-1, L3-1";
  }
}

export function generation(serial) {
  const number = Number(serial.match(/\d{4}/)[0]);
  if (serial.startsWith("2P")) {
    if (number < 120) {
      return GENERATION.ONE;
    } else {
      return GENERATION.TWO;
    }
  } else {
    if (number < 400) {
      return GENERATION.ONE;
    } else {
      return GENERATION.TWO;
    }
  }
}
