/**
 * @author Wentong Liu
 */
"use strict";

export default {
  register: "/v2/auth/commissioner/register",
  login: "/v2/auth/commissioner/login",
  forgetPassword: "/v2/auth/commissioner/forget-password",
  resetPassword: "/v2/auth/commissioner/reset-password",
  viewFleets: "/v2/view-fleet/fleets",
  getSolsharesAtSite: (projectId) =>
    `/v2/view-fleet/solshares-at-site/${projectId}`,
  searchMeter: (serial) => `/v2/commission/meter/${serial}`,
  meterWifiStatus: (serial) => `/v2/commission/wifi/${serial}`,
  uploadConfig: "/v2/commission/upload-config",
  uploadAddress: (serial) => `/v2/commission/upload-config/address/${serial}`,
  uploadSpec: (serial) => `/v2/commission/upload-config/spec/${serial}`,
  uploadConnection: (serial) =>
    `/v2/commission/upload-config/connection/${serial}`,
  restoreConfig: (serial) => `/v2/commission/upload-config/${serial}`,
  configStatus: (serial) => `/v2/commission/config-status/${serial}`,
  triggerCommission: (serial) => `/v2/commission/trigger/${serial}`,
  commissionLog: (serial) => `/v2/commission/log/${serial}`,
  inviteProvider: (serial) => `/v2/projects/invite/meter/${serial}`,
  feedback: "/v2/commission/feedback",
  netPromoterScore: "/v2/commission/net-promoter-score",
  systemInfo: (serial) => `/v2/service-interface/system-info/${serial}`,
  updateBuildingAddress: (serial) =>
    `/v2/service-interface/building-address/${serial}`,
  updateSolarDeliveryMethod: (serial) =>
    `/v2/service-interface/solar-delivery-method/${serial}`,
  updateConnectedUnits: (serial) =>
    `/v2/service-interface/connected-units/${serial}`,
  updateSoftware: "/v2/commission/update-software",
  getUserLocation1: "https://ipinfo.io/json?token=7d3fdbb7d30207",
  getUserLocation2: "https://myip.wtf/json",
};
