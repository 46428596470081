<template>
  <div class="content">
    <back-button />
    <div class="title">
      System Building Address <BlackISVG @click="note1Visible = true" />
    </div>

    <a-form-model ref="form" :colon="false" :model="form">
      <a-form-model-item prop="solshareName">
        <template #label>
          Solshare Name <BlackISVG @click="note2Visible = true" />
        </template>
        <a-input
          v-model.trim="form.solshareName"
          size="large"
          class="input-box"
          disabled
        />
      </a-form-model-item>

      <a-form-model-item label="Address" prop="address">
        <google-autocomplete
          id="address-input"
          v-model.trim="form.address"
          classname="input-box"
          :country="Object.values(COUNTRY_CODE)"
          :enable-geolocation="true"
          disabled
        >
        </google-autocomplete>
      </a-form-model-item>
    </a-form-model>

    <a-row type="flex" align="middle">
      <a-col :span="12">
        <back-button :style="{ marginTop: 0 }" />
      </a-col>
      <a-col :span="12">
        <yellow-button
          data-tn="building-address-next"
          :loading="loading"
          @click="editClicked"
        >
          EDIT
        </yellow-button>
      </a-col>
    </a-row>

    <yellow-modal v-model="note1Visible" icon padding="62px 26px 21px 26px">
      <div :style="{ fontSize: '21px', marginBottom: '1em' }">
        Only enter the main building address details. Do not include any
        individual unit details here.
      </div>
    </yellow-modal>

    <yellow-modal v-model="note2Visible" icon padding="62px 26px 21px 26px">
      <div :style="{ fontSize: '21px', marginBottom: '1em' }">
        This is a descriptive label/name for each SolShare - use this if you
        would like to distinguish the SolShare between others installed on site.
        Examples include "SolShare 1", "SolShare closest to the MSB", "SolShare
        for Building A", etc.
      </div>
    </yellow-modal>
  </div>
</template>

<script>
import BlackISVG from "../assets/svg/black-i-filled.svg";
import { COUNTRY_CODE } from "../util/constants";

export default {
  components: {
    BlackISVG,
  },
  data() {
    return {
      COUNTRY_CODE,
      note1Visible: false,
      note2Visible: false,
      loading: false,
      form: {
        solshareName: this.$store.state.systemInfo.meter.alias,
        address: this.$store.state.systemInfo.meter.project.address,
        latitude: null,
        longitude: null,
      },
    };
  },
  methods: {
    editClicked() {
      this.$router.push("/edit-building-address");
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  .title {
    line-height: 38px;
    margin-top: 20px;
    margin-bottom: 27px;
  }
}
</style>
