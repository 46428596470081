<template>
  <div class="content">
    <progress-bar :percent="10" />
    <TickSVG class="ok-icon" />
    <div class="info-text">This SolShare has already been commissioned.</div>

    <div class="small-text">To reconfigure the unit, contact Allume.</div>

    <yellow-button class="contact-button" @click="systemInfoClicked">
      <a-icon v-if="loading" type="loading" />
      <span v-else>VIEW SYSTEM INFO</span>
    </yellow-button>

    <yellow-button to="/contact" class="contact-button">
      CONTACT NOW
    </yellow-button>

    <yellow-button to="/commission/1"> COMMISSION ANOTHER UNIT </yellow-button>

    <back-button :style="{ textAlign: 'left' }" :step="-2" />
  </div>
</template>

<script>
import TickSVG from "../assets/svg/tick.svg";

export default {
  components: { TickSVG },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    systemInfoClicked() {
      this.loading = true;
      this.$store.dispatch("getSystemInfo", {
        serial: this.$store.state.project.serialNumber,
        redirect: true,
      });
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  text-align: center;

  .ok-icon {
    margin-top: 68px;
    margin-bottom: 16px;
  }
  .info-text {
    color: #005149;
    margin-bottom: 63px;
  }
  .small-text {
    text-align: left;
    margin-bottom: 14px;
    font-size: 17px;
  }
  .contact-button {
    margin-bottom: 13px;
  }
}
</style>
