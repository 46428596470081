var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('a-button', {
    staticClass: "yellow-button",
    attrs: {
      "data-tn": _vm.dataTn,
      "shape": "round",
      "block": _vm.block,
      "size": "large",
      "loading": _vm.loading,
      "disabled": _vm.disabled,
      "html-type": _vm.htmlType
    },
    on: {
      "click": _vm.onClick
    }
  }, [_vm._t("default")], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }