<template>
  <a-progress
    :percent="percent"
    :show-info="false"
    :stroke-color="{ from: '#FFE86C', to: '#FFE86C' }"
    class="progress"
  />
</template>

<script>
export default {
  name: "ProgressBar",
  props: { percent: { type: Number, default: 0 } },
};
</script>

<style scoped>
.progress {
  margin-top: 22px;
}
</style>
