<template>
  <div class="content">
    <progress-bar :percent="10" />
    <SearchSVG class="search-icon" />
    <div class="info-text">
      We are searching for your SolShare. This should only take a couple of
      minutes.
    </div>
  </div>
</template>

<script>
import SearchSVG from "../assets/svg/search.svg";

export default {
  components: { SearchSVG },
};
</script>

<style lang="less" scoped>
.content {
  text-align: center;

  .search-icon {
    margin-top: 52px;
    margin-bottom: 16px;
    position: relative;
    left: -10px;
    top: -10px;
    animation: moveX 600ms cubic-bezier(0.36, 0, 0.64, 1) -300ms infinite alternate,
      moveY 600ms cubic-bezier(0.36, 0, 0.64, 1) 0s infinite alternate;
  }
  .info-text {
    color: #005149;
  }

  @keyframes moveX {
    0% {
      left: -10px;
    }
    100% {
      left: 10px;
    }
  }
  @keyframes moveY {
    0% {
      top: -10px;
    }
    100% {
      top: 10px;
    }
  }
}
</style>
