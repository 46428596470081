var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "content"
  }, [_c('progress-bar', {
    attrs: {
      "percent": 60
    }
  }), _c('IndexFingerSVG', {
    staticClass: "finder-icon"
  }), _c('div', {
    staticClass: "info-text"
  }, [_vm._v(" It is important that the correct CT type is selected. This information will be written on the CT. ")]), _vm.$store.getters.is75A ? _c('white-button', {
    staticClass: "white-button",
    attrs: {
      "data-tn": "ct-rating-75a",
      "bordered": "",
      "to": "/commission/19"
    },
    on: {
      "click": function () {
        return _vm.ratingClicked(_vm.CT_RATING.A75);
      }
    }
  }, [_vm._v(" 75A (DEFAULT) ")]) : !_vm.$store.getters.is2P ? _c('white-button', {
    staticClass: "white-button",
    attrs: {
      "data-tn": "ct-rating-120a",
      "bordered": "",
      "to": "/commission/19"
    },
    on: {
      "click": function () {
        return _vm.ratingClicked(_vm.CT_RATING.A120);
      }
    }
  }, [_vm._v(" 120A (DEFAULT) ")]) : _vm._e(), _c('white-button', {
    staticClass: "white-button",
    attrs: {
      "data-tn": "ct-rating-200a",
      "bordered": "",
      "to": "/commission/19"
    },
    on: {
      "click": function () {
        return _vm.ratingClicked(_vm.CT_RATING.A200);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$store.getters.is2P ? "200A (DEFAULT)" : "200A") + " ")]), _c('back-button')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }