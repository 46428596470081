<template>
  <div class="content">
    <back-button />
    <div class="title">Sites</div>

    <div v-if="$store.state.fleet.length === 0" class="wrapper">
      <div class="info-text">
        It seems you don't have a commissioned site yet.
      </div>
    </div>

    <div v-else class="wrapper">
      <div
        v-for="(fleet, i) of $store.state.fleet"
        :key="i"
        class="item"
        @click="() => itemClicked(fleet.id)"
      >
        <span>{{ fleet.address }}</span>
        <SmallArrow />
      </div>
    </div>
  </div>
</template>

<script>
import SmallArrow from "../assets/svg/small-arrow.svg";

export default {
  name: "ViewFleet",
  components: { SmallArrow },
  mounted() {
    this.$store.dispatch("getFleets");
  },
  methods: {
    itemClicked(projectId) {
      this.$store.dispatch("getSolsharesAtSite", { projectId });
      this.$router.push({
        path: "/solshares-at-site",
        query: { from: "view-fleet" },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  .title {
    margin-bottom: 25px;
  }

  .wrapper {
    .info-text {
      margin-top: 40px;
    }

    .item {
      height: 50px;
      border-radius: 10px;
      background: rgba(157, 157, 157, 0.2);
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 17px;
      padding: 11px 10px 11px 19px;
      margin-bottom: 21px;
      cursor: pointer;
    }
  }
}
</style>
