var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c(_vm.label.startsWith('L') ? _vm.phaseIdxToColor(_vm.phase) : _vm.coloredInput[_vm.color], {
    tag: "component",
    staticClass: "input-box",
    attrs: {
      "size": "large",
      "placeholder": "Type in unit name",
      "disabled": _vm.disabled || !_vm.checked,
      "data-tn": `unit-input-${_vm.label}`
    },
    scopedSlots: _vm._u([{
      key: "addonBefore",
      fn: function () {
        return [_c('span', {
          staticClass: "label"
        }, [_vm._v(" " + _vm._s(_vm.label) + " ")])];
      },
      proxy: true
    }, _vm._l(_vm.$scopedSlots, function (_, slot) {
      return {
        key: slot,
        fn: function (scope) {
          return [_vm._t(slot, null, null, scope)];
        }
      };
    }), _vm.disabled ? {
      key: "suffix",
      fn: function () {
        return [_c('a-checkbox', {
          staticClass: "checkbox",
          attrs: {
            "checked": _vm.checked
          }
        })];
      },
      proxy: true
    } : null], null, true),
    model: {
      value: _vm.text,
      callback: function ($$v) {
        _vm.text = $$v;
      },
      expression: "text"
    }
  });

}
var staticRenderFns = []

export { render, staticRenderFns }