var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "content"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v("App feedback")]), _c('green-info-box', {
    staticClass: "info-box"
  }, [_vm._v(" You will not lose your progress in the commissioning by giving feedback here. ")]), _c('p', [_vm._v("Help us improve:")]), _c('div', {
    staticClass: "grey-box"
  }, [_c('p', [_vm._v("Simply flag an issue with this part of the commissioning app:")]), _c('yellow-button', {
    attrs: {
      "loading": _vm.flagLoading
    },
    on: {
      "click": _vm.flagIssueClicked
    }
  }, [_c('FlagSVG', {
    staticClass: "flag-icon"
  }), _c('span', [_vm._v(" FLAG ISSUE")])], 1), _c('p', {
    staticClass: "small-text"
  }, [_vm._v("and return to the commissioning process.")])], 1), _c('p', {
    staticClass: "big-or"
  }, [_vm._v("OR")]), _c('div', {
    staticClass: "grey-box"
  }, [_c('p', [_vm._v("Write us a comment:")]), _c('a-textarea', {
    staticClass: "comment-box",
    attrs: {
      "placeholder": "I have experienced an issue with...",
      "data-tn": "feedback-text-area"
    },
    model: {
      value: _vm.comment,
      callback: function ($$v) {
        _vm.comment = typeof $$v === 'string' ? $$v.trim() : $$v;
      },
      expression: "comment"
    }
  }), _c('yellow-button', {
    attrs: {
      "loading": _vm.submitLoading,
      "data-tn": "feedback-submit"
    },
    on: {
      "click": _vm.submitClicked
    }
  }, [_vm._v(" SUBMIT FEEDBACK ")]), _c('p', {
    staticClass: "small-text"
  }, [_vm._v("and return to the commissioning process.")])], 1), _c('back-button', {
    attrs: {
      "text": "cancel"
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }