import { ALGORITHM_TYPE, SOLAR_DELIVERY_METHOD } from "../util/constants";

export const solarDeliveryMethodMixin = {
  data() {
    return {
      SOLAR_DELIVERY_METHOD,
      ALGORITHM_TYPE,
    };
  },
  methods: {
    getAlgorithmTypeFromSolarDeliveryMethod(method) {
      switch (method) {
        case SOLAR_DELIVERY_METHOD.NET_METERING:
        case SOLAR_DELIVERY_METHOD.OPTIMISED_AND_EVEN:
        case SOLAR_DELIVERY_METHOD.UNEQUAL_ALLOCATION:
          return ALGORITHM_TYPE.CUSTOM_ALLOCATION;

        case SOLAR_DELIVERY_METHOD.FULLY_OPTIMISED:
          return ALGORITHM_TYPE.DEMAND_BASED_DELIVERY;

        default:
          return null;
      }
    },

    getSolarDeliveryMethodFromAlgorithmType(type) {
      if (this.$store.getters.is2P) {
        return SOLAR_DELIVERY_METHOD.NET_METERING;
      } else {
        if (type === ALGORITHM_TYPE.DEMAND_BASED_DELIVERY) {
          return SOLAR_DELIVERY_METHOD.FULLY_OPTIMISED;
        } else if (type === ALGORITHM_TYPE.CUSTOM_ALLOCATION) {
          return this.$store.getters.isUnequalAlgorithm
            ? SOLAR_DELIVERY_METHOD.UNEQUAL_ALLOCATION
            : SOLAR_DELIVERY_METHOD.OPTIMISED_AND_EVEN;
        }
      }
    },

    methodClicked(type) {
      this.$store.commit(
        "setDeliveryMethod",
        this.getSolarDeliveryMethodFromAlgorithmType(type)
      );

      if (
        this.$store.getters.isUnequalAlgorithm &&
        type === ALGORITHM_TYPE.CUSTOM_ALLOCATION
      ) {
        this.$store.commit("enableUnequalAlgorithm");
      } else {
        this.$store.commit("disableUnequalAlgorithm");
      }
    },
  },
};
