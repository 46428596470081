<template>
  <div class="content">
    <progress-bar :percent="20" class="progress-bar" />

    <div class="center">
      <a-progress
        type="circle"
        :percent="Math.round(progress)"
        stroke-color="#FFE86C"
        trail-color="#005149"
        :width="158"
        class="big-progress"
      >
        <template #format="percent">
          <span
            style="
              color: #005149;
              font-size: 40px;
              font-family: Helvetica Light, sans-serif;
            "
            >{{ percent }}</span
          >
          <span
            style="
              color: #005149;
              font-family: Helvetica Light, sans-serif;
              font-size: 12px;
            "
            >%</span
          >
        </template>
      </a-progress>

      <div class="notice">
        We have noticed this unit could do with a software update.
      </div>

      <div class="note">
        Please wait and ensure the unit stays powered whilst we complete this.
        This may take up to 20 minutes.
      </div>
    </div>

    <back-button />
  </div>
</template>

<script>
export default {
  name: "SoftwareUpdate",
  data() {
    return {
      progress: 0,
    };
  },
  mounted() {
    this.update();
  },
  methods: {
    async increaseProgress(from, to, duration) {
      // this never resolves
      return new Promise((resolve, reject) => {
        const step = (to - from) / (duration * 10);
        this.progress = from;
        const id = setInterval(() => {
          this.progress += step;
          if (this.progress >= to) {
            clearInterval(id);
          }
        }, 100);
      });
    },
    async update() {
      const stepProgress =
        100 / this.$store.state.softwareUpdate.updateTask.length;

      console.log("update tasks:", this.$store.state.softwareUpdate.updateTask);

      try {
        for (
          let i = 0;
          i < this.$store.state.softwareUpdate.updateTask.length;
          i++
        ) {
          await Promise.race([
            this.increaseProgress(
              stepProgress * i,
              Math.min(stepProgress * (i + 1), 99),
              180
            ),
            this.doUpdate(
              stepProgress * (i + 1),
              this.$store.state.softwareUpdate.updateTask[i]
            ),
          ]);
        }

        this.progress = 100;
        this.$store.commit("setSoftwareUpdate", {
          updateTask: [],
          forceUpdate: false,
        });
        await this.$router.push("/software-update-success");
      } catch (e) {
        await this.$router.push("/software-update-failed");
      }
    },

    async doUpdate(progress, task) {
      console.log(`running ${task}...`);
      const {
        data: { status, log },
      } = await this.$http.post(this.$api.updateSoftware, {
        device: this.$store.state.project.serialNumber,
        job: task,
      });

      console.log(status);
      if (status !== "SUCCESS") {
        console.log("---- LOG BEGIN ----");
        console.log(log);
        console.log("---- LOG END ----");
        throw Error(`running ${task} failed`);
      } else {
        this.progress = progress;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  .progress-bar {
    margin-bottom: 41px;
  }

  .big-progress {
    margin-bottom: 40px;
  }

  .notice {
    margin-bottom: 30px;
    font-size: 25px;
    color: #005149;
    font-family: "Helvetica Light", sans-serif;
    line-height: 29px;
  }

  .note {
    margin-bottom: 56px;
    font-size: 17px;
    font-family: "Helvetica Light", sans-serif;
  }

  .center {
    text-align: center;
  }
}
</style>
