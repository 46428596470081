var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('a-progress', {
    staticClass: "progress",
    attrs: {
      "percent": _vm.percent,
      "show-info": false,
      "stroke-color": {
        from: '#FFE86C',
        to: '#FFE86C'
      }
    }
  });

}
var staticRenderFns = []

export { render, staticRenderFns }