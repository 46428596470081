import {
  DISPLAY_FAULT,
  FAULT_DESCRIPTION,
  FAULT_TYPE,
} from "../util/constants";

export const faultMixin = {
  data() {
    return { DISPLAY_FAULT, FAULT_DESCRIPTION };
  },
  computed: {
    solshare() {
      return this.$store.state.systemInfo.solsharesAtSite.find(
        (solshare) => solshare.serial === this.$store.state.project.serialNumber
      );
    },
  },
  methods: {
    isNonCritical(solshare = this.solshare) {
      if (!solshare) {
        return false;
      }
      return (
        solshare.nonCriticalFaults.filter((fault) =>
          DISPLAY_FAULT.includes(fault)
        ).length > 0
      );
    },
    isCritical(solshare = this.solshare) {
      if (!solshare) {
        return false;
      }
      return (
        solshare.criticalFaults.filter((fault) => DISPLAY_FAULT.includes(fault))
          .length > 0
      );
    },
    isOffline(solshare = this.solshare) {
      if (!solshare) {
        return false;
      }
      return solshare.nonCriticalFaults.includes(FAULT_TYPE.OFFLINE);
    },
    displayFaults(solshare = this.solshare) {
      if (!solshare) {
        return [];
      }
      return [...solshare.criticalFaults, ...solshare.nonCriticalFaults].filter(
        (f) => DISPLAY_FAULT.includes(f)
      );
    },
  },
};
