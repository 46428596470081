<template>
  <div class="content">
    <progress-bar :percent="10" />

    <div class="info-text">
      <p>Sorry! We cannot find the SolShare you entered.</p>

      <p>Please re-type the serial number.</p>

      <p>If the problem persists, please contact Allume.</p>
    </div>

    <white-button bordered @click="tryAgainClicked">
      OK, LET’S TRY AGAIN
    </white-button>
  </div>
</template>

<script>
export default {
  methods: {
    tryAgainClicked() {
      this.$router.replace("/commission/2");
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  .info-text {
    margin-top: 26px;
    margin-bottom: 40px;
  }
}
</style>
