var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.hint ? _c('a-row', {
    attrs: {
      "type": "flex",
      "align": "middle",
      "gutter": [8, 8]
    }
  }, [_c('a-col', {
    attrs: {
      "offset": 18,
      "span": 6
    }
  }, [_c('div', {
    staticClass: "small-text"
  }, [_vm._v("Connected")])])], 1) : _vm._e(), _vm._l([_vm.PHASE.L1, _vm.PHASE.L2], function (phase) {
    return _c('a-row', {
      key: phase,
      attrs: {
        "type": "flex",
        "align": "middle",
        "gutter": [8, 8]
      }
    }, [_c('a-col', {
      attrs: {
        "span": _vm.disabled ? 24 : 18
      }
    }, [phase === _vm.PHASE.L1 ? _c('unit-input-box', {
      attrs: {
        "label": _vm.connection.toString(),
        "checked": _vm.value[phase] !== _vm.NO_CONNECTION,
        "disabled": _vm.disabled
      },
      model: {
        value: _vm.L1,
        callback: function ($$v) {
          _vm.L1 = $$v;
        },
        expression: "L1"
      }
    }) : phase === _vm.PHASE.L2 ? _c('unit-input-box', {
      attrs: {
        "label": (_vm.connection + 1).toString(),
        "checked": _vm.value[phase] !== _vm.NO_CONNECTION,
        "disabled": _vm.disabled
      },
      model: {
        value: _vm.L2,
        callback: function ($$v) {
          _vm.L2 = $$v;
        },
        expression: "L2"
      }
    }) : _vm._e()], 1), !_vm.disabled ? _c('a-col', {
      staticClass: "grey-box",
      attrs: {
        "span": 6
      }
    }, [_c('div', {
      staticClass: "center-box"
    }, [_c('a-checkbox', {
      attrs: {
        "checked": _vm.value[phase] !== _vm.NO_CONNECTION
      },
      on: {
        "change": function () {
          return _vm.toggleConnected(phase);
        }
      }
    })], 1)]) : _vm._e()], 1);
  }), _c('a-form-model', {
    ref: "form",
    attrs: {
      "model": _vm.value,
      "rules": _vm.rules
    }
  }, [_c('a-form-model-item', {
    attrs: {
      "prop": "connections"
    }
  }, [false ? _c('a-input') : _vm._e()], 1)], 1)], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }