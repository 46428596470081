var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "content"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v("Info Centre")]), _c('a-row', {
    attrs: {
      "gutter": [11, 5]
    }
  }, [_c('a-col', [_c('a', {
    staticClass: "green-box",
    attrs: {
      "href": _vm.getLinks().dataSheet,
      "target": "_blank",
      "data-tn": "infocentre-datasheet"
    }
  }, [_c('div', {
    staticClass: "icon"
  }, [_c('DataSheetsSVG')], 1), _c('div', [_vm._v("DATA SHEETS")])])])], 1), _c('a-row', {
    attrs: {
      "gutter": [11, 5]
    }
  }, [_c('a-col', [_c('a', {
    staticClass: "green-box",
    attrs: {
      "href": _vm.getLinks().installManual,
      "target": "_blank",
      "data-tn": "infocentre-install-manual"
    }
  }, [_c('div', {
    staticClass: "icon"
  }, [_c('InstallationManualsSVG')], 1), _c('div', [_vm._v("INSTALLATION MANUALS")])])])], 1), _c('a-row', {
    attrs: {
      "gutter": [11, 5]
    }
  }, [_c('a-col', [_c('a', {
    staticClass: "green-box",
    attrs: {
      "href": _vm.getLinks().wifiCreds,
      "target": "_blank",
      "data-tn": "infocentre-wifi-creds"
    }
  }, [_c('div', {
    staticClass: "icon"
  }, [_c('WifiSVG', {
    attrs: {
      "width": "86",
      "height": "56",
      "view-box": "3 0 18 30"
    }
  })], 1), _c('div', [_vm._v("CHANGING WIFI CREDENTIALS")])])])], 1), _c('a-row', {
    attrs: {
      "gutter": [11, 5]
    }
  }, [_c('a-col', [_c('a', {
    staticClass: "green-box",
    on: {
      "click": function ($event) {
        return _vm.$router.push('/commission/1?info=true');
      }
    }
  }, [_c('div', {
    staticClass: "icon"
  }, [_c('StartShutProcedureSVG')], 1), _c('div', [_vm._v("START UP / SHUTDOWN PROCEDURE")])])])], 1), _c('a-row', {
    attrs: {
      "gutter": [11, 5]
    }
  }, [_c('a-col', [_c('a', {
    staticClass: "green-box",
    on: {
      "click": function ($event) {
        return _vm.$router.push('/safety-label?info=true');
      }
    }
  }, [_c('div', {
    staticClass: "icon"
  }, [_c('MSBLabelSVG')], 1), _c('div', [_vm._v("WHERE TO PLACE THE MSB LABELS")])])])], 1), _c('back-button')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }