import styled from "vue-styled-components";

export const CircleIcon = styled("div", {
  opacity: Number,
  size: Number,
  color: String,
  shadow: String,
})`
  width: ${({ size }) => size ?? 42}px;
  height: ${({ size }) => size ?? 42}px;
  background: ${({ color }) => color ?? "#005149"};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: ${({ opacity }) => opacity ?? 1};
  ${({ shadow }) => (shadow ? `box-shadow: ${shadow}` : "")}
`;
