<template>
  <div class="content">
    <progress-bar :percent="60" />

    <div class="info-text">What solar delivery method is required?</div>

    <white-button
      v-if="!$store.getters.is2P"
      data-tn="demand-based-delivery"
      bordered
      class="white-button"
      to="/commission/ct-rating"
      @click="() => methodClicked(ALGORITHM_TYPE.DEMAND_BASED_DELIVERY)"
    >
      DEMAND-BASED DELIVERY
    </white-button>

    <white-button
      data-tn="custom-allocation"
      bordered
      class="white-button"
      :to="
        $store.getters.isUnequalAlgorithm
          ? '/commission/unequal-allocation-review'
          : '/commission/ct-rating'
      "
      @click="() => methodClicked(ALGORITHM_TYPE.CUSTOM_ALLOCATION)"
    >
      CUSTOM ALLOCATION
    </white-button>

    <WarningSVG />
    <span class="small-text">If unsure, click the ‘Unsure’ button below:</span>

    <white-button
      bordered
      class="white-button"
      to="/commission/18"
      bg-color="rgba(229,248,240,0.7)"
      data-tn="solar-delivery-unsure"
    >
      UNSURE
    </white-button>

    <back-button />
  </div>
</template>

<script>
import WarningSVG from "../assets/svg/black-warning.svg";
import { solarDeliveryMethodMixin } from "../mixins/solarDeliveryMethod";
export default {
  components: { WarningSVG },
  mixins: [solarDeliveryMethodMixin],
};
</script>

<style lang="less" scoped>
.content {
  .info-text {
    margin-top: 29px;
    margin-bottom: 30px;
  }

  .small-text {
    letter-spacing: -0.35px;
    margin-left: 5px;
  }

  .white-button {
    margin-top: 8px;
    margin-bottom: 13px;
  }
}
</style>
