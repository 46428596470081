var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "content"
  }, [_c('progress-bar', {
    staticClass: "progress-bar",
    attrs: {
      "percent": 20
    }
  }), _c('div', {
    staticClass: "center"
  }, [_c('TickSVG', {
    staticClass: "tick"
  }), _c('div', {
    staticClass: "text"
  }, [_vm._v(" Success! You're all up to date. "), _vm.showUpdateTip ? _c('div', [_vm._v(" Please ensure all isolation points in maintenance isolation board are "), _c('b', [_vm._v("closed")]), _vm._v(" (connected) before proceeding. ")]) : _vm._e()])], 1), _c('back-and-next', {
    attrs: {
      "to": "/commission/10",
      "back-step": -2,
      "data-tn": "next"
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }