var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "green-info-box"
  }, [_c('a-row', {
    attrs: {
      "type": "flex",
      "justify": "start",
      "align": "top"
    }
  }, [_c('a-col', {
    attrs: {
      "span": 3
    }
  }, [_c('WarningSVG', {
    staticClass: "warning-icon"
  })], 1), _c('a-col', {
    attrs: {
      "span": 21
    }
  }, [_vm._t("default")], 2)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }