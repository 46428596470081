<template>
  <div class="content">
    <ErrorSVG class="error-icon" />

    <div class="info-text">
      <p v-if="$store.state.exceptionHTML">
        Sorry! <span v-html="$store.state.exception" />
      </p>
      <p v-else data-tn="error-text">Sorry! {{ $store.state.exception }}</p>

      <p v-if="$store.state.exceptionTryAgain">Please go back and try again.</p>
    </div>

    <back-button class="back-button" />
  </div>
</template>

<script>
import ErrorSVG from "../assets/svg/error.svg";
export default {
  components: {
    ErrorSVG,
  },
};
</script>

<style lang="less" scoped>
.content {
  text-align: center;

  .error-icon {
    margin-top: 92px;
    margin-bottom: 31px;
  }
  .info-text {
    text-align: left;
    margin-bottom: 167px;
  }

  .back-button {
    text-align: left;
  }
}
</style>
