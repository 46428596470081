<template>
  <a-progress
    type="circle"
    :percent="Math.round(progress)"
    stroke-color="#FFE86C"
    trail-color="#005149"
    :width="158"
    class="big-progress"
  >
    <template #format="percent">
      <span
        style="
          color: #005149;
          font-size: 40px;
          font-family: Helvetica Light, sans-serif;
        "
      >
        {{ percent }}
      </span>
      <span
        style="
          color: #005149;
          font-family: Helvetica Light, sans-serif;
          font-size: 12px;
        "
      >
        %
      </span>
    </template>
  </a-progress>
</template>

<script>
export default {
  name: "CircleProgress",
  data() {
    return {
      progress: 0,
      interval: null,
    };
  },
  methods: {
    startFrom(from, to, duration) {
      if (this.interval) {
        clearInterval(this.interval);
      }

      this.progress = from;

      const stepDuration = duration / (to - from);

      this.interval = setInterval(() => {
        if (this.progress < to) {
          this.progress++;
        } else {
          this.stop();
        }
      }, stepDuration);
    },
    getValue() {
      return this.progress;
    },
    setValue(v) {
      this.progress = v;
    },
    stop() {
      if (this.interval) {
        clearInterval(this.interval);
        this.interval = null;
      }
    },
    complete() {
      this.stop();
      this.progress = 100;
    },
  },
};
</script>
