var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "content"
  }, [_c('progress-bar', {
    attrs: {
      "percent": 100
    }
  }), _c('div', {
    staticClass: "info-text"
  }, [_vm._v(" On a 0-to-10 scale, how likely is it that you would recommend the SolShare? ")]), _c('vue-slider', {
    attrs: {
      "adsorb": true,
      "interval": 1,
      "marks": true,
      "max": 10,
      "drag-on-click": true,
      "tooltip": 'none',
      "contained": true,
      "dot-size": [41, 80],
      "process-style": {
        backgroundColor: '#FFE86C',
        height: '5px',
        borderRadius: '5px',
        cursor: 'pointer'
      },
      "rail-style": {
        backgroundColor: '#ccc',
        height: '5px',
        borderRadius: '5px',
        cursor: 'pointer'
      }
    },
    scopedSlots: _vm._u([{
      key: "dot",
      fn: function () {
        return [_c('div', {
          staticClass: "score"
        }, [_vm._v(_vm._s(_vm.score))]), _c('SliderHandle', {
          staticClass: "dot"
        })];
      },
      proxy: true
    }, {
      key: "step",
      fn: function (_ref) {
        var active = _ref.active,
          pos = _ref.pos;
        return [pos < 100 ? _c('div', {
          class: ['slider-step', {
            active
          }]
        }) : _vm._e()];
      }
    }, {
      key: "label",
      fn: function (_ref2) {
        var pos = _ref2.pos;
        return [_c('div', {
          class: ['vue-slider-mark-label', 'custom-label']
        }, [pos === 0 ? _c('ThumbsDown') : _vm._e(), pos === 100 ? _c('ThumbsUp') : _vm._e()], 1)];
      }
    }]),
    model: {
      value: _vm.score,
      callback: function ($$v) {
        _vm.score = $$v;
      },
      expression: "score"
    }
  }), _c('div', {
    staticClass: "question"
  }, [_vm._v("What is the primary reason for your score?")]), _c('a-textarea', {
    ref: "textarea",
    staticClass: "comment-box",
    attrs: {
      "id": "feedback",
      "placeholder": "Leave your comment here…",
      "data-tn": "nps-text-area"
    },
    on: {
      "focus": _vm.closeModal
    },
    model: {
      value: _vm.feedback,
      callback: function ($$v) {
        _vm.feedback = typeof $$v === 'string' ? $$v.trim() : $$v;
      },
      expression: "feedback"
    }
  }), _c('yellow-button', {
    attrs: {
      "data-tn": "nps-submit"
    },
    on: {
      "click": _vm.submitClicked
    }
  }, [_vm._v(" SUBMIT ")]), _c('yellow-modal', {
    attrs: {
      "destroy-on-close": true,
      "down-arrow": "feedback",
      "closable": false,
      "top": 40,
      "after-close": _vm.afterClose
    },
    model: {
      value: _vm.noteVisible,
      callback: function ($$v) {
        _vm.noteVisible = $$v;
      },
      expression: "noteVisible"
    }
  }, [_c('div', {
    style: {
      fontFamily: 'Helvetica Medium',
      fontSize: '25px',
      textAlign: 'center'
    }
  }, [_vm._v(" Are you sure you want to leave this blank? ")]), _c('div', {
    style: {
      textAlign: 'center',
      marginTop: '15px',
      marginBottom: '40px'
    }
  }, [_vm._v(" Your feedback helps us improve the SolShare and the commissioning process. ")]), _c('white-button', {
    style: {
      margin: '0 auto'
    },
    attrs: {
      "block": false,
      "to": "/commission/22"
    },
    on: {
      "click": _vm.sendFeedback
    }
  }, [_vm._v(" IGNORE ")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }