<template>
  <svg
    width="17"
    height="17"
    :style="{ transform: `scale(${width ? width / 17 : height / 17})` }"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m13.757 2.878-1.225 1.225a6.047 6.047 0 0 1 2.234 4.692 6.061 6.061 0 0 1-12.12 0c0-1.895.874-3.58 2.233-4.692L3.654 2.878a7.767 7.767 0 0 0-2.74 5.917 7.792 7.792 0 0 0 15.584 0 7.767 7.767 0 0 0-2.74-5.917ZM9.571 1.004H7.84V9.66H9.57V1.004Z"
      :fill="color"
      :stroke="color"
      stroke-width=".3"
      fill-rule="evenodd"
    />
  </svg>
</template>

<script>
export default {
  name: "Power",
  props: {
    color: {
      type: String,
      default: "#FFF",
    },
    height: {
      type: String,
      default: "17",
    },
    width: {
      type: String,
      default: "17",
    },
  },
};
</script>

<style scoped></style>
