var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "content"
  }, [_c('progress-bar', {
    attrs: {
      "percent": 10
    }
  }), _vm._m(0), _c('white-button', {
    attrs: {
      "bordered": "",
      "data-tn": "try-again"
    },
    on: {
      "click": _vm.tryAgainClicked
    }
  }, [_vm._v(" OK, LET’S TRY AGAIN ")])], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "info-text"
  }, [_c('p', [_c('b', [_vm._v(" SolShare requires a power cycle to proceed. Please follow the shutdown procedure below: ")])]), _c('p', [_vm._v("1. Turn off all grid maintenance isolators.")]), _c('p', [_vm._v("2. Turn off the main AC isolator on the inverter.")]), _c('p', [_vm._v("3. Wait for 30 seconds before re-energizing.")])]);

}]

export { render, staticRenderFns }