var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "content"
  }, [_c('progress-bar', {
    staticClass: "progress-bar",
    attrs: {
      "percent": 20
    }
  }), _c('div', {
    staticClass: "center"
  }, [_c('a-progress', {
    staticClass: "big-progress",
    attrs: {
      "type": "circle",
      "percent": Math.round(_vm.progress),
      "stroke-color": "#FFE86C",
      "trail-color": "#005149",
      "width": 158
    },
    scopedSlots: _vm._u([{
      key: "format",
      fn: function (percent) {
        return [_c('span', {
          staticStyle: {
            "color": "#005149",
            "font-size": "40px",
            "font-family": "Helvetica Light, sans-serif"
          }
        }, [_vm._v(_vm._s(percent))]), _c('span', {
          staticStyle: {
            "color": "#005149",
            "font-family": "Helvetica Light, sans-serif",
            "font-size": "12px"
          }
        }, [_vm._v("%")])];
      }
    }])
  }), _c('div', {
    staticClass: "notice"
  }, [_vm._v(" We have noticed this unit could do with a software update. ")]), _c('div', {
    staticClass: "note"
  }, [_vm._v(" Please wait and ensure the unit stays powered whilst we complete this. This may take up to 20 minutes. ")])], 1), _c('back-button')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }