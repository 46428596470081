<template>
  <div class="content">
    <progress-bar :percent="20" />
    <div class="title">
      Add a Site <BlackISVG @click="note1Visible = true" />
    </div>

    <a-form-model ref="form" :colon="false" :rules="rules" :model="form">
      <a-form-model-item label="Address" prop="address">
        <google-autocomplete
          id="address-input"
          v-model.trim="form.address"
          classname="input-box"
          :country="Object.values(COUNTRY_CODE)"
          :enable-geolocation="true"
          data-tn="system-building-address-solshare-address"
          @placechanged="placeChanged"
          @blur="placeInputBlurred"
        >
        </google-autocomplete>
      </a-form-model-item>

      <a-form-model-item v-if="showCountryInput" prop="country" label="Country">
        <a-select
          v-model.trim="form.country"
          size="large"
          class="input-box"
          placeholder="Please input country"
          data-tn="system-building-address-country"
        >
          <a-select-option
            v-for="(code, country) in COUNTRY_CODE"
            :key="code"
            :value="code"
            :data-tn="`system-building-address-country-${code}`"
          >
            {{ country }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
    </a-form-model>

    <a-row type="flex" align="middle">
      <a-col :span="12">
        <back-button :style="{ marginTop: 0 }" />
      </a-col>
      <a-col :span="12">
        <yellow-button
          data-tn="building-address-next"
          :disabled="isSaveDisabled"
          @click="nextClicked"
        >
          NEXT
        </yellow-button>
      </a-col>
    </a-row>

    <yellow-modal v-model="note1Visible" icon padding="62px 26px 21px 26px">
      <div :style="{ fontSize: '21px', marginBottom: '1em' }">
        Only enter the main building address details. Do not include any
        individual unit details here.
      </div>
    </yellow-modal>
  </div>
</template>

<script>
import BlackISVG from "../assets/svg/black-i-filled.svg";
import { COUNTRY_CODE } from "../util/constants";

export default {
  components: {
    BlackISVG,
  },
  data() {
    return {
      COUNTRY_CODE,
      note1Visible: false,
      rules: {
        address: [
          {
            required: true,
            message: "please input address",
            trigger: "blur",
          },
        ],
        country: [
          {
            required: true,
            message: "please select country",
            trigger: "blur",
          },
        ],
      },
      showCountryInput: false,
      isSaveDisabled: false,
    };
  },
  computed: {
    form: {
      get() {
        return this.$store.state.project.address;
      },
      set() {},
    },
  },
  mounted() {
    // when user hits back from next page, if there's address but no latitude,
    // display country input
    this.showCountryInput =
      this.$store.state.project.address.address &&
      !this.$store.state.project.address.latitude;
  },
  methods: {
    nextClicked() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          this.$store.commit("setAddress", this.form);
          await this.$router.push("/commission/2");
        }
      });
    },
    placeInputBlurred() {
      this.isSaveDisabled = true;
      // this will be triggered first. when user selects an option from Google API
      // then cleared the input box and input something manually,
      // these values will need to be cleared
      this.form.latitude = null;
      this.form.longitude = null;
      this.form.country = null;

      // this delay will ensure the following code is triggered after
      // the potential placeChanged event
      setTimeout(() => {
        if (!this.form.country) {
          this.showCountryInput = true;
        }
        this.isSaveDisabled = false;
      }, 500);
    },
    placeChanged({ latitude, longitude, country }) {
      this.form.latitude = latitude;
      this.form.longitude = longitude;
      this.form.country = COUNTRY_CODE[country];
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  .title {
    line-height: 38px;
    margin-top: 20px;
    margin-bottom: 27px;
  }
}
</style>
