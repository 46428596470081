<template>
  <div class="content">
    <div class="title">Congratulations!</div>

    <div class="info-text">You’re an official SolShare installer.</div>

    <div class="normal-text">
      <p>Post a photo of your SolShare installation on social media with:</p>
      #allumeenergy <br />
      #solshare <br />
      #install<br />
      <img src="../assets/png/emoji.png" width="26" />
    </div>

    <ShareNetwork
      title=" "
      network="facebook"
      :url="website"
      :hashtags="hashtags"
    >
      <FacebookSVG class="social-network" />
    </ShareNetwork>

    <ShareNetwork
      title=" "
      network="linkedin"
      :url="website"
      :hashtags="hashtags"
    >
      <LinkedInSVG class="social-network" />
    </ShareNetwork>

    <ShareNetwork
      network="twitter"
      title=" "
      :url="website"
      :hashtags="hashtags"
      :twitter-user="twitterUser"
    >
      <TwitterSVG class="social-network" />
    </ShareNetwork>

    <back-and-next next-text="home" to="/welcome" />
  </div>
</template>

<script>
import TwitterSVG from "../assets/svg/twitter.svg";
import LinkedInSVG from "../assets/svg/linkedin.svg";
import FacebookSVG from "../assets/svg/facebook.svg";
export default {
  name: "Congratulations",
  components: { TwitterSVG, LinkedInSVG, FacebookSVG },
  data() {
    return {
      website: "https://www.allumeenergy.com.au/",
      hashtags: "allumeenergy,solshare,install",
      twitterUser: "Allume_Energy",
    };
  },
};
</script>

<style lang="less" scoped>
.content {
  .title {
    color: #005149;
    margin-top: 53px;
    margin-bottom: 23px;
  }
  .info-text {
    margin-bottom: 23px;
  }
  .normal-text {
    margin-bottom: 13px;
  }

  .social-network {
    margin-right: 17px;
    margin-bottom: 77px;
  }
}
</style>
