var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "content"
  }, [_c('progress-bar', {
    attrs: {
      "percent": 70
    }
  }), _c('FlashSVG', {
    staticClass: "flash-icon"
  }), _c('yellow-button', {
    attrs: {
      "data-tn": "commission-solshare-button"
    },
    on: {
      "click": _vm.commissionClicked
    }
  }, [_vm._v(" COMMISSION SOLSHARE ")]), _c('back-button', {
    style: {
      textAlign: 'left'
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }