var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "screen",
    attrs: {
      "id": "app"
    }
  }, [_c('NavBar'), _c('transition', {
    attrs: {
      "name": "page",
      "mode": "out-in"
    }
  }, [_c('router-view')], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }