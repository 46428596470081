export const isCypress = typeof Cypress !== "undefined";

/**
 * used to get user region from IP address
 * this is for display different contact details in different region
 */
export const REGION_CODE = {
  Oceania: "Oceania",
  America: "Americas",
  Europe: "Europe",
};

/**
 * Normalized country code from Google
 * The Google map API will only support these countries
 * NOTE: Adding a new country code will affect error-report.js => update accordingly
 */
export const COUNTRY_CODE = {
  Australia: "AU",
  "United Kingdom": "GB",
  "United States": "US",
  "New Zealand": "NZ",
  Germany: "DE",
};

export const PHONE_NUMBERS = {
  Oceania: "+61 (0) 3 7038 0686",
  America: "+1 (213) 347-4293",
  Europe: "+44 20 8156 2818",
};

export const COLORS = {
  RED: "red",
  WHITE: "white",
  BLUE: "blue",
  GREEN1: "green1",
  GREEN2: "green2",
};

export const INVERTERS = {
  Fronius: "Fronius",
  SolarEdge: "SolarEdge",
  SMA: "SMA",
  Other: "Other",
};

// make sure this is identical in src/modules/biz/commission/dto/CommissionDto.ts in main-server
export const CONNECTION_TYPE = {
  ONE_TRIPLE: "1x3",
  THREE_SINGLE: "3x1",
  NO_CONNECTION: "n/a",
};

export const PHASE = {
  L1: "L1",
  L2: "L2",
  L3: "L3",
};

export const SOLAR_DELIVERY_METHOD = {
  FULLY_OPTIMISED: "optimise",
  OPTIMISED_AND_EVEN: "optimise_even",
  UNEQUAL_ALLOCATION: "optimise_weight",
  NET_METERING: "net_metering",
};

export const ALGORITHM_TYPE = {
  DEMAND_BASED_DELIVERY: "DEMAND_BASED_DELIVERY",
  CUSTOM_ALLOCATION: "CUSTOM_ALLOCATION",
};

export const UNEQUAL_ALLOCATION_TYPE = {
  PERCENT: "%",
  KW: "kW",
  FT: "ft",
};

export const CT_RATING = {
  A200: 200,
  A120: 120,
  A75: 75,
};

export const GENERATION = {
  ONE: "gen1",
  TWO: "gen2",
};

export const NO_CONNECTION = "No Connection";

export const COMMISSION_STATUS = {
  SUCCESS: "pass",
  PENDING: "pending",
  FAILED: "fail",
};

export const COMMISSION_LOG_ERROR_CODE = {
  UNKNOWN: "unknown",
  SWAPPED: "swapped",
  NO_GRID: "nogrid",
  REVERSED: "reverse",
  NO_SOLAR: "nosolar",
  CONFIG_FAIL: "configfail",
  INVERTER_TRIP: "trip",
  WRONG_CT: "wrongct",
  NO_VOLTAGE: "novoltage",
  INVERTER_NOT_OUTPUTTING: "nooutput",
  PHASE_SWAPPED: "phaseswap",
  TIMEOUT: "timeout",
};

export const SEARCH_METER_CODE = {
  NOT_FOUND: 404,
  OFFLINE: 406,
  ALREADY_COMMISSIONED: 409,
  READY_TO_GO: 200,
  FORBIDDEN: 403,
  NOT_COMMISSIONED: 423,
  COMMISSIONING: 205,
  NEED_POWER_CYCLE: 206,
};

export const COMMISSION_STAGE = {
  FIRST: "config",
  SECOND: "commission",
};

export const WIFI_STATUS_INTERVAL_MS = 30 * 1000; // 30 s
export const SEARCH_METER_INTERVAL_MS = 30 * 1000; // 30 s
export const SEARCH_METER_TIMEOUT_MS = 6 * 60 * 1000; // 6 min
export const UPLOAD_CONFIG_INTERVAL_MS = isCypress ? 1000 : 10 * 1000; // 10 s
export const UPLOAD_CONFIG_TIMEOUT_MS = 10 * 60 * 1000; // 10 min
export const COMMISSION_LOG_INTERVAL_MS = isCypress ? 1000 : 10 * 1000; // 10s
export const COMMISSION_LOG_TIMEOUT_MS = 10 * 60 * 1000; // 10 min
export const UPLOAD_CONNECTION_DEBOUNCE_MS = 5000; // 5s
export const COMMISSIONING_PROCESS_TIMEOUT = 20 * 60 * 1000; // 20 mins

export const USER_ROLE = {
  COMMISSIONER: "Commissioner",
  PROVIDER: "Provider",
};

export const JOB_NAME = {
  software: "ota_solshare_application", // < SW_PRE_V1_VER -> SW_PRE_V1_VER
  firmware: "ota_solshare_firmware", // < FW_PRE_V1_VER -> FW_PRE_V1_VER
  monitor: "ota_solshare_monitor-patch",
  solshare_v1: "ota_solshare_v1-single-use", // SW_PRE_V1_VER -> SW_V1_VER, FW_PRE_V1_VER -> FW_V1_VER
  firmware_post_v1: "ota_solshare_fw_post_v1", // FW_V1_VER -> > FW_V1_VER
  software_post_v1: "ota_solshare_sw_post_v1", // SW_V1_VER -> > SW_V1_VER
};

export const FAULT_TYPE = {
  OFFLINE: "Offline",
  NO_SOLAR: "No Solar Output",
  BATTERY_TRIP: "Battery Trip",
  RELAY_SHUT: "Relay Shut",
  INVERTER_TRIP: "Inverter Trip",
  FAILED_CT: "Failed CT",
};

export const DISPLAY_FAULT = [FAULT_TYPE.OFFLINE, FAULT_TYPE.NO_SOLAR];

export const FAULT_DESCRIPTION = {
  [FAULT_TYPE.OFFLINE]: {
    name: "No network access",
    desc: "Please check the Wi-Fi network the SolShare is connected to is powered and functioning.",
  },
  [FAULT_TYPE.NO_SOLAR]: {
    name: "No solar output",
    desc: "No solar has been generated for at least 24 hours, please check your inverter status portal to see if there are any faults in the system. If you need any support please give us a call.",
  },
};
