var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('a-drawer', {
    attrs: {
      "visible": _vm.value,
      "mask": false,
      "body-style": {
        padding: 0
      },
      "closable": false,
      "width": "100vw",
      "placement": "left",
      "position": "left"
    }
  }, [_c('div', {
    staticClass: "nav__overlay"
  }, [_c('a-row', {
    staticClass: "nav__overlay__close",
    attrs: {
      "align": "top",
      "type": "flex"
    }
  }, [_c('a-icon', {
    style: {
      fontSize: '22px'
    },
    attrs: {
      "component": _vm.CloseSVG
    },
    on: {
      "click": _vm.close
    }
  })], 1), _c('a-row', {
    staticClass: "nav__overlay__item",
    attrs: {
      "align": "top",
      "type": "flex"
    }
  }, [_c('router-link', {
    staticClass: "nav__overlay__item__link",
    attrs: {
      "to": "/welcome"
    },
    nativeOn: {
      "click": function ($event) {
        return _vm.close.apply(null, arguments);
      }
    }
  }, [_vm._v(" Homepage ")])], 1), _c('a-row', {
    staticClass: "nav__overlay__item",
    attrs: {
      "align": "top",
      "type": "flex"
    }
  }, [_c('router-link', {
    staticClass: "nav__overlay__item__link",
    attrs: {
      "to": "/contact"
    },
    nativeOn: {
      "click": function ($event) {
        return _vm.close.apply(null, arguments);
      }
    }
  }, [_vm._v(" Contact us ")])], 1), _c('a-row', {
    staticClass: "nav__overlay__item",
    attrs: {
      "align": "top",
      "type": "flex"
    }
  }, [_c('router-link', {
    staticClass: "nav__overlay__item__link",
    attrs: {
      "to": "/info-centre"
    },
    nativeOn: {
      "click": function ($event) {
        return _vm.close.apply(null, arguments);
      }
    }
  }, [_vm._v(" Info centre ")])], 1), _c('a-row', {
    staticClass: "nav__overlay__item",
    attrs: {
      "align": "top",
      "type": "flex"
    }
  }, [_c('a', {
    staticClass: "nav__overlay__item__link",
    attrs: {
      "href": "https://allumeenergy.com.au/about"
    },
    on: {
      "click": _vm.close
    }
  }, [_vm._v(" About Allume ")])]), _vm.$store.getters.isLoggedIn ? _c('a-row', {
    staticClass: "nav__overlay__item",
    attrs: {
      "align": "top",
      "type": "flex"
    }
  }, [_c('a', {
    staticClass: "nav__overlay__item__link",
    attrs: {
      "data-tn": "nav-bar-overlay-logout"
    },
    on: {
      "click": _vm.logout
    }
  }, [_vm._v(" Logout ")])]) : _vm._e()], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }