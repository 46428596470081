<template>
  <div class="content">
    <progress-bar :percent="50" />

    <div class="info-text">Please review connections:</div>

    <div v-if="$store.getters.is2P">
      <UnitInputGroup2P
        v-for="index of [0, 1, 2, 3, 4]"
        :key="index"
        v-model="$store.state.project.connections[index]"
        :connection="index * 2 + 1"
        class="phase-group"
        hint
        disabled
      />
    </div>

    <div v-else>
      <UnitInputGroup3P
        v-for="index of [0, 1, 2, 3, 4]"
        :key="index"
        v-model="$store.state.project.connections[index]"
        :connection="index + 1"
        class="phase-group"
        :hint="index === 0"
        disabled
      />
    </div>

    <div class="small-text">Please state if all of this data is correct:</div>
    <a-row type="flex" :gutter="10">
      <a-col :span="12">
        <white-button bordered to="/commission/16"> NO </white-button>
      </a-col>
      <a-col :span="12">
        <yellow-button data-tn="phase-type-review-yes" @click="navigate">
          YES
        </yellow-button>
      </a-col>
    </a-row>
    <back-button />
  </div>
</template>

<script>
export default {
  methods: {
    navigate() {
      this.$router.push(
        this.$store.getters.is2P
          ? "/commission/unequal-allocation-review"
          : "/commission/17"
      );
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  .info-text {
    margin-top: 29px;
    margin-bottom: 43px;
  }

  .phase-group {
    margin-bottom: 35px;
  }

  .small-text {
    margin-bottom: 11px;
  }
}
</style>
