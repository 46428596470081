<template>
  <div class="content">
    <progress-bar :percent="50" />

    <div class="info-text">Edit connections:</div>

    <green-info-box v-if="isRecommissioning" class="re-commission-note">
      Please note that edit connections in this page will trigger a
      re-commission for the Solshare {{ $store.state.project.serialNumber }}.
    </green-info-box>

    <div v-if="$store.getters.is2P">
      <unit-input-group-2P
        ref="group1"
        v-model="connection0"
        :connection="1"
        class="phase-group"
        hint
      />
      <unit-input-group-2P
        ref="group2"
        v-model="connection1"
        :connection="3"
        class="phase-group"
        hint
      />
      <unit-input-group-2P
        ref="group3"
        v-model="connection2"
        :connection="5"
        class="phase-group"
        hint
      />
      <unit-input-group-2P
        ref="group4"
        v-model="connection3"
        :connection="7"
        class="phase-group"
        hint
      />
      <unit-input-group-2P
        ref="group5"
        v-model="connection4"
        :connection="9"
        class="phase-group"
        hint
      />
    </div>

    <div v-else>
      <unit-input-group-3P
        ref="group1"
        v-model="connection0"
        :connection="1"
        class="phase-group"
        hint
      />
      <unit-input-group-3P
        ref="group2"
        v-model="connection1"
        :connection="2"
        class="phase-group"
        hint
      />
      <unit-input-group-3P
        ref="group3"
        v-model="connection2"
        :connection="3"
        class="phase-group"
        hint
      />
      <unit-input-group-3P
        ref="group4"
        v-model="connection3"
        :connection="4"
        class="phase-group"
        hint
      />
      <unit-input-group-3P
        ref="group5"
        v-model="connection4"
        :connection="5"
        class="phase-group"
        hint
      />
    </div>

    <div>
      <!--   this form is only for validation purpose   -->
      <a-form-model ref="form" :model="{}" :rules="rules">
        <a-form-model-item prop="connections">
          <a-input v-if="false" />
        </a-form-model-item>
      </a-form-model>

      <div class="small-text">Please confirm once connections are correct:</div>

      <a-row type="flex" :gutter="10">
        <a-col :span="12">
          <yellow-button
            :loading="loading"
            data-tn="confirm"
            @click="confirmClicked"
          >
            CONFIRM
          </yellow-button>
        </a-col>
        <a-col :span="12" />
      </a-row>
      <back-button :click-handler="backClicked" />
    </div>
  </div>
</template>

<script>
import { NO_CONNECTION } from "../util/constants";
import clone from "lodash.clonedeep";

export default {
  data() {
    return {
      loading: false,
      valid: true,
      rules: {
        connections: [{ validator: this.validateConnection }],
      },
      connectionBackup: [],
    };
  },
  computed: {
    connection0: {
      get() {
        return this.$store.state.project.connections[0];
      },
      set(val) {
        this.$store.commit("setConnection", { index: 0, connection: val });
      },
    },
    connection1: {
      get() {
        return this.$store.state.project.connections[1];
      },
      set(val) {
        this.$store.commit("setConnection", { index: 1, connection: val });
      },
    },
    connection2: {
      get() {
        return this.$store.state.project.connections[2];
      },
      set(val) {
        this.$store.commit("setConnection", { index: 2, connection: val });
      },
    },
    connection3: {
      get() {
        return this.$store.state.project.connections[3];
      },
      set(val) {
        this.$store.commit("setConnection", { index: 3, connection: val });
      },
    },
    connection4: {
      get() {
        return this.$store.state.project.connections[4];
      },
      set(val) {
        this.$store.commit("setConnection", { index: 4, connection: val });
      },
    },
    isRecommissioning() {
      return this.$route.path === "/reset-connections";
    },
  },
  mounted() {
    this.connectionBackup = clone(this.$store.state.project.connections);
  },
  methods: {
    backClicked() {
      this.connectionBackup.forEach((connection, index) => {
        this.$store.commit("setConnection", { index, connection });
      });
    },
    validate(valid) {
      if (!valid) {
        this.valid = false;
        return valid;
      }
    },
    validateConnection(rule, value, callback) {
      const map = { L1: NO_CONNECTION, L2: NO_CONNECTION, L3: NO_CONNECTION };

      for (const connection of this.$store.state.project.connections) {
        for (const phase of ["L1", "L2", "L3"]) {
          if (connection[phase] !== NO_CONNECTION) {
            map[phase] = connection[phase];
          }
        }
      }

      const invalid2P =
        this.$store.getters.is2P &&
        (map.L1 === NO_CONNECTION || map.L2 === NO_CONNECTION);
      const invalid3P =
        !this.$store.getters.is2P &&
        (map.L1 === NO_CONNECTION ||
          map.L2 === NO_CONNECTION ||
          map.L3 === NO_CONNECTION);

      if (invalid2P || invalid3P) {
        this.valid = false;
        callback(
          new Error("There must be at least one connected unit on each phase")
        );
      } else {
        callback();
      }
    },
    async confirmClicked() {
      this.$refs.group1.validate(this.validate);
      this.$refs.group2.validate(this.validate);
      this.$refs.group3.validate(this.validate);
      this.$refs.group4.validate(this.validate);
      this.$refs.group5.validate(this.validate);
      this.$refs.form.validate(this.validate);

      if (this.valid) {
        this.loading = true;

        await this.$http.post(
          this.$api.uploadConnection(this.$store.state.project.serialNumber),
          {
            serialNumber: this.$store.state.project.serialNumber,
            connections: this.$store.state.project.connections,
          }
        );

        this.loading = false;
        await this.navigate();
      } else {
        this.valid = true;
      }
    },
    async navigate() {
      if (this.isRecommissioning) {
        await this.$store.dispatch("commission", { uploadConfig: true });
      } else {
        if (this.$store.getters.is2P) {
          await this.$router.push("/commission/unequal-allocation-review");
        } else {
          await this.$router.push("/commission/17");
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  .info-text {
    margin-top: 29px;
    margin-bottom: 43px;
  }

  .re-commission-note {
    margin-bottom: 20px;
  }

  .phase-group {
    margin-bottom: 35px;
  }

  .small-text {
    margin-top: 20px;
    margin-bottom: 11px;
    width: 195px;
  }

  .back-button {
    font-size: 15px;
    cursor: pointer;

    span {
      letter-spacing: 2px;
    }
  }
}
</style>
