var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "content"
  }, [_c('progress-bar', {
    attrs: {
      "percent": 50
    }
  }), _vm.$store.getters.is2P ? _c('div', [_c('div', {
    staticClass: "info-text"
  }, [_vm._v(" Are outputs " + _vm._s(Number(_vm.$route.params.index) * 2 + 1) + "(L1) & " + _vm._s(Number(_vm.$route.params.index) * 2 + 2) + "(L2) connected as: ")]), _c('BlackISVG', {
    staticClass: "info-icon",
    on: {
      "click": function ($event) {
        _vm.noteVisible = true;
      }
    }
  }), _c('white-button', {
    staticClass: "white-button",
    attrs: {
      "bordered": "",
      "selected": _vm.previouslySelectedType === _vm.CONNECTION_TYPE.THREE_SINGLE,
      "data-tn": "connection-three-single"
    },
    on: {
      "click": _vm.threeSingleClicked
    }
  }, [_vm._v(" 2 SEPARATE UNITS ")]), _c('div', {
    staticClass: "small-text"
  }), _c('white-button', {
    staticClass: "white-button",
    attrs: {
      "bordered": "",
      "selected": _vm.previouslySelectedType === _vm.CONNECTION_TYPE.ONE_TRIPLE,
      "data-tn": "connection-one-triple"
    },
    on: {
      "click": _vm.oneThreeClicked
    }
  }, [_vm._v(" 1 UNIT ")]), _c('div', {
    staticClass: "small-text"
  }), Number(_vm.$route.params.index) !== 0 ? _c('white-button', {
    staticClass: "white-button",
    attrs: {
      "bordered": "",
      "selected": _vm.previouslySelectedType === _vm.CONNECTION_TYPE.NO_CONNECTION,
      "data-tn": "no-connection"
    },
    on: {
      "click": _vm.noConnectionClicked
    }
  }, [_vm._v(" NO CONNECTION ")]) : _vm._e(), Number(_vm.$route.params.index) !== 0 ? _c('div', {
    staticClass: "small-text"
  }, [_vm._v(" No line connected to solar ")]) : _vm._e()], 1) : _c('div', [_c('div', {
    staticClass: "info-text"
  }, [_vm._v(" Is " + _vm._s(_vm.labelPrefix(0, _vm.$store.getters.isRWB)) + _vm._s(Number(_vm.$route.params.index) + 1) + ", " + _vm._s(_vm.labelPrefix(1, _vm.$store.getters.isRWB)) + _vm._s(Number(_vm.$route.params.index) + 1) + ", " + _vm._s(_vm.labelPrefix(2, _vm.$store.getters.isRWB)) + _vm._s(Number(_vm.$route.params.index) + 1) + " output connected as: ")]), _c('BlackISVG', {
    staticClass: "info-icon",
    on: {
      "click": function ($event) {
        _vm.noteVisible = true;
      }
    }
  }), _c('white-button', {
    staticClass: "white-button",
    attrs: {
      "bordered": "",
      "data-tn": `phase-type-three-single-button-${_vm.index}`,
      "selected": _vm.previouslySelectedType === _vm.CONNECTION_TYPE.THREE_SINGLE
    },
    on: {
      "click": _vm.threeSingleClicked
    }
  }, [_vm._v(" 3 x SINGLE PHASE ")]), _c('div', {
    staticClass: "small-text"
  }), _c('white-button', {
    staticClass: "white-button",
    attrs: {
      "bordered": "",
      "data-tn": `phase-type-one-three-button-${_vm.index}`,
      "selected": _vm.previouslySelectedType === _vm.CONNECTION_TYPE.ONE_TRIPLE
    },
    on: {
      "click": _vm.oneThreeClicked
    }
  }, [_vm._v(" 1 x THREE PHASE ")]), _c('div', {
    staticClass: "small-text"
  }), Number(_vm.$route.params.index) !== 0 ? _c('white-button', {
    staticClass: "white-button",
    attrs: {
      "bordered": "",
      "data-tn": `no-connection`,
      "selected": _vm.previouslySelectedType === _vm.CONNECTION_TYPE.NO_CONNECTION
    },
    on: {
      "click": _vm.noConnectionClicked
    }
  }, [_vm._v(" NO CONNECTION ")]) : _vm._e()], 1), _vm.previouslySelectedType === '' ? _c('back-button', {
    staticClass: "back"
  }) : _c('back-and-next', {
    staticClass: "back",
    attrs: {
      "next-on-click": _vm.navigate,
      "data-tn": "phase-type-choose-next"
    }
  }), _c('yellow-modal', {
    attrs: {
      "icon": "",
      "padding": "62px 26px 21px 26px"
    },
    model: {
      value: _vm.noteVisible,
      callback: function ($$v) {
        _vm.noteVisible = $$v;
      },
      expression: "noteVisible"
    }
  }, [_c('div', {
    style: {
      fontSize: '21px',
      marginBottom: '1em'
    }
  }, [_vm._v(" If the R1, W1, B1 output from the SolShare is connected to individual units, select "), _c('span', {
    staticClass: "bold"
  }, [_vm._v("3 x single-phase")]), _vm._v(". "), _c('br'), _vm._v(" If they're connected to a single, three-phase unit, select "), _c('span', {
    staticClass: "bold"
  }, [_vm._v(" 1 x three-phase ")]), _vm._v(". ")])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }