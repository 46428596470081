export function isNumber(rule, value, callback) {
  if (isNaN(Number(value))) {
    callback(new Error("please input a number"));
  } else {
    callback();
  }
}

export function isPositiveNumber(rule, value, callback) {
  if (isNaN(Number(value))) {
    callback(new Error("please input a number"));
  } else if (Number(value) <= 0) {
    callback(new Error("please input a positive number"));
  } else {
    callback();
  }
}
