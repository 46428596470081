var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "content"
  }, [_c('progress-bar', {
    attrs: {
      "percent": 65
    }
  }), _c('div', {
    staticClass: "info-text"
  }, [_vm._v(" Assign " + _vm._s(_vm.labelPrefix(_vm.index - 1, _vm.$store.getters.isRWB).replace("-", "")) + " allocations ")]), _c('a-form-model', {
    ref: "form",
    attrs: {
      "colon": false,
      "model": _vm.form
    }
  }, [_c('a-form-model-item', {
    attrs: {
      "label": "Input Unit"
    }
  }, [_c('a-radio-group', {
    staticClass: "radio-group",
    attrs: {
      "size": "large"
    },
    model: {
      value: _vm.form.suffix,
      callback: function ($$v) {
        _vm.$set(_vm.form, "suffix", $$v);
      },
      expression: "form.suffix"
    }
  }, [_c('a-radio-button', {
    staticClass: "radio-button",
    attrs: {
      "value": _vm.UNEQUAL_ALLOCATION_TYPE.PERCENT
    }
  }, [_vm._v(" % ")]), _c('a-radio-button', {
    staticClass: "radio-button",
    attrs: {
      "value": _vm.UNEQUAL_ALLOCATION_TYPE.KW
    }
  }, [_vm._v(" kW DC ")])], 1)], 1), _c('a-form-model-item', [_c('div', {
    staticClass: "form-label"
  }, [_c('span', [_vm._v("Phase " + _vm._s(_vm.index))]), _c('span', {
    staticClass: "total-kw"
  }, [_vm._v(" " + _vm._s(_vm.roundToDown(_vm.$store.state.project.spec.dcSize / 3, 2)) + "kW DC available ")])]), _c('UnitInputGroupUnequalAllocation', {
    ref: "group",
    attrs: {
      "phase-idx": _vm.index,
      "suffix": _vm.form.suffix,
      "available": _vm.$store.state.project.spec.dcSize / 3,
      "default-even": ""
    },
    model: {
      value: _vm.form.weight,
      callback: function ($$v) {
        _vm.$set(_vm.form, "weight", $$v);
      },
      expression: "form.weight"
    }
  })], 1)], 1), _c('a-row', {
    attrs: {
      "type": "flex",
      "align": "middle"
    }
  }, [_c('a-col', {
    attrs: {
      "span": 12
    }
  }, [_c('back-button', {
    style: {
      marginTop: 0
    }
  })], 1), _c('a-col', {
    attrs: {
      "span": 12
    }
  }, [_c('yellow-button', {
    attrs: {
      "data-tn": "unequal-allocation-next"
    },
    on: {
      "click": _vm.nextClicked
    }
  }, [_vm._v(" NEXT ")])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }