<template>
  <div class="content">
    <div class="title">We're here to help.</div>

    <p class="text">
      If you have any doubts during the commissioning process, please do not
      hesitate to get in touch.
    </p>

    <grey-contact-box type="phone" :region="region" />
    <grey-contact-box type="email" :region="region" />
    <back-button />
  </div>
</template>

<script>
export default {
  computed: {
    region() {
      return this.$store.state.region;
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  .title {
    margin-bottom: 26px;
  }
  .text {
    margin-bottom: 26px;
  }
}
</style>
