var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "content"
  }, [_c('progress-bar', {
    attrs: {
      "percent": 70
    }
  }), _vm._m(0), _vm.logs.length > 0 ? _c('div', {
    staticClass: "logs",
    attrs: {
      "data-tn": "commission-failed-log"
    }
  }, _vm._l(_vm.logs, function (log, index) {
    return _c('div', {
      key: index,
      staticClass: "log",
      attrs: {
        "data-tn": `commission-failed-detail-${index}`
      }
    }, [_vm._v(" " + _vm._s(log) + " ")]);
  }), 0) : _vm._e(), _c('white-button', {
    attrs: {
      "bordered": "",
      "loading": _vm.loading,
      "data-tn": "commission-failed-try-again"
    },
    on: {
      "click": _vm.tryAgainClicked
    }
  }, [_vm._v(" OK, TRY AGAIN ")]), _c('yellow-button', {
    staticClass: "yellow-button",
    attrs: {
      "to": "/commission/11",
      "data-tn": "commission-failed-reconfigure"
    }
  }, [_vm._v(" RECONFIGURE ")])], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "info-text",
    attrs: {
      "data-tn": "commission-failed-info"
    }
  }, [_vm._v(" Oh no! We’ve identified an issue with your installation."), _c('br'), _vm._v(" Please take the required actions and reconfigure: ")]);

}]

export { render, staticRenderFns }