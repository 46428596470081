<template>
  <div class="content">
    <div class="title">Info Centre</div>

    <a-row :gutter="[11, 5]">
      <a-col>
        <a
          class="green-box"
          :href="getLinks().dataSheet"
          target="_blank"
          data-tn="infocentre-datasheet"
        >
          <div class="icon">
            <DataSheetsSVG />
          </div>
          <div>DATA SHEETS</div>
        </a>
      </a-col>
    </a-row>

    <a-row :gutter="[11, 5]">
      <a-col>
        <a
          class="green-box"
          :href="getLinks().installManual"
          target="_blank"
          data-tn="infocentre-install-manual"
        >
          <div class="icon">
            <InstallationManualsSVG />
          </div>
          <div>INSTALLATION MANUALS</div>
        </a>
      </a-col>
    </a-row>

    <a-row :gutter="[11, 5]">
      <a-col>
        <a
          class="green-box"
          :href="getLinks().wifiCreds"
          target="_blank"
          data-tn="infocentre-wifi-creds"
        >
          <div class="icon">
            <WifiSVG width="86" height="56" view-box="3 0 18 30"></WifiSVG>
          </div>
          <div>CHANGING WIFI CREDENTIALS</div>
        </a>
      </a-col>
    </a-row>

    <a-row :gutter="[11, 5]">
      <a-col>
        <a class="green-box" @click="$router.push('/commission/1?info=true')">
          <div class="icon">
            <StartShutProcedureSVG />
          </div>
          <div>START UP / SHUTDOWN PROCEDURE</div>
        </a>
      </a-col>
    </a-row>

    <a-row :gutter="[11, 5]">
      <a-col>
        <a class="green-box" @click="$router.push('/safety-label?info=true')">
          <div class="icon">
            <MSBLabelSVG />
          </div>
          <div>WHERE TO PLACE THE MSB LABELS</div>
        </a>
      </a-col>
    </a-row>

    <back-button />
  </div>
</template>

<script>
import DataSheetsSVG from "../assets/svg/data-sheets.svg";
import InstallationManualsSVG from "../assets/svg/installation-manuals.svg";
import StartShutProcedureSVG from "../assets/svg/start-shut-procedure.svg";
import MSBLabelSVG from "../assets/svg/msb-labels.svg";
import WifiSVG from "../assets/svg/network-wireless-signal.svg";
import { REGION_CODE } from "../util/constants";
export default {
  components: {
    DataSheetsSVG,
    InstallationManualsSVG,
    StartShutProcedureSVG,
    MSBLabelSVG,
    WifiSVG,
  },
  computed: {
    region() {
      return this.$store.state.region;
    },
  },
  methods: {
    commissionClicked() {
      this.$router.push("/commission/1");
    },
    getLinks() {
      switch (this.region) {
        case REGION_CODE.Europe:
          return {
            dataSheet: "https://allumeenergy.com/uk/2/solshare-datasheet",
            installManual:
              "https://allumeenergy.com/uk/2/solshare-install-manual",
            wifiCreds: "https://allumeenergy.com/uk/2/solshare-wifi-creds",
          };
        case REGION_CODE.America:
          return {
            dataSheet: "https://allumeenergy.com/us/1/solshare-datasheet",
            installManual: "", // Intentionally left blank as this is not finalised yet
            wifiCreds: "https://allumeenergy.com/us/1/solshare-wifi-creds",
          };
        case REGION_CODE.Oceania:
        default:
          // TODO: Be able to find out the serial number from the user to get the generation
          // but default to a particular version if they don't know the serial.
          return {
            dataSheet: "https://allumeenergy.com/au/1/solshare-datasheet",
            installManual:
              "https://allumeenergy.com/au/1/solshare-install-manual",
            wifiCreds: "https://allumeenergy.com/au/1/solshare-wifi-creds",
          };
      }
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  .title {
    font-size: 25px;
    margin-bottom: 29px;
  }
  .green-box {
    display: block;
    background: #f3f4f4;
    height: 175px;
    box-shadow: rgba(162, 162, 162, 0.52) 0.5px 2.3px 4.4px;
    color: #005149;
    font-size: 15px;
    text-align: center;
    letter-spacing: 1px;
    margin-bottom: 10px;
    padding-top: 25px;
    line-height: 19px;

    .icon {
      margin-bottom: 15px;
    }
  }
}
</style>
