var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "content"
  }, [_c('progress-bar', {
    attrs: {
      "percent": 20
    }
  }), _c('OKSVG', {
    staticClass: "ok-icon"
  }), _c('div', {
    staticClass: "info-text"
  }, [_vm._v("SolShare detected")]), _c('yellow-button', {
    attrs: {
      "data-tn": "start-configuration-button"
    },
    on: {
      "click": _vm.startConfigurationClicked
    }
  }, [_vm._v(" START CONFIGURATION ")]), _c('back-button', {
    style: {
      textAlign: 'left'
    },
    attrs: {
      "step": -2
    }
  }), _c('yellow-modal', {
    attrs: {
      "target": _vm.WifiHighlightedSVG,
      "right-arrow": "nav-wifi",
      "closable": false,
      "top": 150
    },
    model: {
      value: _vm.noteVisible,
      callback: function ($$v) {
        _vm.noteVisible = $$v;
      },
      expression: "noteVisible"
    }
  }, [_c('div', {
    style: {
      fontFamily: 'Helvetica Medium',
      fontSize: '25px',
      textAlign: 'center'
    }
  }, [_vm._v(" Lastly, ")]), _c('div', {
    style: {
      textAlign: 'center',
      marginTop: '15px',
      marginBottom: '40px'
    }
  }, [_vm._v(" This icon will indicate the SolShare’s internet connection. Tap the icon to be taken to the Wi-Fi information page ")]), _c('white-button', {
    style: {
      margin: '0 auto'
    },
    attrs: {
      "data-tn": "wifi-icon-modal-got-it",
      "block": false
    },
    on: {
      "click": _vm.noteClicked
    }
  }, [_vm._v(" OK, GOT IT ")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }