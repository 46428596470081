import Vue from "vue";
import lookup from "country-code-lookup";
import {
  COMMISSION_STATUS,
  CONNECTION_TYPE,
  INVERTERS,
  NO_CONNECTION,
  REGION_CODE,
} from "../util/constants";
import { normalizeSerial } from "../util/normalize-serial";
import { project } from "./constant";
import { removeNullishProperties } from "../util/object";

export const mutations = {
  setUser(state, { token, exp, email, role }) {
    state.user.token = token;
    state.user.email = email;
    state.user.exp = exp;
    state.user.role = role;
  },
  setAddress(state, address) {
    state.project.address = {
      ...state.project.address,
      ...removeNullishProperties(address),
    };
  },
  setWifiIcon(state, showIcon) {
    state.showWifiIcon = showIcon;
  },
  setWifiStatus(state, { ssid, connected }) {
    state.wifiStatus.ssid = ssid;
    state.wifiStatus.connected = connected;
  },
  setWifiIntervalID(state, id) {
    state.wifiIntervalID = id;
  },
  setSwVersion(state, version) {
    state.systemInfo.meter.softwareVersion = version;
  },
  setSpec(state, spec) {
    state.project.spec.dcSize = spec.dcSize;
    state.project.spec.acSize = spec.acSize;
    if (spec.inverter) {
      state.project.spec.inverter =
        spec.inverter === INVERTERS.Other ? spec.inputInverter : spec.inverter;
    }
  },
  setConnectionType(state, { index, type }) {
    if (state.project.connections[index].type !== type) {
      Vue.set(state.project.connections, index, {
        type,
        L1: "",
        L2: "",
        L3: "",
      });
    }
  },
  setConnection(state, { index, connection }) {
    const newConn = { ...state.project.connections[index], ...connection };
    if (newConn.L1 === newConn.L2 && newConn.L2 === newConn.L3) {
      if (newConn.L1 === NO_CONNECTION) {
        newConn.type = CONNECTION_TYPE.NO_CONNECTION;
      } else {
        newConn.type = CONNECTION_TYPE.ONE_TRIPLE;
      }
    } else {
      newConn.type = CONNECTION_TYPE.THREE_SINGLE;
    }
    Vue.set(state.project.connections, index, newConn);
  },
  setAlgorithmWeight(state, { index, weight }) {
    Vue.set(state.project.algorithmWeight, index, weight);
  },
  setAllAlgorithmWeight(state, weight) {
    state.project.algorithmWeight = weight;
  },
  enableUnequalAlgorithm(state) {
    state.project.algorithmWeight = state.project.serialNumber.startsWith("2P")
      ? project.algorithmWeight.slice(0, 2)
      : project.algorithmWeight.slice();
  },
  disableUnequalAlgorithm(state) {
    state.project.algorithmWeight = [];
  },
  setDeliveryMethod(state, method) {
    state.project.solarDeliveryMethod = method;
  },
  setCTRating(state, CTRating) {
    state.project.CTRating = CTRating;
  },
  setStep(state, path) {
    state.step = path;
  },
  setSerialNumber(state, serial) {
    state.project.serialNumber = normalizeSerial(serial);
  },
  setException(state, { exception, tryAgain, rawHTML }) {
    state.exception = exception;
    state.exceptionTryAgain = tryAgain;
    state.exceptionHTML = rawHTML;
  },
  setConfigStatus(state, configStatus) {
    state.configStatus = configStatus;
  },
  resetConfigStatus(state) {
    state.configStatus = COMMISSION_STATUS.PENDING;
  },
  setIsPollingConfigStatus(state, isPollingConfigStatus) {
    state.isPollingConfigStatus = isPollingConfigStatus;
  },
  setIsPollingCommissionLog(state, isPollingCommissionLog) {
    state.isPollingCommissionLog = isPollingCommissionLog;
  },
  setCommissionLog(state, log) {
    state.commissionLog = log;
  },
  resetCommissionLog(state) {
    state.commissionLog = {
      status: COMMISSION_STATUS.PENDING,
      startPercent: null,
      endPercent: null,
      duration: null,
      logs: [],
    };
  },
  setSystemInfo(state, info) {
    state.systemInfo = info;
  },
  setSolsharesAtSite(state, data) {
    state.systemInfo.solsharesAtSite = data;
  },
  setFleet(state, data) {
    state.fleet = data;
  },
  resetConnectionFromSystemInfo(state) {
    if (Array.isArray(state.systemInfo.unit)) {
      state.systemInfo.unit.forEach((connection, index) => {
        this.commit("setConnection", { index, connection });
      });
    }
  },
  resetProject(state, p = project) {
    state.project = { ...p };
  },
  setSoftwareUpdate(state, update) {
    state.softwareUpdate = update;
  },
  setUserRegion(state, countryCode) {
    const country = lookup.byIso(countryCode);
    state.region = country?.continent ?? REGION_CODE.Oceania;
  },
};
