var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "content"
  }, [_c('back-button'), _c('div', {
    staticClass: "title"
  }, [_vm._v(" Edit Building Address "), _c('BlackISVG', {
    on: {
      "click": function ($event) {
        _vm.note1Visible = true;
      }
    }
  })], 1), _c('a-form-model', {
    ref: "form",
    attrs: {
      "colon": false,
      "rules": _vm.rules,
      "model": _vm.form
    }
  }, [_c('a-form-model-item', {
    attrs: {
      "prop": "solshareName"
    },
    scopedSlots: _vm._u([{
      key: "label",
      fn: function () {
        return [_vm._v(" Solshare Name "), _c('BlackISVG', {
          on: {
            "click": function ($event) {
              _vm.note2Visible = true;
            }
          }
        })];
      },
      proxy: true
    }])
  }, [_c('a-input', {
    staticClass: "input-box",
    attrs: {
      "size": "large",
      "placeholder": "e.g. Solshare 1"
    },
    model: {
      value: _vm.form.solshareName,
      callback: function ($$v) {
        _vm.$set(_vm.form, "solshareName", typeof $$v === 'string' ? $$v.trim() : $$v);
      },
      expression: "form.solshareName"
    }
  })], 1), _c('a-form-model-item', {
    attrs: {
      "label": "Address",
      "prop": "address"
    }
  }, [_c('google-autocomplete', {
    attrs: {
      "id": "address-input",
      "classname": "input-box",
      "country": Object.values(_vm.COUNTRY_CODE),
      "enable-geolocation": true
    },
    on: {
      "placechanged": _vm.placeChanged,
      "blur": _vm.placeInputBlurred
    },
    model: {
      value: _vm.form.address,
      callback: function ($$v) {
        _vm.$set(_vm.form, "address", typeof $$v === 'string' ? $$v.trim() : $$v);
      },
      expression: "form.address"
    }
  })], 1), _vm.showCountryInput ? _c('a-form-model-item', {
    attrs: {
      "prop": "country",
      "label": "Country"
    }
  }, [_c('a-select', {
    staticClass: "input-box",
    attrs: {
      "size": "large",
      "placeholder": "Please input country",
      "data-tn": "system-building-address-country"
    },
    model: {
      value: _vm.form.country,
      callback: function ($$v) {
        _vm.$set(_vm.form, "country", typeof $$v === 'string' ? $$v.trim() : $$v);
      },
      expression: "form.country"
    }
  }, _vm._l(_vm.COUNTRY_CODE, function (code, country) {
    return _c('a-select-option', {
      key: code,
      attrs: {
        "value": code
      }
    }, [_vm._v(" " + _vm._s(country) + " ")]);
  }), 1)], 1) : _vm._e()], 1), _c('a-row', {
    attrs: {
      "type": "flex",
      "align": "middle"
    }
  }, [_c('a-col', {
    attrs: {
      "span": 12
    }
  }, [_c('back-button', {
    style: {
      marginTop: 0
    }
  })], 1), _c('a-col', {
    attrs: {
      "span": 12
    }
  }, [_c('yellow-button', {
    attrs: {
      "data-tn": "building-address-next",
      "loading": _vm.loading,
      "disabled": _vm.isSaveDisabled
    },
    on: {
      "click": _vm.saveClicked
    }
  }, [_vm._v(" SAVE ")])], 1)], 1), _c('yellow-modal', {
    attrs: {
      "icon": "",
      "padding": "62px 26px 21px 26px"
    },
    model: {
      value: _vm.note1Visible,
      callback: function ($$v) {
        _vm.note1Visible = $$v;
      },
      expression: "note1Visible"
    }
  }, [_c('div', {
    style: {
      fontSize: '21px',
      marginBottom: '1em'
    }
  }, [_vm._v(" Only enter the main building address details. Do not include any individual unit details here. ")])]), _c('yellow-modal', {
    attrs: {
      "icon": "",
      "padding": "62px 26px 21px 26px"
    },
    model: {
      value: _vm.note2Visible,
      callback: function ($$v) {
        _vm.note2Visible = $$v;
      },
      expression: "note2Visible"
    }
  }, [_c('div', {
    style: {
      fontSize: '21px',
      marginBottom: '1em'
    }
  }, [_vm._v(" This is a descriptive label/name for each SolShare - use this if you would like to distinguish the SolShare between others installed on site. Examples include \"SolShare 1\", \"SolShare closest to the MSB\", \"SolShare for Building A\", etc. ")])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }