<template>
  <div class="content">
    <progress-bar :percent="65" />

    <div class="info-text">
      Assign
      {{ labelPrefix(index - 1, $store.getters.isRWB).replace("-", "") }}
      allocations
    </div>

    <a-form-model ref="form" :colon="false" :model="form">
      <a-form-model-item label="Input Unit">
        <a-radio-group v-model="form.suffix" size="large" class="radio-group">
          <a-radio-button
            :value="UNEQUAL_ALLOCATION_TYPE.PERCENT"
            class="radio-button"
          >
            %
          </a-radio-button>
          <a-radio-button
            :value="UNEQUAL_ALLOCATION_TYPE.KW"
            class="radio-button"
          >
            kW DC
          </a-radio-button>
        </a-radio-group>
      </a-form-model-item>

      <a-form-model-item>
        <div class="form-label">
          <span>Phase {{ index }}</span>
          <span class="total-kw">
            {{ roundToDown($store.state.project.spec.dcSize / 3, 2) }}kW DC
            available
          </span>
        </div>
        <UnitInputGroupUnequalAllocation
          ref="group"
          v-model="form.weight"
          :phase-idx="index"
          :suffix="form.suffix"
          :available="$store.state.project.spec.dcSize / 3"
          default-even
        />
      </a-form-model-item>
    </a-form-model>

    <a-row type="flex" align="middle">
      <a-col :span="12">
        <back-button :style="{ marginTop: 0 }" />
      </a-col>
      <a-col :span="12">
        <yellow-button data-tn="unequal-allocation-next" @click="nextClicked">
          NEXT
        </yellow-button>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { UNEQUAL_ALLOCATION_TYPE } from "../util/constants";
import { roundToDown } from "../util/round";
import { labelPrefix } from "../util/unit-input-label";
export default {
  components: {},
  data() {
    return {
      labelPrefix,
      UNEQUAL_ALLOCATION_TYPE,
      form: { suffix: UNEQUAL_ALLOCATION_TYPE.PERCENT, weight: [] },
      roundToDown,
    };
  },
  computed: {
    index() {
      return Number(this.$route.params.index);
    },
  },
  methods: {
    nextClicked() {
      this.$refs.group.validate((valid) => {
        if (valid) {
          this.$store.commit("setAlgorithmWeight", {
            index: this.index - 1,
            weight: this.form.weight,
          });
          this.navigate();
        } else {
          return false;
        }
      });
    },
    navigate() {
      if (this.index === (this.$store.getters.is2P ? 2 : 3)) {
        this.$router.push(`/commission/unequal-allocation-review`);
      } else {
        this.$router.push(`/commission/unequal-allocation/${this.index + 1}`);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  .info-text {
    margin-top: 29px;
    margin-bottom: 30px;
  }

  .radio-group {
    width: 100%;
    display: flex;

    .radio-button {
      width: 50%;
      color: black;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: none;

      span {
        height: 60px;
      }
    }

    .ant-radio-button-wrapper-checked {
      background: rgb(254, 194, 23);
      border-color: black;
    }
  }

  .form-label {
    display: flex;
    justify-content: space-between;
    color: rgba(0, 0, 0, 0.85);

    .total-kw {
      color: #aaa;
      font-style: italic;
    }
  }
}
</style>
