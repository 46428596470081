var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "content"
  }, [_c('progress-bar', {
    attrs: {
      "percent": 10
    }
  }), _c('div', {
    staticClass: "info-text"
  }, [_vm._v(" How would you like to enter the unit’s serial number? ")]), _c('BlackISVG', {
    staticClass: "info-icon",
    on: {
      "click": function ($event) {
        _vm.noteVisible = true;
      }
    }
  }), _c('yellow-button', {
    staticClass: "manual-button",
    attrs: {
      "to": "/commission/3",
      "data-tn": "serial-number-manually"
    }
  }, [_vm._v(" MANUALLY ")]), _c('yellow-button', {
    attrs: {
      "to": "/commission/4"
    }
  }, [_vm._v(" SCAN THE QR CODE "), _c('QRCodeSVG', {
    staticClass: "qrcode-icon"
  })], 1), _c('back-button'), _c('yellow-modal', {
    attrs: {
      "icon": "",
      "padding": "62px 26px 21px 26px"
    },
    scopedSlots: _vm._u([{
      key: "footer",
      fn: function () {
        return [_c('img', {
          style: {
            width: '100%'
          },
          attrs: {
            "src": require("../assets/png/qrcode-location.png")
          }
        })];
      },
      proxy: true
    }]),
    model: {
      value: _vm.noteVisible,
      callback: function ($$v) {
        _vm.noteVisible = $$v;
      },
      expression: "noteVisible"
    }
  }, [_c('div', {
    style: {
      fontSize: '21px',
      marginBottom: '1em'
    }
  }, [_vm._v(" The QR Code and Serial number can be found on the bottom right-hand side of the SolShare. ")])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }