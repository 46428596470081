<template>
  <div class="content">
    <progress-bar :percent="20" class="progress-bar" />

    <div class="center">
      <TickSVG class="tick" />
      <div class="text">
        Success! You're all up to date.
        <div v-if="showUpdateTip">
          Please ensure all isolation points in maintenance isolation board are
          <b>closed</b> (connected) before proceeding.
        </div>
      </div>
    </div>

    <back-and-next to="/commission/10" :back-step="-2" data-tn="next" />
  </div>
</template>

<script>
import TickSVG from "../assets/svg/tick.svg";
export default {
  name: "SoftwareUpdateSuccess",
  components: {
    TickSVG,
  },
  computed: {
    showUpdateTip() {
      return !this.$store.getters.shouldNotShowUpdateInstructions;
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  .center {
    text-align: center;

    .tick {
      margin-top: 63px;
      margin-bottom: 21px;
    }

    .text {
      font-size: 25px;
      line-height: 29px;
      color: #005149;
      font-family: "Helvetica Light", sans-serif;
      margin-bottom: 225px;
      width: 232px;
      margin-left: auto;
      margin-right: auto;
    }
  }
}
</style>
