<template>
  <div id="app" class="screen">
    <NavBar />

    <transition name="page" mode="out-in">
      <router-view />
    </transition>
  </div>
</template>

<script>
import NavBar from "./components/NavBar";
export default {
  components: { NavBar },
};
</script>

<style lang="less">
@import "assets/style/font";
@import "assets/style/antd-overwrites";
@import "assets/style/global";

.page-enter-active,
.page-leave-active {
  transition: all 0.3s;
}
.page-enter {
  transform: translateX(100px);
  opacity: 0;
}
.page-leave-to {
  transform: translateX(-100px);
  opacity: 0;
}

#app {
  font-family: Helvetica, Arial, sans-serif;
  font-size: 17px;
  line-height: 20px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  padding-top: 70px;
}

.pac-logo:after {
  display: none !important;
}

.ant-drawer {
  z-index: 9999;
}
</style>
