<template>
  <div>
    <a-modal
      v-model="isVisible"
      width="calc(100vw - 44px)"
      :body-style="{
        fontSize: '21px',
        lineHeight: '25px',
        padding,
        background: color,
        fontFamily: 'Helvetica',
      }"
      :mask-style="{ background: 'rgba(62, 62, 62, 0.55)' }"
      :dialog-style="{ top: `${top}px` }"
      :closable="closable"
      :after-close="afterClose"
    >
      <template #closeIcon>
        <CloseSVG />
      </template>

      <BlackILineSVG v-if="icon" :style="{ marginBottom: '9px' }" />

      <slot />

      <template #footer>
        <slot name="footer">
          <div style="display: none" />
        </slot>
      </template>
    </a-modal>

    <!--  arrow   -->
    <ArrowRightSVG
      v-if="isVisible && rightArrow && dom"
      class="modal__arrow"
      :style="{
        top: arrowTop,
        left: arrowLeft,
      }"
    />

    <ArrowLeftSVG
      v-if="isVisible && leftArrow && dom"
      class="modal__arrow"
      :style="{
        top: arrowTop,
        left: arrowLeft,
      }"
    />

    <ArrowDownSVG
      v-if="isVisible && downArrow && dom"
      class="modal__arrow"
      :style="{
        top: arrowBottom,
        left: arrowLeft,
      }"
    />

    <!--  icon that arrow points to  -->
    <component
      :is="target"
      v-if="isVisible && target && dom"
      class="modal__target-icon"
      :style="{
        top: domTop,
        left: domLeft,
      }"
    />
  </div>
</template>

<script>
import CloseSVG from "../assets/svg/close.svg";
import BlackILineSVG from "../assets/svg/black-i-line.svg";
import ArrowRightSVG from "../assets/svg/arrow-right.svg";
import ArrowLeftSVG from "../assets/svg/arrow-left.svg";
import ArrowDownSVG from "../assets/svg/arrow-down.svg";
export default {
  name: "YellowModal",
  components: {
    CloseSVG,
    BlackILineSVG,
    ArrowRightSVG,
    ArrowLeftSVG,
    ArrowDownSVG,
  },
  props: {
    value: { type: Boolean, default: false },
    color: { type: String, default: "#FFE86C" },
    padding: { type: String, default: "69px 28px" },
    icon: { type: Boolean, default: false },
    leftArrow: { type: String, default: null },
    rightArrow: { type: String, default: null },
    downArrow: { type: String, default: null },
    target: { type: Object, default: null },
    top: { type: Number, default: 140 },
    closable: { type: Boolean, default: true },
    afterClose: { type: Function, default: null },
  },
  data() {
    return {
      dom: null,
      domLeft: 0,
      domTop: 0,
      domRight: 0,
      domHeight: 0,
    };
  },
  computed: {
    isVisible: {
      get() {
        return this.value;
      },
      set() {
        this.$emit("input", false);
      },
    },
    arrowTop() {
      if (this.dom) {
        return this.domTop + this.domHeight / 2 + "px";
      }
      return 0;
    },
    arrowBottom() {
      if (this.dom) {
        // 147 is height of the arrow svg
        return this.domTop - 147 + "px";
      }
      return 0;
    },
    arrowLeft() {
      if (this.dom && this.rightArrow) {
        // 118 is width of the arrow svg
        return this.domLeft - 118 + "px";
      }
      if (this.dom && this.leftArrow) {
        return this.domRight + "px";
      }
      if (this.dom && this.downArrow) {
        return this.domLeft + "px";
      }

      return 0;
    },
  },
  watch: {
    isVisible() {
      this.domLeft = this.dom?.getBoundingClientRect?.()?.left ?? 0;
      this.domTop = this.dom?.getBoundingClientRect?.()?.top ?? 0;
      this.domRight = this.dom?.getBoundingClientRect?.()?.right ?? 0;
      this.domHeight = this.dom?.getBoundingClientRect?.()?.height ?? 0;
    },
  },
  mounted() {
    this.dom = document.getElementById(
      this.leftArrow || this.rightArrow || this.downArrow
    );
  },
};
</script>

<style lang="less" scoped>
.modal__arrow {
  position: fixed;
  z-index: 99999;
}

.modal__target-icon {
  position: fixed;
  z-index: 100000;
}
</style>
