<template>
  <div class="content">
    <div class="title">Create account</div>

    <a-form-model ref="form" :model="form" layout="vertical" :rules="rules">
      <a-form-model-item label="Your name" prop="name">
        <a-input v-model.trim="form.name" size="large" class="input-box" />
      </a-form-model-item>

      <a-form-model-item label="Company name" prop="company">
        <a-input v-model.trim="form.company" size="large" class="input-box" />
      </a-form-model-item>

      <a-form-model-item prop="licenseNumber">
        <template #label>
          Company Electrical License number
          <a-icon
            :component="BlackISVG"
            :style="{ fontSize: '25px' }"
            @click="electricalNumberVisible = true"
          />
        </template>
        <a-input
          v-model.trim="form.licenseNumber"
          size="large"
          class="input-box"
        />
      </a-form-model-item>

      <a-form-model-item prop="trainingCert">
        <template #label> Allume Training Cert number </template>
        <a-input
          v-model.trim="form.trainingCert"
          size="large"
          class="input-box"
        />
      </a-form-model-item>

      <a-form-model-item label="Email address" prop="email">
        <a-input v-model.trim="form.email" size="large" class="input-box" />
      </a-form-model-item>

      <a-form-model-item label="Password" prop="password">
        <a-input-password
          v-model="form.password"
          size="large"
          class="input-box"
        />
      </a-form-model-item>

      <a-form-model-item label="Re-enter password" prop="passwordConfirm">
        <a-input-password
          v-model="form.passwordConfirm"
          size="large"
          class="input-box"
        />
      </a-form-model-item>
    </a-form-model>

    <yellow-button class="submit" :loading="loading" @click="signUpClicked">
      SIGN UP
    </yellow-button>

    <back-button />

    <yellow-modal v-model="electricalNumberVisible" :icon="true">
      <div>Please provide your company’s electrical licence number.</div>
    </yellow-modal>
  </div>
</template>

<script>
import BlackISVG from "../assets/svg/black-i-filled.svg";
export default {
  data() {
    return {
      BlackISVG,
      electricalNumberVisible: false,
      loading: false,
      form: {
        name: "",
        password: "",
        passwordConfirm: "",
        company: "",
        licenseNumber: "",
        trainingCert: "",
        email: "",
      },
      rules: {
        name: [
          {
            required: true,
            message: "Please input your name",
            trigger: "blur",
          },
        ],
        company: [
          {
            required: true,
            message: "Please input your company",
            trigger: "blur",
          },
        ],
        password: [
          { required: true, message: "Please input password", trigger: "blur" },
        ],
        email: [
          {
            type: "email",
            required: true,
            message: "Please input a valid email address",
            trigger: "blur",
          },
        ],
        licenseNumber: [
          {
            required: true,
            message: "Please input license number",
            trigger: "blur",
          },
        ],
        trainingCert: [
          {
            required: true,
            message: "Please input training cert number",
            trigger: "blur",
          },
          {
            validator: (rule, value, callback) => {
              if (!/([0-9a-fA-F]{4}-){3}([0-9a-fA-F]{4})/.test(value)) {
                callback(new Error("invalid training cert number"));
              } else {
                callback();
              }
            },
          },
        ],
        passwordConfirm: [
          {
            required: true,
            message: "Please input password again",
            trigger: "blur",
          },
          {
            validator: (rule, value, callback) => {
              if (value !== this.form.password) {
                callback(new Error("Two inputs don't match!"));
              } else {
                callback();
              }
            },
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    signUpClicked() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          this.loading = true;
          await this.$http.post(this.$api.register, this.form);
          this.$message.success(
            "please click the verification link in your email box"
          );
          await this.$router.push("/login");
          this.loading = false;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  .title {
    margin-bottom: 45px;
  }

  /deep/ .ant-form-item {
    margin-bottom: 21px;
  }

  .submit {
    margin-top: 7px;
  }
}
</style>
