/**
 * @author Wentong Liu
 */
"use strict";

import axios from "./axios";

export default {
  get(url) {
    return axios.get(url);
  },

  post(url, data) {
    return axios.post(url, data);
  },

  put(url, data) {
    return axios.put(url, data);
  },

  del(url) {
    return axios.delete(url);
  },
};
