<template>
  <div>
    <div class="wrapper">
      <a-row v-for="conn of connections" :key="conn" :gutter="[6, 6]">
        <a-col
          :span="shouldDisplayWeightInput(conn) || isCalculated(conn) ? 16 : 24"
        >
          <UnitInputBox
            v-model="$store.state.project.connections[conn][`L${phaseIdx}`]"
            :label="
              $store.getters.is2P
                ? `${labelPrefix(phaseIdx - 1, $store.getters.isRWB)}${
                    phaseIdx === 1 ? 2 * conn + 1 : 2 * (conn + 1)
                  }`
                : `${labelPrefix(phaseIdx - 1, $store.getters.isRWB)}${
                    conn + 1
                  }`
            "
            :color="labelColor(phaseIdx - 1, $store.getters.isRWB)"
            readonly
            :phase="phaseIdx"
          >
          </UnitInputBox>
        </a-col>
        <a-col v-if="shouldDisplayWeightInput(conn)" :span="8">
          <div class="input-wrapper">
            <a-input-number
              v-model="form.weights[conn]"
              class="input"
              size="large"
              :precision="2"
              :min="0"
              :disabled="readonly || !isEnabled(conn)"
            />
            <span class="suffix">{{ suffix }}</span>
          </div>
        </a-col>
        <a-col v-if="isCalculated(conn)" :span="8">
          <div class="calculated-wrapper" disabled>
            <span class="calculated-text"> {{ calculatedText }} </span>
          </div>
        </a-col>
      </a-row>
    </div>

    <!--   this form is only for validation purpose   -->
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item prop="connections">
        <a-input v-if="false" />
      </a-form-model-item>
    </a-form-model>
  </div>
</template>

<script>
import { UNEQUAL_ALLOCATION_TYPE, NO_CONNECTION } from "../util/constants";
import { roundTo } from "../util/round";
import { labelColor, labelPrefix } from "../util/unit-input-label";

export default {
  name: "UnitInputGroupUnequalAllocation",
  props: {
    phaseIdx: { type: Number, default: 0 },
    hint: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
    value: { type: Array, default: () => [] },
    suffix: { type: String, default: UNEQUAL_ALLOCATION_TYPE.PERCENT },
    available: { type: Number, default: 0 },
    defaultEven: { type: Boolean, default: false },
    enabledUnits: { type: Array, default: () => [] },
  },
  data() {
    return {
      labelColor,
      labelPrefix,
      NO_CONNECTION,
      UNEQUAL_ALLOCATION_TYPE,
      connections: [0, 1, 2, 3, 4],
      form: {
        weights: [
          this.$store.state.project.algorithmWeight[this.phaseIdx - 1][0][2],
          this.$store.state.project.algorithmWeight[this.phaseIdx - 1][1][2],
          this.$store.state.project.algorithmWeight[this.phaseIdx - 1][2][2],
          this.$store.state.project.algorithmWeight[this.phaseIdx - 1][3][2],
          this.$store.state.project.algorithmWeight[this.phaseIdx - 1][4][2],
        ],
      },
      rules: {
        connections: [{ validator: this.validateConnection }],
      },
    };
  },
  computed: {
    connectedConnections() {
      return this.connections.filter((conn) => this.isConnected(conn));
    },
    enabledConnections() {
      return this.enabledUnits.length > 0
        ? this.enabledUnits
        : this.connectedConnections;
    },
    calculatedConnection() {
      return this.enabledConnections[this.enabledConnections.length - 1];
    },
    inputConnections() {
      return this.enabledConnections.slice(0, -1);
    },

    calculatedValue() {
      switch (this.suffix) {
        case UNEQUAL_ALLOCATION_TYPE.PERCENT:
          return roundTo(
            this.inputConnections.reduce(
              (acc, cur) => acc - Number(this.form.weights[cur]),
              100
            ),
            2
          );

        case UNEQUAL_ALLOCATION_TYPE.KW:
          return roundTo(
            this.inputConnections.reduce(
              (acc, cur) => acc - Number(this.form.weights[cur]),
              this.available
            ),
            2
          );

        default:
          return 0;
      }
    },
    calculatedText() {
      if (this.calculatedValue < 0) {
        return "calculated N/A";
      } else {
        return `calculated ${this.calculatedValue}${this.suffix}`;
      }
    },
  },
  watch: {
    form: {
      handler() {
        this.reset();
      },
      deep: true,
    },
    suffix(newVal, oldVal) {
      if (
        oldVal === UNEQUAL_ALLOCATION_TYPE.PERCENT &&
        newVal === UNEQUAL_ALLOCATION_TYPE.KW
      ) {
        this.switchFromPercentToKw();
      }

      if (
        oldVal === UNEQUAL_ALLOCATION_TYPE.KW &&
        newVal === UNEQUAL_ALLOCATION_TYPE.PERCENT
      ) {
        this.switchFromKwToPercent();
      }
    },
    phaseIdx() {
      this.form.weights = [
        this.$store.state.project.algorithmWeight[this.phaseIdx - 1][0][2],
        this.$store.state.project.algorithmWeight[this.phaseIdx - 1][1][2],
        this.$store.state.project.algorithmWeight[this.phaseIdx - 1][2][2],
        this.$store.state.project.algorithmWeight[this.phaseIdx - 1][3][2],
        this.$store.state.project.algorithmWeight[this.phaseIdx - 1][4][2],
      ];

      // make sure values are prefilled
      this.$nextTick(() => {
        if (this.suffix === UNEQUAL_ALLOCATION_TYPE.KW) {
          this.switchFromPercentToKw();
        }
      });
    },
  },
  mounted() {
    this.reset();
  },
  methods: {
    reset() {
      // prefill even value to input connections
      if (
        this.defaultEven &&
        this.inputConnections.length > 0 &&
        Number(this.form.weights[this.inputConnections[0]]) === 0
      ) {
        const evenValue =
          Math.floor(10000 / this.enabledConnections.length) / 100;
        this.inputConnections.forEach((conn) =>
          this.$set(this.form.weights, conn, evenValue)
        );
      }

      // set calculated value
      this.form.weights[this.calculatedConnection] = this.calculatedValue;

      // emit the values to the parent component
      this.$emit("input", this.getAlgoWeight());
    },
    validate(callback) {
      this.$refs.form.validate(callback);
    },
    getAlgoWeight() {
      if (this.calculatedValue === null) {
        return null;
      }
      switch (this.suffix) {
        case UNEQUAL_ALLOCATION_TYPE.PERCENT:
          return this.connections.map((conn) => [
            this.phaseIdx - 1,
            conn,
            Number(this.form.weights[conn]),
          ]);

        case UNEQUAL_ALLOCATION_TYPE.KW:
          return this.connections.map((conn) => [
            this.phaseIdx - 1,
            conn,
            (100 * Number(this.form.weights[conn])) / this.available,
          ]);
      }
    },
    isConnected(connection) {
      return (
        this.$store.state.project.connections[connection][
          `L${this.phaseIdx}`
        ] !== NO_CONNECTION
      );
    },
    isEnabled(connection) {
      return this.enabledConnections.includes(connection);
    },
    isCalculated(connection) {
      return connection === this.calculatedConnection;
    },
    shouldDisplayWeightInput(connection) {
      return this.isConnected(connection) && !this.isCalculated(connection);
    },
    validateConnection(rule, value, callback) {
      const errored = this.inputConnections
        .filter((conn) => this.form.weights[conn] === null)
        .map((conn) =>
          this.$store.getters.is2P
            ? `${labelPrefix(this.phaseIdx - 1, this.$store.getters.isRWB)}${
                this.phaseIdx === 1 ? 2 * conn + 1 : 2 * (conn + 1)
              }`
            : `${labelPrefix(this.phaseIdx - 1, this.$store.getters.isRWB)}${
                conn + 1
              }`
        );

      if (errored.length > 0) {
        callback(new Error(`Please input number to ${errored.join(", ")}.`));
      }

      if (this.calculatedValue < 0) {
        if (this.suffix === UNEQUAL_ALLOCATION_TYPE.PERCENT) {
          callback(new Error(`Total input percent is greater than 100%.`));
        } else {
          callback(
            new Error(
              `Total input allocation for all connections must be smaller than the available kW.`
            )
          );
        }
      }

      callback();
    },
    switchFromPercentToKw() {
      this.inputConnections
        .filter((conn) => this.form.weights[conn] !== null)
        .forEach((conn) => {
          this.$set(
            this.form.weights,
            conn,
            roundTo((this.form.weights[conn] * this.available) / 100, 2)
          );
        });
    },
    switchFromKwToPercent() {
      this.inputConnections
        .filter((conn) => this.form.weights[conn] !== null)
        .forEach((conn) => {
          this.$set(
            this.form.weights,
            conn,
            roundTo((this.form.weights[conn] / this.available) * 100, 2)
          );
        });
    },
  },
};
</script>

<style lang="less" scoped>
.wrapper {
  .input-wrapper {
    position: relative;

    .input {
      width: 100%;

      /deep/ .ant-input-number-input-wrap {
        padding-right: 20px;
      }

      /deep/ .ant-input-number-handler-wrap {
        display: none;
      }
    }

    .suffix {
      position: absolute;
      right: 10px;
      line-height: 50px;
      top: 1px;
    }
  }

  .calculated-wrapper {
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    height: 50px;
    line-height: 20px;
    text-align: right;
    padding: 0 9px;
    display: flex;
    align-items: center;
    justify-content: right;
  }

  .input,
  /deep/ .input input {
    height: 50px;
    text-align: center;

    &:last-child {
      grid-column-start: 1;
      grid-column-end: 3;

      .calculated {
        color: #aaa;
      }
    }
  }
}
</style>
