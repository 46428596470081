var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('a-row', {
    attrs: {
      "type": "flex",
      "align": "middle"
    }
  }, [_c('a-col', {
    attrs: {
      "span": 12
    }
  }, [_c('back-button', {
    style: {
      marginTop: 0
    },
    attrs: {
      "step": _vm.backStep
    }
  })], 1), _c('a-col', {
    attrs: {
      "span": 12
    }
  }, [_c('yellow-button', {
    attrs: {
      "to": _vm.to,
      "data-tn": _vm.dataTn
    },
    on: {
      "click": _vm.nextOnClick
    }
  }, [_vm._v(" " + _vm._s(_vm.nextText.toUpperCase()) + " ")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }