export const inviteProviderMixin = {
  data() {
    return {
      form: { email: "" },
      isModalVisible: false,
    };
  },
  methods: {
    inviteClicked(e) {
      e.preventDefault();
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.isModalVisible = true;
        } else {
          return false;
        }
      });
    },
    async yesClicked() {
      await this.$http.post(
        this.$api.inviteProvider(this.$store.state.project.serialNumber),
        {
          email: this.form.email,
          serialNum: this.$store.state.project.serialNumber,
        }
      );
      this.isModalVisible = false;
      this.$message.success("Invitation sent successfully.");
    },
  },
};
