<template>
  <div class="content">
    <progress-bar :percent="50" />

    <div class="info-text">What is connected to:</div>

    <BlackISVG class="info-icon" @click="noteVisible = true" />

    <UnitInputGroup2P
      v-if="$store.getters.is2P"
      ref="group"
      v-model="connections"
      :connection="currentIndex * 2 + 1"
      hint
      class="input-group"
    />

    <UnitInputGroup3P
      v-else
      ref="group"
      v-model="connections"
      :connection="currentIndex + 1"
      hint
      class="input-group"
    />

    <a-row type="flex" align="middle" class="back-n-next">
      <a-col :span="12">
        <back-button :style="{ marginTop: 0 }" />
      </a-col>
      <a-col :span="12">
        <yellow-button data-tn="phase-type-three-next" @click="nextClicked">
          NEXT
        </yellow-button>
      </a-col>
    </a-row>

    <yellow-modal v-model="noteVisible" icon padding="62px 26px 21px 26px">
      <div :style="{ fontSize: '21px', marginBottom: '1em' }">
        Enter the connected unit details eg:<br />
        - Apt 1<br />
        - Unit B<br />
        - Common light & power<br />
        Or, untick 'Connected' box if there is no connection here.
      </div>
    </yellow-modal>
  </div>
</template>

<script>
import BlackISVG from "../assets/svg/black-i-filled.svg";
export default {
  components: { BlackISVG },
  data() {
    return {
      noteVisible: false,
      shadowConnections: {
        L1: "",
        L2: "",
        L3: "",
      },
    };
  },
  computed: {
    currentIndex() {
      return Number(this.$route.params.index);
    },
    connections: {
      get() {
        if (
          this.shadowConnections.L1 !== "" ||
          this.shadowConnections.L2 !== "" ||
          this.shadowConnections.L3 !== ""
        ) {
          return this.shadowConnections;
        } else {
          return this.$store.state.project.connections[this.currentIndex];
        }
      },
      set(v) {
        this.shadowConnections = v;
      },
    },
  },
  methods: {
    nextClicked() {
      this.$refs.group.validate((valid) => {
        if (valid) {
          this.$store.commit("setConnection", {
            index: this.currentIndex,
            connection: this.connections,
          });
          this.navigate();
          this.reset();
        } else {
          return false;
        }
      });
    },
    reset() {
      this.connections = {
        L1: "",
        L2: "",
        L3: "",
      };
    },
    navigate() {
      this.$store.dispatch("uploadConnection");

      if (this.currentIndex === 4) {
        this.$router.push(`/commission/15`);
      } else {
        this.$router.push(`/commission/12/${this.currentIndex + 1}`);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  .info-text {
    margin-top: 29px;
    margin-bottom: 10px;
  }

  .info-icon {
    margin-bottom: 23px;
  }

  .input-group {
    margin-bottom: 5px;
  }

  .back-n-next {
    margin-top: 62px;
  }
}
</style>
