var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "content"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v("G'day!")]), _c('div', {
    staticClass: "description"
  }, [_vm._v("Welcome to the Solshare commissioning app.")]), _c('yellow-button', {
    attrs: {
      "to": "/login",
      "data-tn": "good-day-start"
    }
  }, [_vm._v(" START ")])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }