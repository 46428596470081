<template>
  <div class="content">
    <back-button />
    <div class="title">Access Control</div>

    <div class="wrapper">
      <div class="info-text">
        Invite asset manager to access the Solshare data
      </div>
      <a-form-model
        ref="form"
        :model="form"
        class="email-input-container"
        :rules="{
          email: [
            {
              type: 'email',
              required: true,
              message: 'please input a valid email address',
              trigger: 'blur',
            },
          ],
        }"
        @submit="inviteClicked"
      >
        <a-form-model-item prop="email">
          <a-input
            v-model="form.email"
            class="input-box"
            size="large"
            placeholder="Input email address"
          />
        </a-form-model-item>
        <yellow-button html-type="submit" class="invite-button">
          INVITE
        </yellow-button>
      </a-form-model>
    </div>
    <yellow-modal v-model="isModalVisible">
      <div style="margin-bottom: 40px">
        <p>We will send an invite email to {{ form.email }}.</p>
        <p>
          Are they an officially-appointed asset manager, building manager or
          solar partner providing ongoing maintenance support?
        </p>
      </div>
      <white-button @click="yesClicked">YES</white-button>
    </yellow-modal>
  </div>
</template>

<script>
import { inviteProviderMixin } from "../mixins/inviteProvider";

export default {
  name: "AccessControl",
  mixins: [inviteProviderMixin],
};
</script>

<style lang="less" scoped>
.content {
  .title {
    margin-bottom: 25px;
  }

  .wrapper {
    .info-text {
      margin-top: 40px;
    }

    .input-box {
      margin-top: 20px;
    }

    .invite-button {
      margin-top: 40px;
    }
  }
}
</style>
