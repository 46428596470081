var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "content"
  }, [_c('progress-bar', {
    attrs: {
      "percent": 67
    }
  }), _c('div', {
    staticClass: "info-text"
  }, [_vm._v("Review allocations:")]), _c('a-radio-group', {
    staticClass: "radio-group",
    attrs: {
      "size": "large"
    },
    model: {
      value: _vm.suffix,
      callback: function ($$v) {
        _vm.suffix = $$v;
      },
      expression: "suffix"
    }
  }, [_c('a-radio-button', {
    staticClass: "radio-button",
    attrs: {
      "value": _vm.UNEQUAL_ALLOCATION_TYPE.PERCENT
    }
  }, [_vm._v(" % ")]), _c('a-radio-button', {
    staticClass: "radio-button",
    attrs: {
      "value": _vm.UNEQUAL_ALLOCATION_TYPE.KW
    }
  }, [_vm._v(" kW ")])], 1), _vm._l(_vm.$store.getters.is2P ? [0, 1] : [0, 1, 2], function (index) {
    return _c('div', {
      key: index
    }, [_c('div', {
      staticClass: "form-label"
    }, [_c('span', [_vm._v("Phase " + _vm._s(index + 1))]), _c('span', {
      staticClass: "total-kw"
    }, [_vm._v(" " + _vm._s(_vm.roundToDown(_vm.$store.state.project.spec.dcSize / (_vm.$store.getters.is2P ? 2 : 3), 2)) + "kW available ")])]), _c('UnitInputGroupUnequalAllocation', {
      ref: "group",
      refInFor: true,
      attrs: {
        "phase-idx": index + 1,
        "suffix": _vm.suffix,
        "readonly": "",
        "available": _vm.$store.state.project.spec.dcSize / (_vm.$store.getters.is2P ? 2 : 3),
        "default-even": ""
      },
      model: {
        value: _vm.$store.state.project.algorithmWeight[index],
        callback: function ($$v) {
          _vm.$set(_vm.$store.state.project.algorithmWeight, index, $$v);
        },
        expression: "$store.state.project.algorithmWeight[index]"
      }
    })], 1);
  }), _c('div', {
    staticClass: "small-text"
  }, [_vm._v("Please confirm once allocations are correct:")]), _c('a-row', {
    attrs: {
      "type": "flex",
      "gutter": 10
    }
  }, [_c('a-col', {
    attrs: {
      "span": 12
    }
  }, [_c('white-button', {
    attrs: {
      "bordered": "",
      "to": "/commission/unequal-allocation/1",
      "data-tn": "unequal-allocation-edit"
    }
  }, [_vm._v(" EDIT ")])], 1), _c('a-col', {
    attrs: {
      "span": 12
    }
  }, [_c('yellow-button', {
    attrs: {
      "to": "/commission/ct-rating",
      "data-tn": "unequal-allocation-confirm"
    }
  }, [_vm._v(" CONFIRM ")])], 1)], 1), _c('back-button')], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }