var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "content"
  }, [_c('progress-bar', {
    attrs: {
      "percent": 50
    }
  }), _vm.$store.getters.isRWB ? _c('div', {
    staticClass: "info-text"
  }, [_vm._v(" What is connected to R" + _vm._s(_vm.currentIndex + 1) + ", W" + _vm._s(_vm.currentIndex + 1) + ", B" + _vm._s(_vm.currentIndex + 1) + "? ")]) : _vm.$store.getters.is2P ? _c('div', {
    staticClass: "info-text"
  }, [_vm._v(" What is connected to L1-" + _vm._s(_vm.currentIndex * 2 + 1) + ", L2-" + _vm._s(_vm.currentIndex * 2 + 2) + "? ")]) : _c('div', {
    staticClass: "info-text"
  }, [_vm._v(" What is connected to L1-" + _vm._s(_vm.currentIndex + 1) + ", L2-" + _vm._s(_vm.currentIndex + 1) + ", L3-" + _vm._s(_vm.currentIndex + 1) + "? ")]), _c('BlackISVG', {
    staticClass: "info-icon",
    on: {
      "click": function ($event) {
        _vm.noteVisible = true;
      }
    }
  }), _c('a-form-model', {
    ref: "form",
    staticClass: "input-box",
    attrs: {
      "model": _vm.form,
      "rules": _vm.rules
    }
  }, [_c('a-form-model-item', {
    attrs: {
      "prop": "connections"
    }
  }, [_c('a-input', {
    attrs: {
      "size": "large",
      "placeholder": "Type in unit name",
      "data-tn": `unit-input-${_vm.currentIndex + 1}`
    },
    model: {
      value: _vm.form.connections,
      callback: function ($$v) {
        _vm.$set(_vm.form, "connections", $$v);
      },
      expression: "form.connections"
    }
  })], 1)], 1), _c('a-row', {
    staticClass: "back-n-next",
    attrs: {
      "type": "flex",
      "align": "middle"
    }
  }, [_c('a-col', {
    attrs: {
      "span": 12
    }
  }, [_c('back-button', {
    style: {
      marginTop: 0
    }
  })], 1), _c('a-col', {
    attrs: {
      "span": 12
    }
  }, [_c('yellow-button', {
    attrs: {
      "data-tn": "phase-type-single-next"
    },
    on: {
      "click": _vm.nextClicked
    }
  }, [_vm._v(" NEXT ")])], 1)], 1), _c('yellow-modal', {
    attrs: {
      "icon": "",
      "padding": "62px 26px 21px 26px"
    },
    model: {
      value: _vm.noteVisible,
      callback: function ($$v) {
        _vm.noteVisible = $$v;
      },
      expression: "noteVisible"
    }
  }, [_c('div', {
    style: {
      fontSize: '21px',
      marginBottom: '1em'
    }
  }, [_vm._v(" Enter the connected unit details eg:"), _c('br'), _vm._v(" - Apt 1"), _c('br'), _vm._v(" - Unit B"), _c('br'), _vm._v(" - Common light & power ")])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }