<template>
  <div class="content">
    <progress-bar :percent="100" />

    <div class="info-text">
      On a 0-to-10 scale, how likely is it that you would recommend the
      SolShare?
    </div>

    <vue-slider
      v-model="score"
      :adsorb="true"
      :interval="1"
      :marks="true"
      :max="10"
      :drag-on-click="true"
      :tooltip="'none'"
      :contained="true"
      :dot-size="[41, 80]"
      :process-style="{
        backgroundColor: '#FFE86C',
        height: '5px',
        borderRadius: '5px',
        cursor: 'pointer',
      }"
      :rail-style="{
        backgroundColor: '#ccc',
        height: '5px',
        borderRadius: '5px',
        cursor: 'pointer',
      }"
    >
      <template #dot>
        <div class="score">{{ score }}</div>
        <SliderHandle class="dot" />
      </template>
      <template #step="{ active, pos }">
        <div v-if="pos < 100" :class="['slider-step', { active }]" />
      </template>
      <template #label="{ pos }">
        <div :class="['vue-slider-mark-label', 'custom-label']">
          <ThumbsDown v-if="pos === 0" />
          <ThumbsUp v-if="pos === 100" />
        </div>
      </template>
    </vue-slider>

    <div class="question">What is the primary reason for your score?</div>
    <a-textarea
      id="feedback"
      ref="textarea"
      v-model.trim="feedback"
      class="comment-box"
      placeholder="Leave your comment here…"
      data-tn="nps-text-area"
      @focus="closeModal"
    />

    <yellow-button data-tn="nps-submit" @click="submitClicked">
      SUBMIT
    </yellow-button>

    <!--  Modal  -->

    <yellow-modal
      v-model="noteVisible"
      :destroy-on-close="true"
      down-arrow="feedback"
      :closable="false"
      :top="40"
      :after-close="afterClose"
    >
      <div
        :style="{
          fontFamily: 'Helvetica Medium',
          fontSize: '25px',
          textAlign: 'center',
        }"
      >
        Are you sure you want to leave this blank?
      </div>
      <div
        :style="{
          textAlign: 'center',
          marginTop: '15px',
          marginBottom: '40px',
        }"
      >
        Your feedback helps us improve the SolShare and the commissioning
        process.
      </div>
      <white-button
        :block="false"
        :style="{ margin: '0 auto' }"
        to="/commission/22"
        @click="sendFeedback"
      >
        IGNORE
      </white-button>
    </yellow-modal>
  </div>
</template>

<script>
import ThumbsUp from "../assets/svg/thumbs-up.svg";
import ThumbsDown from "../assets/svg/thumbs-down.svg";
import SliderHandle from "../assets/svg/slider-handle.svg";
export default {
  name: "NetPromoterScore",
  components: {
    SliderHandle,
    ThumbsDown,
    ThumbsUp,
  },
  data() {
    return {
      score: 5,
      feedback: "",
      isIgnoreClicked: false,
      noteVisible: false,
    };
  },
  methods: {
    async sendFeedback() {
      await this.$http.post(
        this.$api.netPromoterScore,
        Object.assign(
          { score: this.score, comment: this.feedback },
          {
            environment: navigator.userAgent,
            page: this.$route.path,
            meter: this.$store.state.project.serialNumber,
          }
        )
      );
    },
    submitClicked() {
      if (this.feedback === "") {
        this.noteVisible = true;
      } else {
        this.sendFeedback();
        this.$router.push("/commission/22");
      }
    },
    closeModal() {
      this.noteVisible = false;
    },
    afterClose() {
      this.$refs.textarea.focus();
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  .info-text {
    margin-top: 28px;
    margin-bottom: 70px;
  }

  .question {
    margin-top: 48px;
    margin-bottom: 12px;
  }

  .comment-box {
    height: 87px;
    margin-bottom: 33px;
    z-index: 1001;
  }

  .score {
    position: relative;
    top: -21px;
    left: 6px;
    z-index: 2;
    font-size: 25px;
    cursor: pointer;
    width: 28px;
    text-align: center;
  }

  .dot {
    top: -52px;
    position: relative;
    cursor: pointer;
    transition: all 0.5s;
  }

  .slider-step {
    background-color: #9d9d9d;
    opacity: 0.52;
    height: 4px;
    width: 4px;
    border-radius: 4px;
    margin-top: 0.5px;

    &.active {
      display: none;
    }
  }
}
</style>
