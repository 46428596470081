var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "content"
  }, [_c('progress-bar', {
    attrs: {
      "percent": 30
    }
  }), _c('div', {
    staticClass: "title"
  }, [_vm._v("System Specifications")]), _c('a-form-model', {
    ref: "form",
    attrs: {
      "colon": false,
      "model": _vm.form,
      "rules": _vm.rules
    }
  }, [_c('a-form-model-item', {
    attrs: {
      "prop": "solshareName"
    },
    scopedSlots: _vm._u([{
      key: "label",
      fn: function () {
        return [_vm._v(" Solshare Name "), _c('BlackISVG', {
          on: {
            "click": function ($event) {
              _vm.note4Visible = true;
            }
          }
        })];
      },
      proxy: true
    }])
  }, [_c('a-input', {
    staticClass: "input-box",
    attrs: {
      "size": "large",
      "placeholder": "e.g. Solshare 1",
      "data-tn": "system-building-address-solshare-name"
    },
    model: {
      value: _vm.form.solshareName,
      callback: function ($$v) {
        _vm.$set(_vm.form, "solshareName", typeof $$v === 'string' ? $$v.trim() : $$v);
      },
      expression: "form.solshareName"
    }
  })], 1), _c('a-form-model-item', {
    attrs: {
      "prop": "dcSize"
    },
    scopedSlots: _vm._u([{
      key: "label",
      fn: function () {
        return [_vm._v(" DC System size (kW) "), _c('BlackISVG', {
          on: {
            "click": function ($event) {
              _vm.note1Visible = true;
            }
          }
        })];
      },
      proxy: true
    }])
  }, [_c('a-input', {
    staticClass: "input-box",
    attrs: {
      "data-tn": "spec-dc-input",
      "size": "large",
      "placeholder": "For this SolShare only"
    },
    model: {
      value: _vm.form.dcSize,
      callback: function ($$v) {
        _vm.$set(_vm.form, "dcSize", typeof $$v === 'string' ? $$v.trim() : $$v);
      },
      expression: "form.dcSize"
    }
  })], 1), _c('a-form-model-item', {
    attrs: {
      "prop": "acSize"
    },
    scopedSlots: _vm._u([{
      key: "label",
      fn: function () {
        return [_vm._v(" AC inverter capacity (kW) "), _c('BlackISVG', {
          on: {
            "click": function ($event) {
              _vm.note2Visible = true;
            }
          }
        })];
      },
      proxy: true
    }])
  }, [_c('a-input', {
    staticClass: "input-box",
    attrs: {
      "data-tn": "spec-ac-input",
      "size": "large",
      "placeholder": "For this SolShare only"
    },
    model: {
      value: _vm.form.acSize,
      callback: function ($$v) {
        _vm.$set(_vm.form, "acSize", typeof $$v === 'string' ? $$v.trim() : $$v);
      },
      expression: "form.acSize"
    }
  })], 1), _c('a-form-model-item', {
    attrs: {
      "prop": "inverter"
    },
    scopedSlots: _vm._u([{
      key: "label",
      fn: function () {
        return [_vm._v(" Inverter Brand "), _c('BlackISVG', {
          on: {
            "click": function ($event) {
              _vm.note3Visible = true;
            }
          }
        })];
      },
      proxy: true
    }])
  }, [_c('a-radio-group', {
    attrs: {
      "button-style": "solid",
      "size": "large"
    },
    model: {
      value: _vm.form.inverter,
      callback: function ($$v) {
        _vm.$set(_vm.form, "inverter", $$v);
      },
      expression: "form.inverter"
    }
  }, [_c('a-row', {
    attrs: {
      "type": "flex",
      "gutter": [7, 7]
    }
  }, _vm._l(_vm.inverters, function (inverter) {
    return _c('a-col', {
      key: inverter,
      attrs: {
        "span": 12
      }
    }, [_c('a-radio-button', {
      attrs: {
        "value": inverter,
        "data-tn": `spec-inverter-${inverter}`
      }
    }, [_vm._v(" " + _vm._s(inverter) + " ")])], 1);
  }), 1)], 1)], 1), _vm.form.inverter === _vm.inverters.Other ? _c('a-form-model-item', {
    attrs: {
      "label": "Please state your inverter brand",
      "prop": "inputInverter"
    }
  }, [_c('a-input', {
    staticClass: "input-box",
    attrs: {
      "size": "large",
      "placeholder": "Inverter brand name"
    },
    model: {
      value: _vm.form.inputInverter,
      callback: function ($$v) {
        _vm.$set(_vm.form, "inputInverter", typeof $$v === 'string' ? $$v.trim() : $$v);
      },
      expression: "form.inputInverter"
    }
  })], 1) : _vm._e()], 1), _c('a-row', {
    attrs: {
      "type": "flex",
      "align": "middle"
    }
  }, [_c('a-col', {
    attrs: {
      "span": 12
    }
  }, [_c('back-button', {
    style: {
      marginTop: 0
    }
  })], 1), _c('a-col', {
    attrs: {
      "span": 12
    }
  }, [_c('yellow-button', {
    attrs: {
      "data-tn": "spec-next-button"
    },
    on: {
      "click": _vm.nextClicked
    }
  }, [_vm._v(" NEXT ")])], 1)], 1), _c('yellow-modal', {
    attrs: {
      "icon": "",
      "padding": "62px 26px 21px 26px"
    },
    model: {
      value: _vm.note1Visible,
      callback: function ($$v) {
        _vm.note1Visible = $$v;
      },
      expression: "note1Visible"
    }
  }, [_c('div', {
    style: {
      fontSize: '21px',
      marginBottom: '1em'
    }
  }, [_vm._v(" Please enter the PV panel capacity in kW DC. ")]), _c('div', {
    style: {
      fontSize: '21px',
      marginBottom: '1em'
    }
  }, [_vm._v(" This value should be for the system generation connected to this SolShare only. ")])]), _c('yellow-modal', {
    attrs: {
      "icon": "",
      "padding": "62px 26px 21px 26px"
    },
    model: {
      value: _vm.note2Visible,
      callback: function ($$v) {
        _vm.note2Visible = $$v;
      },
      expression: "note2Visible"
    }
  }, [_c('div', {
    style: {
      fontSize: '21px',
      marginBottom: '1em'
    }
  }, [_vm._v(" Please enter the inverter capacity in kW AC. ")]), _c('div', {
    style: {
      fontSize: '21px',
      marginBottom: '1em'
    }
  }, [_vm._v(" This value should be for the system generation connected to this SolShare only. ")])]), _c('yellow-modal', {
    attrs: {
      "icon": "",
      "padding": "62px 26px 21px 26px"
    },
    model: {
      value: _vm.note3Visible,
      callback: function ($$v) {
        _vm.note3Visible = $$v;
      },
      expression: "note3Visible"
    }
  }, [_c('div', {
    style: {
      fontSize: '21px',
      marginBottom: '1em'
    }
  }, [_vm._v(" Please enter the brand of inverter. If not listed please select other. ")])]), _c('yellow-modal', {
    attrs: {
      "icon": "",
      "padding": "62px 26px 21px 26px"
    },
    model: {
      value: _vm.note4Visible,
      callback: function ($$v) {
        _vm.note4Visible = $$v;
      },
      expression: "note4Visible"
    }
  }, [_c('div', {
    style: {
      fontSize: '21px',
      marginBottom: '1em'
    }
  }, [_vm._v(" This is a descriptive label/name for each SolShare - use this if you would like to distinguish the SolShare between others installed on site. Examples include \"SolShare 1\", \"SolShare closest to the MSB\", \"SolShare for Building A\", etc. ")])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }