<template>
  <div class="content">
    <a-icon
      :component="TickSVG"
      :style="{ fontSize: '61px', marginTop: '71px' }"
    />
    <div class="info-text green">Your feedback has been submitted!</div>
    <div class="info-text black">
      Thank you for <br />
      helping us improve our <br />commissioning process.
    </div>
    <yellow-button :go="-1"> RETURN TO COMMISSIONING </yellow-button>
  </div>
</template>

<script>
import TickSVG from "../assets/svg/tick.svg";
export default {
  data() {
    return {
      TickSVG,
    };
  },
};
</script>

<style lang="less" scoped>
.content {
  text-align: center;

  .green {
    color: #005149;
    margin-top: 14px;
    margin-bottom: 1em;
  }

  .black {
    margin-bottom: 40px;
  }
}
</style>
