var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "content"
  }, [_c('back-button'), _c('div', {
    staticClass: "title"
  }, [_vm._v("Sites")]), _vm.$store.state.fleet.length === 0 ? _c('div', {
    staticClass: "wrapper"
  }, [_c('div', {
    staticClass: "info-text"
  }, [_vm._v(" It seems you don't have a commissioned site yet. ")])]) : _c('div', {
    staticClass: "wrapper"
  }, _vm._l(_vm.$store.state.fleet, function (fleet, i) {
    return _c('div', {
      key: i,
      staticClass: "item",
      on: {
        "click": function () {
          return _vm.itemClicked(fleet.id);
        }
      }
    }, [_c('span', [_vm._v(_vm._s(fleet.address))]), _c('SmallArrow')], 1);
  }), 0)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }