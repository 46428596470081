<template>
  <svg
    width="26"
    height="19"
    xmlns="http://www.w3.org/2000/svg"
    :style="{ transform: `scale(${width ? width / 26 : height / 19})` }"
  >
    <path
      d="m4.676 10.148 2.307 2.307a8.16 8.16 0 0 1 11.535 0l2.307-2.307c-4.458-4.459-11.69-4.459-16.15 0Zm4.614 4.614 3.46 3.46 3.461-3.46a4.898 4.898 0 0 0-6.921 0ZM.062 5.534 2.369 7.84c5.733-5.733 15.03-5.733 20.763 0l2.308-2.307c-7.008-7.008-18.37-7.008-25.378 0Z"
      :fill="color"
      fill-rule="evenodd"
    />
  </svg>
</template>

<script>
export default {
  name: "WiFi",
  props: {
    color: {
      type: String,
      default: "#FFF",
    },
    height: {
      type: String,
      default: "19",
    },
    width: {
      type: String,
      default: "26",
    },
  },
};
</script>

<style scoped></style>
