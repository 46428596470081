<template>
  <div class="content">
    <progress-bar :percent="10" />

    <div class="info-text">Entering unit serial number manually.</div>

    <BlackISVG class="info-icon" @click="noteVisible = true" />

    <a-form-model ref="form" :model="form" :rules="rules" layout="vertical">
      <a-form-model-item
        prop="serial"
        label="Please enter below the serial number located on the side of this SolShare:"
      >
        <a-input
          v-model="form.serial"
          size="large"
          class="input-box"
          placeholder="3P-35A-XXXX"
          data-tn="enter-serial-input"
        />
      </a-form-model-item>
    </a-form-model>

    <yellow-button
      class="manual-button"
      data-tn="enter-serial-confirm"
      :loading="loading"
      @click="confirmClicked"
    >
      CONFIRM
    </yellow-button>

    <back-button />

    <yellow-modal v-model="noteVisible" icon padding="62px 26px 21px 26px">
      <div :style="{ fontSize: '21px', marginBottom: '1em' }">
        The serial number can be found on the bottom right hand side of the
        SolShare.
      </div>

      <template #footer>
        <img
          src="../assets/png/serial-location.png"
          :style="{ width: '100%' }"
        />
      </template>
    </yellow-modal>
  </div>
</template>

<script>
import BlackISVG from "../assets/svg/black-i-filled.svg";
import { normalizeSerial } from "../util/normalize-serial";
export default {
  components: { BlackISVG },
  props: {
    nextAction: { type: String, default: "searchMeter" },
  },
  data() {
    return {
      noteVisible: false,
      loading: false,
      form: { serial: "" },
      rules: {
        serial: [
          {
            required: true,
            message: "Please input serial number",
            trigger: "blur",
          },
          {
            validator: async (_rule, value) => {
              // see: https://regex101.com/r/N5Ft55/1
              if (/(\dP_)?\d{2,3}A?_\d{4}(_test)?/.test(value)) {
                return Promise.resolve();
              }
              return Promise.reject(`Invalid serial number, valid format:
              3P_35A_XXXX, 2P_100_XXXX`);
            },
            trigger: "blur",
          },
        ],
      },
    };
  },
  watch: {
    "form.serial": {
      handler(newVal) {
        this.form.serial = normalizeSerial(newVal);
      },
      deep: true,
    },
  },
  methods: {
    confirmClicked() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          this.loading = true;
          await this.$store.dispatch(this.nextAction, {
            serial: this.form.serial,
            redirect: true,
          });
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  .info-text {
    margin-top: 29px;
  }

  .info-icon {
    margin-top: 10px;
    margin-bottom: 20px;
  }
  .manual-button {
    margin-top: 15px;
    margin-bottom: 15px;
  }
}
</style>
