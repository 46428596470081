var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "content"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v("Log in")]), _c('a-form-model', {
    ref: "form",
    attrs: {
      "model": _vm.form,
      "rules": _vm.rules,
      "layout": "vertical"
    }
  }, [_c('a-form-model-item', {
    attrs: {
      "label": "Email address",
      "prop": "email"
    }
  }, [_c('a-input', {
    staticClass: "input-box",
    attrs: {
      "size": "large",
      "data-tn": "login-email-input"
    },
    model: {
      value: _vm.form.email,
      callback: function ($$v) {
        _vm.$set(_vm.form, "email", typeof $$v === 'string' ? $$v.trim() : $$v);
      },
      expression: "form.email"
    }
  })], 1), _c('a-form-model-item', {
    attrs: {
      "label": "Password",
      "prop": "password"
    }
  }, [_c('a-input-password', {
    staticClass: "input-box",
    attrs: {
      "data-tn": "login-password-input",
      "size": "large"
    },
    on: {
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.loginClicked.apply(null, arguments);
      }
    },
    model: {
      value: _vm.form.password,
      callback: function ($$v) {
        _vm.$set(_vm.form, "password", $$v);
      },
      expression: "form.password"
    }
  })], 1)], 1), _c('a-row', {
    attrs: {
      "justify": "end",
      "type": "flex"
    }
  }, [_c('div', {
    staticClass: "grey",
    on: {
      "click": _vm.forgetPasswordClicked
    }
  }, [_vm._v("Forgot password?")])]), _c('a-row', {
    attrs: {
      "justify": "start",
      "type": "flex",
      "align": "middle"
    }
  }, [_c('div', [_vm._v(" Or "), _c('span', {
    staticClass: "green",
    on: {
      "click": _vm.signUpClicked
    }
  }, [_vm._v("Sign Up")]), _vm._v(" here ")])]), _c('yellow-button', {
    staticClass: "submit",
    attrs: {
      "loading": _vm.loginLoading,
      "data-tn": "login-submit"
    },
    on: {
      "click": _vm.loginClicked
    }
  }, [_vm._v(" SUBMIT ")]), _c('yellow-modal', {
    attrs: {
      "color": "#fff",
      "padding": "67px 28px 47px 28px"
    },
    model: {
      value: _vm.forgetPasswordModalVisible,
      callback: function ($$v) {
        _vm.forgetPasswordModalVisible = $$v;
      },
      expression: "forgetPasswordModalVisible"
    }
  }, [_c('div', {
    style: {
      marginBottom: '28px',
      fontSize: '25px',
      lineHeight: '21px',
      fontFamily: 'Helvetica Medium'
    }
  }, [_vm._v(" Forgotten password? ")]), _c('div', {
    staticStyle: {
      "margin-bottom": "14px"
    }
  }, [_vm._v(" We will send you a link you can use to create a new password. ")]), _c('a-form-model', {
    attrs: {
      "model": _vm.form,
      "layout": "vertical"
    }
  }, [_c('a-form-model-item', {
    attrs: {
      "label": "Email address"
    }
  }, [_c('a-input', {
    staticClass: "input-box",
    attrs: {
      "size": "large"
    },
    model: {
      value: _vm.form.email,
      callback: function ($$v) {
        _vm.$set(_vm.form, "email", typeof $$v === 'string' ? $$v.trim() : $$v);
      },
      expression: "form.email"
    }
  })], 1)], 1), _c('yellow-button', {
    attrs: {
      "loading": _vm.sendLoading
    },
    on: {
      "click": _vm.forgetSend
    }
  }, [_vm._v(" SEND ")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }