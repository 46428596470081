var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "content"
  }, [_c('back-button'), _c('div', {
    staticClass: "title"
  }, [_vm._v("Fault Status")]), _vm.displayFaults().length === 0 ? _c('div', {
    staticClass: "wrapper"
  }, [_c('OKSVG'), _c('div', {
    staticClass: "no-issue"
  }, [_vm._v("No issues here!")])], 1) : _c('div', {
    staticClass: "fault-wrapper"
  }, _vm._l(_vm.displayFaults(), function (fault) {
    return _c('div', {
      key: fault,
      staticClass: "fault"
    }, [_c('div', [_c('div', {
      staticClass: "fault-name"
    }, [_vm._v(_vm._s(_vm.FAULT_DESCRIPTION[fault].name))]), _c('div', {
      staticClass: "description"
    }, [_vm._v(_vm._s(_vm.FAULT_DESCRIPTION[fault].desc))])])]);
  }), 0), _c('div', {
    staticClass: "title"
  }, [_vm._v("We're here to help.")]), _c('p', {
    staticClass: "text"
  }, [_vm._v(" If you have any questions, please do not hesitate to get in touch. ")]), _c('grey-contact-box', {
    attrs: {
      "type": "phone"
    }
  }), _c('grey-contact-box', {
    attrs: {
      "type": "email"
    }
  }), _c('back-button')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }