var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "content"
  }, [_c('progress-bar', {
    attrs: {
      "percent": 10
    }
  }), _vm._m(0), _c('white-button', {
    attrs: {
      "bordered": ""
    },
    on: {
      "click": _vm.tryAgainClicked
    }
  }, [_vm._v(" OK, LET’S TRY AGAIN ")])], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "info-text"
  }, [_c('p', [_vm._v("Sorry! We cannot find the SolShare you entered.")]), _c('p', [_vm._v("Please re-type the serial number.")]), _c('p', [_vm._v("If the problem persists, please contact Allume.")])]);

}]

export { render, staticRenderFns }