var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "content"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v("We're here to help.")]), _c('p', {
    staticClass: "text"
  }, [_vm._v(" If you have any doubts during the commissioning process, please do not hesitate to get in touch. ")]), _c('grey-contact-box', {
    attrs: {
      "type": "phone",
      "region": _vm.region
    }
  }), _c('grey-contact-box', {
    attrs: {
      "type": "email",
      "region": _vm.region
    }
  }), _c('back-button')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }