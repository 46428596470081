<template>
  <a-row
    type="flex"
    align="middle"
    justify="center"
    class="commission-item__row"
  >
    <a-col :span="16" :style="{ color: statusColor(status) }">
      {{ item }}
    </a-col>
    <a-col :span="4" :offset="4">
      <SuccessSVG v-if="status === COMMISSION_STATUS.SUCCESS" />
      <a-icon
        v-else-if="status === COMMISSION_STATUS.PENDING"
        type="loading"
        style="font-size: 30px"
      />
      <FailedSVG v-else />
    </a-col>
  </a-row>
</template>

<script>
import SuccessSVG from "../assets/svg/success.svg";
import FailedSVG from "../assets/svg/failed.svg";
import { COMMISSION_STATUS } from "../util/constants";
export default {
  name: "CommissionItem",
  components: { SuccessSVG, FailedSVG },
  props: {
    item: { type: String, default: "" },
    status: { type: String, default: COMMISSION_STATUS.FAILED },
  },
  data() {
    return { COMMISSION_STATUS };
  },
  methods: {
    statusColor(status) {
      switch (status) {
        case COMMISSION_STATUS.SUCCESS:
          return "#005149";
        case COMMISSION_STATUS.PENDING:
          return "#000";
        case COMMISSION_STATUS.FAILED:
          return "#FF0000";
      }
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  .commission-item__row {
    padding: 7px 0;
    height: 56px;
  }
}
</style>
