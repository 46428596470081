export function chunk(arr, num) {
  return arr.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / num);

    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = []; // start a new chunk
    }

    resultArray[chunkIndex].push(item);

    return resultArray;
  }, []);
}

export function frontendToBackend(arr) {
  return arr
    .flat()
    .filter(([_phase, _conn, weight]) => weight > 0)
    .map(([_phase, _conn, weight]) => [
      _phase,
      _conn,
      Math.round(weight * 100),
    ]);
  // weight is 10000 based. e.g, 1234 is 1234 / 10000 = 0.1234 = 12.34%
}

export function backendToFrontend(arr = [], is2P) {
  const template = [];
  for (let _phase = 0; _phase < (is2P ? 2 : 3); _phase++) {
    for (let _index = 0; _index < 5; _index++) {
      template.push([_phase, _index, 0]);
    }
  }
  arr
    .map(([phase, conn, weight]) => [phase, conn, weight / 100])
    .forEach(([phase, conn, weight]) => {
      template[5 * phase + conn][2] = weight;
    });

  return chunk(template, 5);
}
