var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "content"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v("Important!")]), _c('div', {
    staticClass: "info-text"
  }, [_vm._v(" Software update required."), _c('br'), _vm._v(" To do this safely, "), _c('b', [_vm._v("only close")]), _vm._v(" SolShare maintenance isolation point belonging to SolShare "), _c('b', [_vm._v("output L1-1")]), _vm._v(". "), _c('br'), _vm._v(" All other SolShare outputs "), _c('b', [_vm._v("must remain open")]), _vm._v(" (disconnected) for the duration of the update. "), _c('span', {
    staticClass: "info-icon"
  }, [_c('BlackISVG', {
    staticClass: "info-icon",
    on: {
      "click": function ($event) {
        _vm.noteVisible = true;
      }
    }
  })], 1)]), _c('back-and-next', {
    attrs: {
      "next-text": "OK, I have done that",
      "to": "/software-update",
      "data-tn": "ok-i-have-done-that"
    }
  }), _c('yellow-modal', {
    attrs: {
      "icon": "",
      "padding": "62px 26px 21px 26px"
    },
    scopedSlots: _vm._u([{
      key: "footer",
      fn: function () {
        return [_c('img', {
          style: {
            width: '100%'
          },
          attrs: {
            "src": require("../assets/png/relay.png")
          }
        })];
      },
      proxy: true
    }]),
    model: {
      value: _vm.noteVisible,
      callback: function ($$v) {
        _vm.noteVisible = $$v;
      },
      expression: "noteVisible"
    }
  }, [_c('div', {
    style: {
      fontSize: '21px',
      marginBottom: '1em'
    }
  }, [_c('span', {
    staticStyle: {
      "font-family": "Helvetica Bold, serif"
    }
  }, [_vm._v("Only")]), _vm._v(" close the isolation point highlighted by the arrow. Keep all other isolation points open while software update in progress. ")])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }