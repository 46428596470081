<template>
  <div class="back-button" @click="onClick">
    <a-icon type="left" /> {{ text.toUpperCase() }}
  </div>
</template>

<script>
export default {
  name: "BackButton",
  props: {
    text: { type: String, default: "back" },
    step: { type: Number, default: -1 },
    clickHandler: { type: Function, default: () => {} },
  },
  methods: {
    onClick() {
      this.clickHandler();
      this.$router.go(this.step);
    },
  },
};
</script>

<style lang="less" scoped>
.back-button {
  font-size: 15px;
  margin-top: 30px;
  cursor: pointer;

  span {
    letter-spacing: 2px;
  }
}
</style>
