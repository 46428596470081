<template>
  <div class="content">
    <progress-bar :percent="67" />

    <div class="info-text">Review allocations:</div>

    <a-radio-group v-model="suffix" size="large" class="radio-group">
      <a-radio-button
        :value="UNEQUAL_ALLOCATION_TYPE.PERCENT"
        class="radio-button"
      >
        %
      </a-radio-button>
      <a-radio-button :value="UNEQUAL_ALLOCATION_TYPE.KW" class="radio-button">
        kW
      </a-radio-button>
    </a-radio-group>

    <div v-for="index of $store.getters.is2P ? [0, 1] : [0, 1, 2]" :key="index">
      <div class="form-label">
        <span>Phase {{ index + 1 }}</span>
        <span class="total-kw">
          {{
            roundToDown(
              $store.state.project.spec.dcSize / ($store.getters.is2P ? 2 : 3),
              2
            )
          }}kW available
        </span>
      </div>

      <UnitInputGroupUnequalAllocation
        ref="group"
        v-model="$store.state.project.algorithmWeight[index]"
        :phase-idx="index + 1"
        :suffix="suffix"
        readonly
        :available="
          $store.state.project.spec.dcSize / ($store.getters.is2P ? 2 : 3)
        "
        default-even
      />
    </div>

    <div class="small-text">Please confirm once allocations are correct:</div>
    <a-row type="flex" :gutter="10">
      <a-col :span="12">
        <white-button
          bordered
          to="/commission/unequal-allocation/1"
          data-tn="unequal-allocation-edit"
        >
          EDIT
        </white-button>
      </a-col>
      <a-col :span="12">
        <yellow-button
          to="/commission/ct-rating"
          data-tn="unequal-allocation-confirm"
        >
          CONFIRM
        </yellow-button>
      </a-col>
    </a-row>
    <back-button />
  </div>
</template>

<script>
import { UNEQUAL_ALLOCATION_TYPE } from "../util/constants";
import { roundToDown } from "../util/round";

export default {
  data() {
    return {
      UNEQUAL_ALLOCATION_TYPE,
      roundToDown,
      suffix: UNEQUAL_ALLOCATION_TYPE.PERCENT,
    };
  },
};
</script>

<style lang="less" scoped>
.content {
  .info-text {
    margin-top: 29px;
    margin-bottom: 43px;
  }

  .radio-group {
    width: 100%;
    display: flex;
    margin-bottom: 35px;

    .radio-button {
      width: 50%;
      color: black;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: none;

      span {
        height: 60px;
      }
    }

    .ant-radio-button-wrapper-checked {
      background: #ffe86c;
    }
  }

  .form-label {
    display: flex;
    justify-content: space-between;
    color: rgba(0, 0, 0, 0.85);
    margin-bottom: 8px;

    .total-kw {
      color: #aaa;
      font-style: italic;
    }
  }

  .small-text {
    margin-bottom: 11px;
  }
}
</style>
