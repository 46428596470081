<template>
  <div class="content">
    <back-button />
    <div class="title">Fault Status</div>

    <div v-if="displayFaults().length === 0" class="wrapper">
      <OKSVG />
      <div class="no-issue">No issues here!</div>
    </div>

    <div v-else class="fault-wrapper">
      <div v-for="fault of displayFaults()" :key="fault" class="fault">
        <div>
          <div class="fault-name">{{ FAULT_DESCRIPTION[fault].name }}</div>
          <div class="description">{{ FAULT_DESCRIPTION[fault].desc }}</div>
        </div>
      </div>
    </div>

    <div class="title">We're here to help.</div>

    <p class="text">
      If you have any questions, please do not hesitate to get in touch.
    </p>

    <grey-contact-box type="phone" />
    <grey-contact-box type="email" />
    <back-button />
  </div>
</template>

<script>
import OKSVG from "../assets/svg/ok.svg";
import { faultMixin } from "../mixins/faults";
export default {
  name: "FaultStatus",
  components: { OKSVG },
  mixins: [faultMixin],
};
</script>

<style lang="less" scoped>
.content {
  .title {
    margin-bottom: 20px;
  }

  .fault-wrapper {
    margin-top: 43px;

    .fault {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      font-size: 17px;
      margin-bottom: 27px;

      .fault-name {
        margin-bottom: 8px;
      }

      .description {
        color: #9d9d9d;
      }
    }
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #ccc;
    padding-bottom: 27px;
    margin-bottom: 26px;

    .no-issue {
      font-family: "Helvetica Light", serif;
      font-size: 25px;
      color: #005149;
      letter-spacing: 0.2px;
      text-align: center;
      line-height: 29px;
      margin-top: 23px;
    }
  }

  .text {
    margin: 21px 0 32px 0;
  }
}
</style>
