var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "content"
  }, [_c('div', {
    staticClass: "info-text"
  }, [_vm._v(" To begin, power the SolShare by following the start-up procedure: ")]), _vm._m(0), !_vm.$route.query.info ? _c('yellow-button', {
    attrs: {
      "to": _vm.hasSite ? '/my-sites' : '/add-a-site',
      "data-tn": "begin-commissioning"
    }
  }, [_vm._v(" BEGIN COMMISSIONING ")]) : _vm._e(), _c('back-button')], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "procedure"
  }, [_c('p', {
    staticClass: "bold"
  }, [_vm._v("SolShare Startup Procedure")]), _c('p', [_c('span', {
    staticClass: "bold"
  }, [_vm._v("1.")]), _vm._v(" Turn on DC PV Array Isolator located next to Inverter ")]), _c('p', [_c('span', {
    staticClass: "bold"
  }, [_vm._v("2.")]), _vm._v(" Turn on INVERTER AC ISOLATOR")]), _c('p', [_c('span', {
    staticClass: "bold"
  }, [_vm._v("3.")]), _vm._v(" Turn on SOLSHARE MAINTENANCE ISOLATOR (INVERTER) [If present] ")]), _c('p', [_c('span', {
    staticClass: "bold"
  }, [_vm._v("4.")]), _vm._v(" Ensure MAIN SWITCH (NORMAL SUPPLY) for each tenancy are on ")]), _c('p', [_c('span', {
    staticClass: "bold"
  }, [_vm._v("5.")]), _vm._v(" Turn on MAIN SWITCH (INVERTER SUPPLY) for all tenancies connected to SolShare ")]), _c('p', [_c('span', {
    staticClass: "bold"
  }, [_vm._v("6.")]), _vm._v(" Turn on all SOLSHARE MAINTENANCE ISOLATORS (GRID) [if present] ")])]);

}]

export { render, staticRenderFns }