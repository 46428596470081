var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "content"
  }, [_c('progress-bar', {
    attrs: {
      "percent": 60
    }
  }), _c('IndexFingerSVG', {
    staticClass: "finder-icon"
  }), _c('div', {
    staticClass: "info-text"
  }, [_vm._v(" It’s important that the correct delivery method is uploaded. Please confirm with your company which delivery is required and select from the below. ")]), !_vm.$store.getters.is2P ? _c('white-button', {
    staticClass: "white-button",
    attrs: {
      "data-tn": "demand-based-delivery",
      "bordered": "",
      "to": "/commission/ct-rating"
    },
    on: {
      "click": function () {
        return _vm.methodClicked(_vm.ALGORITHM_TYPE.DEMAND_BASED_DELIVERY);
      }
    }
  }, [_vm._v(" DEMAND-BASED DELIVERY ")]) : _vm._e(), _c('white-button', {
    staticClass: "white-button",
    attrs: {
      "data-tn": "custom-allocation",
      "bordered": "",
      "to": _vm.$store.getters.isUnequalAlgorithm ? '/commission/unequal-allocation-review' : '/commission/ct-rating'
    },
    on: {
      "click": function () {
        return _vm.methodClicked(_vm.ALGORITHM_TYPE.CUSTOM_ALLOCATION);
      }
    }
  }, [_vm._v(" CUSTOM ALLOCATION ")]), _c('back-button')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }