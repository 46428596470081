export const enabledUnitsMixin = {
  methods: {
    getEnabled(index) {
      const phases = this.$store.getters.is2P ? [0, 1] : [0, 1, 2];
      return phases.map((phase) =>
        this.$store.state.systemInfo.config.enabled_units?.[phase]?.includes(
          index
        )
      );
    },
  },
};
