var render = function render(){
  var _this = this;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "content"
  }, [_c('progress-bar', {
    attrs: {
      "percent": 100
    }
  }), _c('CheersSVG', {
    staticClass: "cheers-icon"
  }), _vm._m(0), _c('div', {
    staticClass: "invite-section"
  }, [_c('div', {
    staticClass: "info-text"
  }, [_vm._v(" Invite an asset manager to access the Solshare data ")]), _c('a-form-model', {
    ref: "form",
    staticClass: "email-input-container",
    attrs: {
      "model": _vm.form,
      "rules": {
        email: [{
          type: 'email',
          required: true,
          message: 'please input a valid email address',
          trigger: 'blur'
        }]
      }
    },
    on: {
      "submit": _vm.inviteClicked
    }
  }, [_c('a-form-model-item', {
    attrs: {
      "prop": "email"
    }
  }, [_c('div', {
    staticClass: "input-container"
  }, [_c('a-input', {
    staticClass: "input-box",
    attrs: {
      "size": "large",
      "placeholder": "Input email address",
      "data-tn": "invite-email"
    },
    model: {
      value: _vm.form.email,
      callback: function ($$v) {
        _vm.$set(_vm.form, "email", $$v);
      },
      expression: "form.email"
    }
  }), _c('a-button', {
    staticClass: "invite-button",
    attrs: {
      "html-type": "submit",
      "data-tn": "invite-submit"
    }
  }, [_vm._v("INVITE")])], 1)]), _c('span', [_vm._v(" If you don't know right now, that's ok. This can be entered later in the 'View Fleet' menu. ")])], 1)], 1), _c('yellow-button', {
    staticClass: "yellow-button",
    attrs: {
      "to": "/congratulations",
      "data-tn": "commission-finish"
    }
  }, [_vm._v(" FINISH ")]), _c('white-button', {
    staticClass: "white-button",
    attrs: {
      "bordered": "",
      "to": "/commission/1"
    },
    on: {
      "click": function () {
        return _this.$store.commit('resetProject');
      }
    }
  }, [_vm._v(" COMMISSION ANOTHER UNIT ")]), _c('white-button', {
    staticClass: "white-button",
    attrs: {
      "bordered": ""
    },
    on: {
      "click": _vm.systemInfoClicked
    }
  }, [_vm.loading ? _c('a-icon', {
    attrs: {
      "type": "loading"
    }
  }) : _c('span', [_vm._v("VIEW SYSTEM INFO")])], 1), _c('yellow-modal', {
    model: {
      value: _vm.isModalVisible,
      callback: function ($$v) {
        _vm.isModalVisible = $$v;
      },
      expression: "isModalVisible"
    }
  }, [_c('div', {
    staticStyle: {
      "margin-bottom": "40px"
    }
  }, [_c('p', [_vm._v("We will send an email to " + _vm._s(_vm.form.email) + ".")]), _c('p', [_vm._v(" Are they an officially-appointed asset manager, building manager or solar partner providing ongoing maintenance support? ")])]), _c('white-button', {
    attrs: {
      "data-tn": "confirm-email-send"
    },
    on: {
      "click": _vm.yesClicked
    }
  }, [_vm._v("YES")])], 1)], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "info-text",
    attrs: {
      "data-tn": "commission-success-success"
    }
  }, [_vm._v(" Commissioning "), _c('br'), _vm._v("successful! ")]);

}]

export { render, staticRenderFns }