var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "nav-wrapper"
  }, [_c('a-row', {
    staticClass: "nav",
    attrs: {
      "type": "flex",
      "justify": "space-around",
      "align": "middle"
    }
  }, [_c('a-col', {
    attrs: {
      "span": 2
    }
  }, [_c('NavSVG', {
    staticClass: "nav-btn",
    attrs: {
      "data-tn": "nav-bar-expand"
    },
    on: {
      "click": _vm.toggleOverlay
    }
  })], 1), _c('a-col', {
    attrs: {
      "span": 12
    }
  }, [_c('img', {
    staticClass: "nav__logo",
    attrs: {
      "src": require("../assets/png/allume-logo.png"),
      "width": "100"
    }
  })]), _c('a-col', {
    attrs: {
      "span": 3
    }
  }, [_c(_vm.$store.state.wifiStatus.connected ? 'WifiNavSVG' : 'WifiNavNoGoodSVG', {
    tag: "component",
    staticClass: "nav-btn",
    class: {
      hidden: !_vm.$store.state.showWifiIcon,
      blink: !_vm.$store.state.wifiStatus.connected
    },
    attrs: {
      "id": "nav-wifi"
    },
    on: {
      "click": _vm.wifiClick
    }
  })], 1), _c('a-col', {
    attrs: {
      "span": 3
    }
  }, [_c('ContactSVG', {
    staticClass: "nav-btn",
    attrs: {
      "id": "nav-contact"
    },
    on: {
      "click": _vm.contactClick
    }
  })], 1), _c('a-col', {
    attrs: {
      "span": 3
    }
  }, [_c('FeedbackSVG', {
    staticClass: "nav-btn",
    attrs: {
      "id": "nav-feedback"
    },
    on: {
      "click": _vm.feedbackClick
    }
  })], 1)], 1), _c('NavBarOverlay', {
    model: {
      value: _vm.showOverlay,
      callback: function ($$v) {
        _vm.showOverlay = $$v;
      },
      expression: "showOverlay"
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }