<template>
  <svg
    width="16"
    height="16"
    :style="{ transform: `scale(${width ? width / 16 : height / 16})` }"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.603 9.353c.062-.51.109-1.028.109-1.558s-.043-1.048-.11-1.558h2.63c.13.499.207 1.02.207 1.558s-.074 1.06-.206 1.558h-2.63Zm-1.375 4.333c.467-.87.822-1.8 1.075-2.774h2.299a6.267 6.267 0 0 1-3.374 2.774Zm-.199-4.333H6.382a10.826 10.826 0 0 1-.124-1.558c0-.53.05-1.048.124-1.558h3.647c.074.51.125 1.028.125 1.558s-.051 1.048-.125 1.558Zm-1.823 4.648a10.998 10.998 0 0 1-1.489-3.09h2.977a10.998 10.998 0 0 1-1.488 3.09ZM5.108 4.68H2.81A6.247 6.247 0 0 1 6.184 1.9a12.48 12.48 0 0 0-1.076 2.778ZM2.81 10.912h2.298a12.48 12.48 0 0 0 1.076 2.777 6.246 6.246 0 0 1-3.374-2.777Zm-.635-1.559a6.235 6.235 0 0 1-.203-1.558c0-.537.074-1.06.203-1.558h2.63c-.063.51-.106 1.028-.106 1.558s.043 1.048.11 1.558H2.174Zm6.03-7.764a10.79 10.79 0 0 1 1.489 3.09H6.717a10.79 10.79 0 0 1 1.489-3.09Zm5.393 3.09h-2.299a12.376 12.376 0 0 0-1.075-2.774 6.24 6.24 0 0 1 3.374 2.774ZM8.202.004A7.786 7.786 0 0 0 .414 7.795a7.786 7.786 0 0 0 7.788 7.792 7.793 7.793 0 0 0 7.795-7.792A7.793 7.793 0 0 0 8.202.004Z"
      :fill="color"
      fill-rule="evenodd"
    />
  </svg>
</template>

<script>
export default {
  name: "Network",
  props: {
    color: {
      type: String,
      default: "#FFF",
    },
    height: {
      type: String,
      default: "16",
    },
    width: {
      type: String,
      default: "16",
    },
  },
};
</script>

<style scoped></style>
