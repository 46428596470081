var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "content"
  }, [_c('back-button'), _c('div', {
    staticClass: "title"
  }, [_vm._v("Access Control")]), _c('div', {
    staticClass: "wrapper"
  }, [_c('div', {
    staticClass: "info-text"
  }, [_vm._v(" Invite asset manager to access the Solshare data ")]), _c('a-form-model', {
    ref: "form",
    staticClass: "email-input-container",
    attrs: {
      "model": _vm.form,
      "rules": {
        email: [{
          type: 'email',
          required: true,
          message: 'please input a valid email address',
          trigger: 'blur'
        }]
      }
    },
    on: {
      "submit": _vm.inviteClicked
    }
  }, [_c('a-form-model-item', {
    attrs: {
      "prop": "email"
    }
  }, [_c('a-input', {
    staticClass: "input-box",
    attrs: {
      "size": "large",
      "placeholder": "Input email address"
    },
    model: {
      value: _vm.form.email,
      callback: function ($$v) {
        _vm.$set(_vm.form, "email", $$v);
      },
      expression: "form.email"
    }
  })], 1), _c('yellow-button', {
    staticClass: "invite-button",
    attrs: {
      "html-type": "submit"
    }
  }, [_vm._v(" INVITE ")])], 1)], 1), _c('yellow-modal', {
    model: {
      value: _vm.isModalVisible,
      callback: function ($$v) {
        _vm.isModalVisible = $$v;
      },
      expression: "isModalVisible"
    }
  }, [_c('div', {
    staticStyle: {
      "margin-bottom": "40px"
    }
  }, [_c('p', [_vm._v("We will send an invite email to " + _vm._s(_vm.form.email) + ".")]), _c('p', [_vm._v(" Are they an officially-appointed asset manager, building manager or solar partner providing ongoing maintenance support? ")])]), _c('white-button', {
    on: {
      "click": _vm.yesClicked
    }
  }, [_vm._v("YES")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }