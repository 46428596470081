<template>
  <a-drawer
    :visible="value"
    :mask="false"
    :body-style="{ padding: 0 }"
    :closable="false"
    width="100vw"
    placement="left"
    position="left"
  >
    <div class="nav__overlay">
      <a-row align="top" type="flex" class="nav__overlay__close">
        <a-icon
          :component="CloseSVG"
          :style="{ fontSize: '22px' }"
          @click="close"
        />
      </a-row>
      <a-row align="top" type="flex" class="nav__overlay__item">
        <router-link
          to="/welcome"
          class="nav__overlay__item__link"
          @click.native="close"
        >
          Homepage
        </router-link>
      </a-row>
      <a-row align="top" type="flex" class="nav__overlay__item">
        <router-link
          to="/contact"
          class="nav__overlay__item__link"
          @click.native="close"
        >
          Contact us
        </router-link>
      </a-row>
      <a-row align="top" type="flex" class="nav__overlay__item">
        <router-link
          to="/info-centre"
          class="nav__overlay__item__link"
          @click.native="close"
        >
          Info centre
        </router-link>
      </a-row>
      <a-row align="top" type="flex" class="nav__overlay__item">
        <a
          href="https://allumeenergy.com.au/about"
          class="nav__overlay__item__link"
          @click="close"
        >
          About Allume
        </a>
      </a-row>
      <a-row
        v-if="$store.getters.isLoggedIn"
        align="top"
        type="flex"
        class="nav__overlay__item"
      >
        <a
          class="nav__overlay__item__link"
          data-tn="nav-bar-overlay-logout"
          @click="logout"
        >
          Logout
        </a>
      </a-row>
    </div>
  </a-drawer>
</template>

<script>
import CloseSVG from "../assets/svg/close.svg";
export default {
  name: "NavBarOverlay",
  props: {
    value: { type: Boolean },
  },
  data() {
    return { CloseSVG };
  },
  methods: {
    close() {
      this.$emit("input", false);
    },
    logout() {
      this.$store.commit("setUser", { email: "", token: "" });
      this.$router.push("/");
      this.close();
    },
  },
};
</script>

<style lang="less" scoped>
.nav__overlay {
  width: 100vw;
  height: 100vh;
  background: #ffe86c;
  padding: 27px 24px;

  .nav__overlay__close {
    margin-bottom: 40px;
  }

  .nav__overlay__item {
    margin-bottom: 11px;
    font-family: "Helvetica Light", sans-serif;

    .nav__overlay__item__link {
      color: #000;
      font-size: 39px;
    }
  }
}
</style>
