var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "content"
  }, [_c('progress-bar', {
    attrs: {
      "percent": 50
    }
  }), _c('div', {
    staticClass: "info-text"
  }, [_vm._v("Please review connections:")]), _vm.$store.getters.is2P ? _c('div', _vm._l([0, 1, 2, 3, 4], function (index) {
    return _c('UnitInputGroup2P', {
      key: index,
      staticClass: "phase-group",
      attrs: {
        "connection": index * 2 + 1,
        "hint": "",
        "disabled": ""
      },
      model: {
        value: _vm.$store.state.project.connections[index],
        callback: function ($$v) {
          _vm.$set(_vm.$store.state.project.connections, index, $$v);
        },
        expression: "$store.state.project.connections[index]"
      }
    });
  }), 1) : _c('div', _vm._l([0, 1, 2, 3, 4], function (index) {
    return _c('UnitInputGroup3P', {
      key: index,
      staticClass: "phase-group",
      attrs: {
        "connection": index + 1,
        "hint": index === 0,
        "disabled": ""
      },
      model: {
        value: _vm.$store.state.project.connections[index],
        callback: function ($$v) {
          _vm.$set(_vm.$store.state.project.connections, index, $$v);
        },
        expression: "$store.state.project.connections[index]"
      }
    });
  }), 1), _c('div', {
    staticClass: "small-text"
  }, [_vm._v("Please state if all of this data is correct:")]), _c('a-row', {
    attrs: {
      "type": "flex",
      "gutter": 10
    }
  }, [_c('a-col', {
    attrs: {
      "span": 12
    }
  }, [_c('white-button', {
    attrs: {
      "bordered": "",
      "to": "/commission/16"
    }
  }, [_vm._v(" NO ")])], 1), _c('a-col', {
    attrs: {
      "span": 12
    }
  }, [_c('yellow-button', {
    attrs: {
      "data-tn": "phase-type-review-yes"
    },
    on: {
      "click": _vm.navigate
    }
  }, [_vm._v(" YES ")])], 1)], 1), _c('back-button')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }