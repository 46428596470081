var render = function render(){
  var _this = this;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "content"
  }, [_c('back-button'), _c('div', {
    staticClass: "info-text"
  }, [_vm._v("Edit connections:")]), _vm.$store.getters.is2P ? _c('div', [_c('unit-enable-group-2P', {
    ref: "group1",
    staticClass: "phase-group",
    attrs: {
      "connection": 1,
      "enabled": _vm.getEnabled(0),
      "enable-changed": function (v) {
        return _vm.enableChanged(0, v);
      },
      "hint": ""
    },
    model: {
      value: _vm.connection0,
      callback: function ($$v) {
        _vm.connection0 = $$v;
      },
      expression: "connection0"
    }
  }), _c('unit-enable-group-2P', {
    ref: "group2",
    staticClass: "phase-group",
    attrs: {
      "connection": 3,
      "enabled": _vm.getEnabled(1),
      "enable-changed": function (v) {
        return _vm.enableChanged(1, v);
      },
      "hint": ""
    },
    model: {
      value: _vm.connection1,
      callback: function ($$v) {
        _vm.connection1 = $$v;
      },
      expression: "connection1"
    }
  }), _c('unit-enable-group-2P', {
    ref: "group3",
    staticClass: "phase-group",
    attrs: {
      "connection": 5,
      "enabled": _vm.getEnabled(2),
      "enable-changed": function (v) {
        return _vm.enableChanged(2, v);
      },
      "hint": ""
    },
    model: {
      value: _vm.connection2,
      callback: function ($$v) {
        _vm.connection2 = $$v;
      },
      expression: "connection2"
    }
  }), _c('unit-enable-group-2P', {
    ref: "group4",
    staticClass: "phase-group",
    attrs: {
      "connection": 7,
      "enabled": _vm.getEnabled(3),
      "enable-changed": function (v) {
        return _vm.enableChanged(3, v);
      },
      "hint": ""
    },
    model: {
      value: _vm.connection3,
      callback: function ($$v) {
        _vm.connection3 = $$v;
      },
      expression: "connection3"
    }
  }), _c('unit-enable-group-2P', {
    ref: "group5",
    staticClass: "phase-group",
    attrs: {
      "connection": 9,
      "enabled": _vm.getEnabled(4),
      "enable-changed": function (v) {
        return _vm.enableChanged(4, v);
      },
      "hint": ""
    },
    model: {
      value: _vm.connection4,
      callback: function ($$v) {
        _vm.connection4 = $$v;
      },
      expression: "connection4"
    }
  })], 1) : _c('div', [_c('unit-enable-group-3P', {
    ref: "group1",
    staticClass: "phase-group",
    attrs: {
      "connection": 1,
      "enabled": _vm.getEnabled(0),
      "enable-changed": function (v) {
        return _vm.enableChanged(0, v);
      },
      "hint": ""
    },
    model: {
      value: _vm.connection0,
      callback: function ($$v) {
        _vm.connection0 = $$v;
      },
      expression: "connection0"
    }
  }), _c('unit-enable-group-3P', {
    ref: "group2",
    staticClass: "phase-group",
    attrs: {
      "connection": 2,
      "enabled": _vm.getEnabled(1),
      "enable-changed": function (v) {
        return _vm.enableChanged(1, v);
      },
      "hint": ""
    },
    model: {
      value: _vm.connection1,
      callback: function ($$v) {
        _vm.connection1 = $$v;
      },
      expression: "connection1"
    }
  }), _c('unit-enable-group-3P', {
    ref: "group3",
    staticClass: "phase-group",
    attrs: {
      "connection": 3,
      "enabled": _vm.getEnabled(2),
      "enable-changed": function (v) {
        return _vm.enableChanged(2, v);
      },
      "hint": ""
    },
    model: {
      value: _vm.connection2,
      callback: function ($$v) {
        _vm.connection2 = $$v;
      },
      expression: "connection2"
    }
  }), _c('unit-enable-group-3P', {
    ref: "group4",
    staticClass: "phase-group",
    attrs: {
      "connection": 4,
      "enabled": _vm.getEnabled(3),
      "enable-changed": function (v) {
        return _vm.enableChanged(3, v);
      },
      "hint": ""
    },
    model: {
      value: _vm.connection3,
      callback: function ($$v) {
        _vm.connection3 = $$v;
      },
      expression: "connection3"
    }
  }), _c('unit-enable-group-3P', {
    ref: "group5",
    staticClass: "phase-group",
    attrs: {
      "connection": 5,
      "enabled": _vm.getEnabled(4),
      "enable-changed": function (v) {
        return _vm.enableChanged(4, v);
      },
      "hint": ""
    },
    model: {
      value: _vm.connection4,
      callback: function ($$v) {
        _vm.connection4 = $$v;
      },
      expression: "connection4"
    }
  })], 1), !_vm.isValidEnabledArray ? _c('div', {
    staticClass: "error-message"
  }, [_vm._v(" There must be at least one enabled unit on each phase ")]) : _vm._e(), _c('div', [_vm.$store.getters.isCommissioner ? _c('a-row', {
    staticClass: "re-commission",
    attrs: {
      "type": "flex",
      "align": "middle"
    }
  }, [_c('white-button', {
    attrs: {
      "bordered": "",
      "to": "/reset-connections",
      "data-tn": "re-connect-a-unit"
    }
  }, [_vm._v(" ADD A CONNECTION ")])], 1) : _vm._e(), _c('a-row', {
    staticClass: "back-button",
    attrs: {
      "type": "flex",
      "align": "middle"
    }
  }, [_c('a-col', {
    attrs: {
      "span": 12
    }
  }, [_c('div', {
    staticClass: "back-button",
    on: {
      "click": function () {
        _this.$store.commit('resetConnectionFromSystemInfo');
        _this.$router.go(-1);
      }
    }
  }, [_c('a-icon', {
    attrs: {
      "type": "left"
    }
  }), _vm._v(" BACK ")], 1)]), _c('a-col', {
    attrs: {
      "span": 12
    }
  }, [_c('yellow-button', {
    attrs: {
      "loading": _vm.loading
    },
    on: {
      "click": _vm.saveClicked
    }
  }, [_vm._v(" SAVE ")])], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }