<template>
  <div>
    <a-row v-if="hint" type="flex" align="middle" :gutter="[8, 8]">
      <a-col :offset="18" :span="6">
        <div class="small-text">Connected</div>
      </a-col>
    </a-row>

    <a-row
      v-for="phase of PHASE"
      :key="phase"
      type="flex"
      align="middle"
      :gutter="[8, 8]"
    >
      <a-col :span="disabled ? 24 : 18">
        <UnitInputBox
          v-if="phase === PHASE.L1"
          v-model="L1"
          :label="`${labelPrefix(0, $store.getters.isRWB)}${connection}`"
          :color="labelColor(0, $store.getters.isRWB)"
          :phase="1"
          :checked="value[phase] !== NO_CONNECTION"
          :disabled="disabled"
        />
        <UnitInputBox
          v-else-if="phase === PHASE.L2"
          v-model="L2"
          :label="`${labelPrefix(1, $store.getters.isRWB)}${connection}`"
          :color="labelColor(1, $store.getters.isRWB)"
          :phase="2"
          :checked="value[phase] !== NO_CONNECTION"
          :disabled="disabled"
        />
        <UnitInputBox
          v-else-if="phase === PHASE.L3"
          v-model="L3"
          :label="`${labelPrefix(2, $store.getters.isRWB)}${connection}`"
          :color="labelColor(2, $store.getters.isRWB)"
          :phase="3"
          :checked="value[phase] !== NO_CONNECTION"
          :disabled="disabled"
        />
      </a-col>
      <a-col v-if="!disabled" :span="6" class="grey-box">
        <div class="center-box">
          <a-checkbox
            :checked="value[phase] !== NO_CONNECTION"
            @change="() => toggleConnected(phase)"
          />
        </div>
      </a-col>
    </a-row>

    <!--   this form is only for validation purpose   -->
    <a-form-model ref="form" :model="value" :rules="rules">
      <a-form-model-item prop="connections">
        <a-input v-if="false" />
      </a-form-model-item>
    </a-form-model>
  </div>
</template>

<script>
import { PHASE, NO_CONNECTION } from "../util/constants";
import { labelColor, labelPrefix } from "../util/unit-input-label";

export default {
  name: "UnitInputGroup3P",
  props: {
    connection: { type: Number, default: 1 },
    hint: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    value: { type: Object, default: () => ({ L1: "", L2: "", L3: "" }) },
  },
  data() {
    const validateConnection = (rule, value, callback) => {
      const errored = Object.entries(this.value)
        .filter(([_k, v]) => v.length === 0)
        .map(
          ([k, _v]) =>
            `${labelPrefix(Number(k[1]) - 1, this.$store.getters.isRWB)}${
              this.connection
            }`
        );

      if (errored.length > 0) {
        callback(
          new Error(
            `Please input unit connected to ${errored.join(
              ", "
            )}. If no connection, untick the box to the right`
          )
        );
      } else {
        callback();
      }
    };
    return {
      labelColor,
      labelPrefix,
      PHASE,
      NO_CONNECTION,
      rules: {
        connections: [{ validator: validateConnection }],
      },
    };
  },
  computed: {
    L1: {
      get() {
        return this.value.L1;
      },
      set(val) {
        this.$emit("input", { L1: val, L2: this.L2, L3: this.L3 });
      },
    },
    L2: {
      get() {
        return this.value.L2;
      },
      set(val) {
        this.$emit("input", { L1: this.L1, L2: val, L3: this.L3 });
      },
    },
    L3: {
      get() {
        return this.value.L3;
      },
      set(val) {
        this.$emit("input", { L1: this.L1, L2: this.L2, L3: val });
      },
    },
  },
  methods: {
    toggleConnected(phase) {
      if (this[phase] !== NO_CONNECTION) {
        this[phase] = NO_CONNECTION;
      } else {
        this[phase] = "";
      }
    },
    validate(callback) {
      this.$refs.form.validate(callback);
    },
  },
};
</script>

<style lang="less" scoped>
.small-text {
  font-size: 14px;
  text-align: center;
  width: 100%;
}

.grey-box {
  text-align: center;
  background: #f6f6f6;
  height: 50px;

  .center-box {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
