<template>
  <div class="content">
    <progress-bar :percent="100" />
    <CheersSVG class="cheers-icon" />
    <div class="info-text" data-tn="commission-success-success">
      Commissioning <br />successful!
    </div>

    <div class="invite-section">
      <div class="info-text">
        Invite an asset manager to access the Solshare data
      </div>
      <a-form-model
        ref="form"
        :model="form"
        class="email-input-container"
        :rules="{
          email: [
            {
              type: 'email',
              required: true,
              message: 'please input a valid email address',
              trigger: 'blur',
            },
          ],
        }"
        @submit="inviteClicked"
      >
        <a-form-model-item prop="email">
          <div class="input-container">
            <a-input
              v-model="form.email"
              class="input-box"
              size="large"
              placeholder="Input email address"
              data-tn="invite-email"
            />
            <a-button
              html-type="submit"
              data-tn="invite-submit"
              class="invite-button"
              >INVITE</a-button
            >
          </div>
        </a-form-model-item>
        <span>
          If you don't know right now, that's ok. This can be entered later in
          the 'View Fleet' menu.
        </span>
      </a-form-model>
    </div>

    <yellow-button
      to="/congratulations"
      class="yellow-button"
      data-tn="commission-finish"
    >
      FINISH
    </yellow-button>
    <white-button
      bordered
      class="white-button"
      to="/commission/1"
      @click="() => this.$store.commit('resetProject')"
    >
      COMMISSION ANOTHER UNIT
    </white-button>
    <white-button bordered class="white-button" @click="systemInfoClicked">
      <a-icon v-if="loading" type="loading" />
      <span v-else>VIEW SYSTEM INFO</span>
    </white-button>

    <yellow-modal v-model="isModalVisible">
      <div style="margin-bottom: 40px">
        <p>We will send an email to {{ form.email }}.</p>
        <p>
          Are they an officially-appointed asset manager, building manager or
          solar partner providing ongoing maintenance support?
        </p>
      </div>
      <white-button data-tn="confirm-email-send" @click="yesClicked"
        >YES</white-button
      >
    </yellow-modal>
  </div>
</template>

<script>
import CheersSVG from "../assets/svg/cheers.svg";
import { inviteProviderMixin } from "../mixins/inviteProvider";

export default {
  components: { CheersSVG },
  mixins: [inviteProviderMixin],
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    systemInfoClicked() {
      this.loading = true;
      this.$store.dispatch("getSystemInfo", {
        serial: this.$store.state.project.serialNumber,
        redirect: true,
      });
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  text-align: center;

  .cheers-icon {
    margin-top: 47px;
    margin-bottom: 8px;
  }

  .info-text {
    color: #005149;
    margin-bottom: 67px;
  }

  .invite-section {
    .info-text {
      margin-bottom: 20px;
    }

    .email-input-container {
      margin-bottom: 60px;

      .input-container {
        display: flex;
      }

      .invite-button {
        height: 50px;
        width: 140px;
        font-size: 14px;
        border-left: none;
      }
    }
  }

  .yellow-button,
  .white-button {
    margin-bottom: 20px;
  }
}
</style>
