var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "content"
  }, [_c('progress-bar', {
    attrs: {
      "percent": 50
    }
  }), _vm._m(0), _c('green-info-box', {
    staticClass: "green-box"
  }, [_vm._v(" Please note: Your progress will "), _c('span', {
    style: {
      textDecoration: 'underline'
    }
  }, [_vm._v("not")]), _vm._v(" be lost if you exit this page to commission the inverter. ")]), _c('yellow-button', {
    attrs: {
      "bordered": "",
      "to": "/commission/12/0",
      "data-tn": "solshare-connected-configure-button"
    }
  }, [_vm._v(" CONFIGURE YOUR SOLSHARE ")]), _c('back-button')], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "info-text"
  }, [_vm._v(" The SolShare has connected the inverter to the grid. "), _c('br'), _vm._v(" Please commission the inverter before continuing. ")]);

}]

export { render, staticRenderFns }