<template>
  <div class="content">
    <progress-bar :percent="50" />

    <div class="info-text">
      The SolShare has connected the inverter to the grid. <br />
      Please commission the inverter before continuing.
    </div>

    <green-info-box class="green-box">
      Please note: Your progress will
      <span :style="{ textDecoration: 'underline' }">not</span> be lost if you
      exit this page to commission the inverter.
    </green-info-box>

    <yellow-button
      bordered
      to="/commission/12/0"
      data-tn="solshare-connected-configure-button"
    >
      CONFIGURE YOUR SOLSHARE
    </yellow-button>

    <back-button />
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.content {
  .info-text {
    margin-top: 26px;
    margin-bottom: 27px;
  }
  .green-box {
    margin-bottom: 34px;
  }
}
</style>
