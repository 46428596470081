<template>
  <div class="green-info-box">
    <a-row type="flex" justify="start" align="top">
      <a-col :span="3">
        <WarningSVG class="warning-icon" />
      </a-col>
      <a-col :span="21">
        <slot />
      </a-col>
    </a-row>
  </div>
</template>

<script>
import WarningSVG from "../assets/svg/green-i-line.svg";

export default {
  name: "GreenInfoBox",
  components: { WarningSVG },
};
</script>

<style lang="less" scoped>
.green-info-box {
  width: 100%;
  color: #005c30;
  background: #e5f8f0;
  border: 1px solid #005149;
  font-family: "Helvetica Light", sans-serif;
  font-size: 17px;
  line-height: 19px;
  padding: 11px 21px;

  .warning-icon {
    margin-top: 5px;
  }
}
</style>
