var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "box"
  }, [_c('a-icon', {
    style: {
      fontSize: '45px',
      marginBottom: '14px'
    },
    attrs: {
      "component": _vm.getContact(_vm.type).svg
    }
  }), _c('div', {
    staticClass: "contact-title"
  }, [_vm._v(" " + _vm._s(_vm.getContact(_vm.type).title) + " ")]), _c('div', [_c('a', {
    staticClass: "contact-detail",
    attrs: {
      "href": _vm.getContact(_vm.type).href
    }
  }, [_vm._v(_vm._s(_vm.getContact(_vm.type).description))])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }