<template>
  <div class="content">
    <back-button />

    <div class="info-text">Connected Units</div>

    <div v-if="$store.getters.is2P">
      <UnitEnableGroup2P
        v-for="index of [0, 1, 2, 3, 4]"
        :key="index"
        v-model="$store.state.project.connections[index]"
        :enabled="getEnabled(index)"
        :connection="index * 2 + 1"
        class="phase-group"
        hint
        disabled
      />
    </div>

    <div v-else>
      <UnitEnableGroup3P
        v-for="index of [0, 1, 2, 3, 4]"
        :key="index"
        v-model="$store.state.project.connections[index]"
        :enabled="getEnabled(index)"
        :connection="index + 1"
        class="phase-group"
        :hint="index === 0"
        disabled
      />
    </div>

    <a-row type="flex" :gutter="10" align="middle">
      <a-col :span="12">
        <back-button :style="{ marginTop: 0 }" />
      </a-col>
      <a-col :span="12">
        <yellow-button
          to="/edit-connected-units"
          data-tn="edit-connected-units"
        >
          EDIT
        </yellow-button>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { enabledUnitsMixin } from "../mixins/enabledUnits";

export default {
  mixins: [enabledUnitsMixin],
};
</script>

<style lang="less" scoped>
.content {
  .info-text {
    margin-top: 29px;
    margin-bottom: 43px;
  }

  .phase-group {
    margin-bottom: 35px;
  }

  .small-text {
    margin-bottom: 11px;
  }
}
</style>
