var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "content"
  }, [_c('progress-bar', {
    attrs: {
      "percent": 10
    }
  }), _c('SearchSVG', {
    staticClass: "search-icon"
  }), _c('div', {
    staticClass: "info-text"
  }, [_vm._v(" We are searching for your SolShare. This should only take a couple of minutes. ")])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }