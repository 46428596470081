<template>
  <div>
    <a-row v-if="hint" type="flex" align="middle" :gutter="[8, 8]">
      <a-col :offset="16" :span="8">
        <div class="small-text">Enable for Solar</div>
      </a-col>
    </a-row>

    <a-row
      v-for="phase of PHASE"
      :key="phase"
      type="flex"
      align="middle"
      :gutter="[8, 8]"
    >
      <a-col :span="disabled ? 24 : 18">
        <UnitInputBox
          v-if="phase === PHASE.L1"
          v-model="L1"
          :label="connection.toString()"
          :color="labelColor(0, $store.getters.isRWB)"
          :phase="1"
          :checked="shadowEnabled[0]"
          :disabled="disabled"
        />
        <UnitInputBox
          v-else-if="phase === PHASE.L2"
          v-model="L2"
          :label="(connection + 1).toString()"
          :color="labelColor(1, $store.getters.isRWB)"
          :phase="2"
          :checked="shadowEnabled[1]"
          :disabled="disabled"
        />
      </a-col>
      <a-col
        v-if="!disabled && [PHASE.L1, PHASE.L2].includes(phase)"
        :span="6"
        class="grey-box"
      >
        <div class="center-box">
          <a-checkbox
            :checked="shadowEnabled[phaseToIndex(phase)]"
            :disabled="value[phase] === NO_CONNECTION"
            @change="() => toggleConnected(phase)"
          />
        </div>
      </a-col>
    </a-row>

    <!--   this form is only for validation purpose   -->
    <a-form-model ref="form" :model="value" :rules="rules">
      <a-form-model-item prop="connections">
        <a-input v-if="false" />
      </a-form-model-item>
    </a-form-model>
  </div>
</template>

<script>
import { PHASE, NO_CONNECTION } from "../util/constants";
import { labelColor, labelPrefix } from "../util/unit-input-label";

export default {
  name: "UnitEnableGroup2P",
  props: {
    connection: { type: Number, default: 1 },
    hint: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    enabled: { type: Array, default: () => [] },
    value: { type: Object, default: () => ({ L1: "", L2: "" }) },
    enableChanged: { type: Function, default: () => {} },
  },
  data() {
    const validateConnection = (rule, value, callback) => {
      const errored = Object.entries(this.value)
        .filter(([_k, v]) => v.length === 0)
        .map(
          ([k, _v]) =>
            `${labelPrefix(Number(k[1]) - 1, this.$store.getters.isRWB)}${
              this.connection
            }`
        );

      if (errored.length > 0) {
        callback(
          new Error(`Please input unit connected to ${errored.join(", ")}.`)
        );
      } else {
        callback();
      }
    };
    return {
      labelColor,
      labelPrefix,
      PHASE,
      NO_CONNECTION,
      rules: {
        connections: [{ validator: validateConnection }],
      },
      shadowEnabled: [...this.enabled],
    };
  },
  computed: {
    L1: {
      get() {
        return this.value.L1;
      },
      set(val) {
        this.$emit("input", { L1: val, L2: this.L2, L3: this.L3 });
      },
    },
    L2: {
      get() {
        return this.value.L2;
      },
      set(val) {
        this.$emit("input", { L1: this.L1, L2: val, L3: this.L3 });
      },
    },
    L3: {
      get() {
        return this.value.L3;
      },
      set(val) {
        this.$emit("input", { L1: this.L1, L2: this.L2, L3: val });
      },
    },
  },
  methods: {
    toggleConnected(phase) {
      const idx = this.phaseToIndex(phase);
      this.$set(this.shadowEnabled, idx, !this.shadowEnabled[idx]);
      this.enableChanged(this.shadowEnabled);
    },
    validate(callback) {
      this.$refs.form.validate(callback);
    },
    phaseToIndex(phase) {
      return phase === PHASE.L1 ? 0 : 1;
    },
  },
};
</script>

<style lang="less" scoped>
.small-text {
  font-size: 14px;
  text-align: center;
  width: 100%;
}

.grey-box {
  text-align: center;
  background: #f6f6f6;
  height: 50px;

  .center-box {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
