<template>
  <div class="content">
    <div class="info-text">
      To begin, power the SolShare by following the start-up procedure:
    </div>

    <div class="procedure">
      <p class="bold">SolShare Startup Procedure</p>
      <p>
        <span class="bold">1.</span> Turn on DC PV Array Isolator located next
        to Inverter
      </p>
      <p><span class="bold">2.</span> Turn on INVERTER AC ISOLATOR</p>
      <p>
        <span class="bold">3.</span> Turn on SOLSHARE MAINTENANCE ISOLATOR
        (INVERTER) [If present]
      </p>
      <p>
        <span class="bold">4.</span> Ensure MAIN SWITCH (NORMAL SUPPLY) for each
        tenancy are on
      </p>
      <p>
        <span class="bold">5.</span> Turn on MAIN SWITCH (INVERTER SUPPLY) for
        all tenancies connected to SolShare
      </p>
      <p>
        <span class="bold">6.</span> Turn on all SOLSHARE MAINTENANCE ISOLATORS
        (GRID) [if present]
      </p>
    </div>

    <yellow-button
      v-if="!$route.query.info"
      :to="hasSite ? '/my-sites' : '/add-a-site'"
      data-tn="begin-commissioning"
    >
      BEGIN COMMISSIONING
    </yellow-button>
    <back-button />
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {
    hasSite() {
      return this.$store.state.fleet.length > 0;
    },
  },
  mounted() {
    this.$store.dispatch("getFleets");
  },
};
</script>

<style lang="less" scoped>
.content {
  padding-left: 28px;
  padding-right: 28px;

  .info-text {
    margin-top: 60px;
    margin-bottom: 26px;
    width: 272px;
  }

  .procedure {
    margin-bottom: 34px;
  }
}
</style>
