var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "content"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v("Reset password")]), _c('a-form-model', {
    ref: "form",
    attrs: {
      "model": _vm.form,
      "layout": "vertical",
      "rules": _vm.rules
    }
  }, [_c('a-form-model-item', {
    attrs: {
      "label": "Password",
      "prop": "password"
    }
  }, [_c('a-input-password', {
    staticClass: "input-box",
    attrs: {
      "size": "large"
    },
    model: {
      value: _vm.form.password,
      callback: function ($$v) {
        _vm.$set(_vm.form, "password", $$v);
      },
      expression: "form.password"
    }
  })], 1), _c('a-form-model-item', {
    attrs: {
      "label": "Re-enter password",
      "prop": "passwordConfirm"
    }
  }, [_c('a-input-password', {
    staticClass: "input-box",
    attrs: {
      "size": "large"
    },
    model: {
      value: _vm.form.passwordConfirm,
      callback: function ($$v) {
        _vm.$set(_vm.form, "passwordConfirm", $$v);
      },
      expression: "form.passwordConfirm"
    }
  })], 1)], 1), _c('yellow-button', {
    staticClass: "submit",
    attrs: {
      "loading": _vm.loading
    },
    on: {
      "click": _vm.submitClicked
    }
  }, [_vm._v(" Submit ")])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }