var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "wrapper"
  }, _vm._l(_vm.connections, function (conn) {
    return _c('a-row', {
      key: conn,
      attrs: {
        "gutter": [6, 6]
      }
    }, [_c('a-col', {
      attrs: {
        "span": _vm.shouldDisplayWeightInput(conn) || _vm.isCalculated(conn) ? 16 : 24
      }
    }, [_c('UnitInputBox', {
      attrs: {
        "label": _vm.$store.getters.is2P ? `${_vm.labelPrefix(_vm.phaseIdx - 1, _vm.$store.getters.isRWB)}${_vm.phaseIdx === 1 ? 2 * conn + 1 : 2 * (conn + 1)}` : `${_vm.labelPrefix(_vm.phaseIdx - 1, _vm.$store.getters.isRWB)}${conn + 1}`,
        "color": _vm.labelColor(_vm.phaseIdx - 1, _vm.$store.getters.isRWB),
        "readonly": "",
        "phase": _vm.phaseIdx
      },
      model: {
        value: _vm.$store.state.project.connections[conn][`L${_vm.phaseIdx}`],
        callback: function ($$v) {
          _vm.$set(_vm.$store.state.project.connections[conn], `L${_vm.phaseIdx}`, $$v);
        },
        expression: "$store.state.project.connections[conn][`L${phaseIdx}`]"
      }
    })], 1), _vm.shouldDisplayWeightInput(conn) ? _c('a-col', {
      attrs: {
        "span": 8
      }
    }, [_c('div', {
      staticClass: "input-wrapper"
    }, [_c('a-input-number', {
      staticClass: "input",
      attrs: {
        "size": "large",
        "precision": 2,
        "min": 0,
        "disabled": _vm.readonly || !_vm.isEnabled(conn)
      },
      model: {
        value: _vm.form.weights[conn],
        callback: function ($$v) {
          _vm.$set(_vm.form.weights, conn, $$v);
        },
        expression: "form.weights[conn]"
      }
    }), _c('span', {
      staticClass: "suffix"
    }, [_vm._v(_vm._s(_vm.suffix))])], 1)]) : _vm._e(), _vm.isCalculated(conn) ? _c('a-col', {
      attrs: {
        "span": 8
      }
    }, [_c('div', {
      staticClass: "calculated-wrapper",
      attrs: {
        "disabled": ""
      }
    }, [_c('span', {
      staticClass: "calculated-text"
    }, [_vm._v(" " + _vm._s(_vm.calculatedText) + " ")])])]) : _vm._e()], 1);
  }), 1), _c('a-form-model', {
    ref: "form",
    attrs: {
      "model": _vm.form,
      "rules": _vm.rules
    }
  }, [_c('a-form-model-item', {
    attrs: {
      "prop": "connections"
    }
  }, [false ? _c('a-input') : _vm._e()], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }