<template>
  <div class="content">
    <div class="title">App feedback</div>

    <green-info-box class="info-box">
      You will not lose your progress in the commissioning by giving feedback
      here.
    </green-info-box>

    <p>Help us improve:</p>

    <div class="grey-box">
      <p>Simply flag an issue with this part of the commissioning app:</p>
      <yellow-button :loading="flagLoading" @click="flagIssueClicked">
        <FlagSVG class="flag-icon" /><span> FLAG ISSUE</span>
      </yellow-button>
      <p class="small-text">and return to the commissioning process.</p>
    </div>

    <p class="big-or">OR</p>

    <div class="grey-box">
      <p>Write us a comment:</p>
      <a-textarea
        v-model.trim="comment"
        placeholder="I have experienced an issue with..."
        class="comment-box"
        data-tn="feedback-text-area"
      />

      <yellow-button
        :loading="submitLoading"
        data-tn="feedback-submit"
        @click="submitClicked"
      >
        SUBMIT FEEDBACK
      </yellow-button>
      <p class="small-text">and return to the commissioning process.</p>
    </div>

    <back-button text="cancel" />
  </div>
</template>

<script>
import FlagSVG from "../assets/svg/flag.svg";

export default {
  components: { FlagSVG },
  data() {
    return {
      comment: "",
      submitLoading: false,
      flagLoading: false,
    };
  },
  methods: {
    async flagIssueClicked() {
      this.flagLoading = true;
      await this.$http.post(this.$api.feedback, {
        environment: navigator.userAgent,
        comment: `error flagged`,
        page: this.$store.state.step,
        meter: this.$store.state.project.serialNumber,
      });
      await this.$router.replace("/feedback/2");
      this.flagLoading = false;
    },
    async submitClicked() {
      if (this.comment !== "") {
        this.submitLoading = true;
        await this.$http.post(this.$api.feedback, {
          environment: navigator.userAgent,
          comment: this.comment,
          page: this.$store.state.step,
          meter: this.$store.state.project.serialNumber,
        });
        await this.$router.replace("/feedback/2");
        this.submitLoading = false;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  .title {
    margin-bottom: 37px;
  }
  .info-box {
    margin-bottom: 25px;
  }

  .grey-box {
    padding: 16px 18px;
    background: #f3f4f4;

    .small-text {
      font-size: 13px;
      margin-top: 4px;
      text-align: center;
    }

    .flag-icon {
      margin-right: 10px;
    }

    .comment-box {
      height: 154px;
      margin-bottom: 13px;
      background: #fff;
    }
  }

  .big-or {
    font-size: 18px;
    text-align: center;
    margin: 6px 0 10px 0;
  }
}
</style>
