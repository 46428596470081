var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "content"
  }, [_c('back-button'), _c('div', {
    staticClass: "title"
  }, [_vm._v(" " + _vm._s(_vm.$store.state.wifiStatus.connected ? "Connected to:" : "Last connected to:") + " ")]), _c('green-border-box', [_vm._v(" " + _vm._s(_vm.$store.state.wifiStatus.ssid) + " ")]), _c('div', {
    staticClass: "wifi-status"
  }, [_vm.$store.state.wifiStatus.connected ? _c('WifiOkSVG') : _c('WifiNoGoodSVG')], 1), _c('div', {
    staticClass: "divider"
  }), _c('div', [_c('a', {
    attrs: {
      "href": "//public-res.s3.ap-southeast-2.amazonaws.com/0339_A1_Changing+Wi-Fi+credentials.pdf",
      "target": "_blank"
    }
  }, [_c('a-row', {
    staticClass: "green-box",
    attrs: {
      "type": "flex",
      "align": "middle"
    }
  }, [_c('a-col', {
    attrs: {
      "span": 6
    }
  }, [_c('InfoCentreSVG')], 1), _c('a-col', {
    attrs: {
      "span": 18
    }
  }, [_vm._v(" How to change Wi-Fi network ")])], 1)], 1)]), _c('back-button')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }