<template>
  <div class="content">
    <back-button />
    <div class="info-text">Solar Delivery Method</div>

    <div class="solar-wrapper">
      <div class="label">Method</div>
      <a-select
        v-model="form.algorithmType"
        style="width: 100%"
        size="large"
        disabled
      >
        <template #suffixIcon>
          <ArrowDropdown />
        </template>

        <a-select-option :value="ALGORITHM_TYPE.DEMAND_BASED_DELIVERY">
          Demand-based delivery
        </a-select-option>

        <a-select-option :value="ALGORITHM_TYPE.CUSTOM_ALLOCATION">
          Custom allocation
        </a-select-option>
      </a-select>
    </div>

    <div
      v-if="
        form.algorithmType === ALGORITHM_TYPE.CUSTOM_ALLOCATION &&
        $store.getters.isUnequalAlgorithm
      "
      class="unequal-wrapper"
    >
      <div class="label">Input Unit</div>
      <a-radio-group v-model="suffix" size="large" class="radio-group">
        <a-radio-button
          :value="UNEQUAL_ALLOCATION_TYPE.PERCENT"
          class="radio-button"
        >
          %
        </a-radio-button>
        <a-radio-button
          :value="UNEQUAL_ALLOCATION_TYPE.KW"
          class="radio-button"
        >
          kW
        </a-radio-button>
      </a-radio-group>

      <div
        v-for="index of $store.getters.is2P ? [0, 1] : [0, 1, 2]"
        :key="index"
      >
        <div class="form-label">
          <span>Phase {{ index + 1 }}</span>
          <span class="total-kw">
            {{
              roundToDown(
                $store.state.project.spec.dcSize /
                  ($store.getters.is2P ? 2 : 3),
                2
              )
            }}kW available
          </span>
        </div>

        <UnitInputGroupUnequalAllocation
          ref="group"
          v-model="$store.state.project.algorithmWeight[index]"
          :phase-idx="index + 1"
          :suffix="suffix"
          :available="
            $store.state.project.spec.dcSize / ($store.getters.is2P ? 2 : 3)
          "
          :enabled-units="
            $store.state.systemInfo?.config?.enabled_units?.[index]
          "
          readonly
          default-even
        />
      </div>
    </div>

    <a-row type="flex" align="middle" class="back-button">
      <a-col :span="12">
        <back-button :style="{ marginTop: 0 }" />
      </a-col>
      <a-col :span="12">
        <yellow-button
          @click="() => this.$router.push('/edit-solar-deliver-method')"
        >
          EDIT
        </yellow-button>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import {
  SOLAR_DELIVERY_METHOD,
  UNEQUAL_ALLOCATION_TYPE,
} from "../util/constants";
import { roundToDown } from "../util/round";

import ArrowDropdown from "../assets/svg/arrow-dropdown.svg";
import { solarDeliveryMethodMixin } from "../mixins/solarDeliveryMethod";
export default {
  components: { ArrowDropdown },
  mixins: [solarDeliveryMethodMixin],
  data() {
    return {
      roundToDown,
      SOLAR_DELIVERY_METHOD,
      UNEQUAL_ALLOCATION_TYPE,
      suffix: UNEQUAL_ALLOCATION_TYPE.PERCENT,
      form: {
        algorithmType: this.getAlgorithmTypeFromSolarDeliveryMethod(
          this.$store.state.systemInfo.config.algorithm_type
        ),
      },
    };
  },
};
</script>

<style lang="less" scoped>
.content {
  .info-text {
    margin-top: 29px;
    margin-bottom: 30px;
  }

  .label {
    font-size: 17px;
    margin-bottom: 3px;
  }

  .back-button {
    margin-top: 27px;
  }

  /deep/ .ant-select-arrow {
    margin-top: 2px;
  }

  .solar-wrapper {
    margin-bottom: 25px;
  }

  .radio-group {
    width: 100%;
    display: flex;
    margin-bottom: 35px;

    .radio-button {
      width: 50%;
      color: black;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: none;

      span {
        height: 60px;
      }
    }

    .ant-radio-button-wrapper-checked {
      background: #ffe86c;
    }
  }

  .form-label {
    display: flex;
    justify-content: space-between;
    color: rgba(0, 0, 0, 0.85);
    margin-bottom: 8px;

    .total-kw {
      color: #aaa;
      font-style: italic;
    }
  }
}
</style>
