<template>
  <a-button
    :data-tn="dataTn"
    shape="round"
    class="yellow-button"
    :block="block"
    size="large"
    :loading="loading"
    :disabled="disabled"
    :html-type="htmlType"
    @click="onClick"
  >
    <slot />
  </a-button>
</template>

<script>
export default {
  name: "YellowButton",
  props: {
    to: { type: String, default: null },
    replace: { type: String, default: null },
    go: { type: Number, default: null },
    block: { type: Boolean, default: true },
    loading: { type: Boolean, default: false },
    dataTn: { type: String, default: null },
    disabled: { type: Boolean, default: false },
    htmlType: { type: String, default: null },
  },
  methods: {
    onClick() {
      if (this.to) {
        this.$router.push(this.to);
      } else if (this.replace) {
        this.$router.replace(this.replace);
      } else if (this.go) {
        this.$router.go(this.go);
      }
      this.$emit("click");
    },
  },
};
</script>

<style scoped>
.yellow-button {
  background: #ffe86c;
  border: 0;
  font-family: Helvetica, sans-serif;
  letter-spacing: 2px;
  font-size: 14px;
  height: 47px;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: break-spaces;
}
</style>
