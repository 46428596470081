<template>
  <div class="content">
    <progress-bar :percent="20" />

    <div class="center">
      <CrossSvg class="cross" />
      <div class="text">Oh no! the update wasn't successful</div>
    </div>

    <yellow-button
      class="try-again"
      :loading="loading"
      @click="tryAgainClicked"
    >
      OK, LET'S TRY AGAIN
    </yellow-button>
    <white-button
      v-if="$store.state.softwareUpdate.forceUpdate === false"
      class="continue"
      bordered
      to="/commission/10"
    >
      CONTINUE ANYWAY
    </white-button>

    <back-button />
  </div>
</template>

<script>
import CrossSvg from "../assets/svg/cross.svg";
export default {
  name: "SoftwareUpdateFailed",
  components: {
    CrossSvg,
  },
  data() {
    return { loading: false };
  },
  methods: {
    tryAgainClicked() {
      this.loading = true;
      this.$store.dispatch("softwareUpdateTryAgain");
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  .cross {
    margin-top: 64px;
    margin-bottom: 22px;
  }

  .center {
    text-align: center;

    .text {
      font-size: 25px;
      line-height: 29px;
      color: #005149;
      font-family: "Helvetica Light", sans-serif;
      width: 272px;
      margin: 0 auto 47px auto;
    }
  }

  .try-again {
    margin-bottom: 13px;
  }

  .continue {
    margin-bottom: 92px;
  }
}
</style>
