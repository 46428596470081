var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "content"
  }, [_c('back-button'), _c('div', {
    staticClass: "info-text"
  }, [_vm._v("Connected Units")]), _vm.$store.getters.is2P ? _c('div', _vm._l([0, 1, 2, 3, 4], function (index) {
    return _c('UnitEnableGroup2P', {
      key: index,
      staticClass: "phase-group",
      attrs: {
        "enabled": _vm.getEnabled(index),
        "connection": index * 2 + 1,
        "hint": "",
        "disabled": ""
      },
      model: {
        value: _vm.$store.state.project.connections[index],
        callback: function ($$v) {
          _vm.$set(_vm.$store.state.project.connections, index, $$v);
        },
        expression: "$store.state.project.connections[index]"
      }
    });
  }), 1) : _c('div', _vm._l([0, 1, 2, 3, 4], function (index) {
    return _c('UnitEnableGroup3P', {
      key: index,
      staticClass: "phase-group",
      attrs: {
        "enabled": _vm.getEnabled(index),
        "connection": index + 1,
        "hint": index === 0,
        "disabled": ""
      },
      model: {
        value: _vm.$store.state.project.connections[index],
        callback: function ($$v) {
          _vm.$set(_vm.$store.state.project.connections, index, $$v);
        },
        expression: "$store.state.project.connections[index]"
      }
    });
  }), 1), _c('a-row', {
    attrs: {
      "type": "flex",
      "gutter": 10,
      "align": "middle"
    }
  }, [_c('a-col', {
    attrs: {
      "span": 12
    }
  }, [_c('back-button', {
    style: {
      marginTop: 0
    }
  })], 1), _c('a-col', {
    attrs: {
      "span": 12
    }
  }, [_c('yellow-button', {
    attrs: {
      "to": "/edit-connected-units",
      "data-tn": "edit-connected-units"
    }
  }, [_vm._v(" EDIT ")])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }