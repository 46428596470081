<template>
  <div class="content">
    <progress-bar :percent="50" />

    <!--  2 phase  -->
    <div v-if="$store.getters.is2P">
      <div class="info-text">
        Are outputs {{ Number($route.params.index) * 2 + 1 }}(L1) &
        {{ Number($route.params.index) * 2 + 2 }}(L2) connected as:
      </div>

      <BlackISVG class="info-icon" @click="noteVisible = true" />

      <white-button
        bordered
        class="white-button"
        :selected="previouslySelectedType === CONNECTION_TYPE.THREE_SINGLE"
        data-tn="connection-three-single"
        @click="threeSingleClicked"
      >
        2 SEPARATE UNITS
      </white-button>

      <div class="small-text" />

      <white-button
        bordered
        class="white-button"
        :selected="previouslySelectedType === CONNECTION_TYPE.ONE_TRIPLE"
        data-tn="connection-one-triple"
        @click="oneThreeClicked"
      >
        1 UNIT
      </white-button>
      <div class="small-text" />

      <white-button
        v-if="Number($route.params.index) !== 0"
        bordered
        class="white-button"
        :selected="previouslySelectedType === CONNECTION_TYPE.NO_CONNECTION"
        data-tn="no-connection"
        @click="noConnectionClicked"
      >
        NO CONNECTION
      </white-button>
      <div v-if="Number($route.params.index) !== 0" class="small-text">
        No line connected to solar
      </div>
    </div>

    <!--   3 phase   -->
    <div v-else>
      <div class="info-text">
        Is {{ labelPrefix(0, $store.getters.isRWB)
        }}{{ Number($route.params.index) + 1 }},
        {{ labelPrefix(1, $store.getters.isRWB)
        }}{{ Number($route.params.index) + 1 }},
        {{ labelPrefix(2, $store.getters.isRWB)
        }}{{ Number($route.params.index) + 1 }}
        output connected as:
      </div>

      <BlackISVG class="info-icon" @click="noteVisible = true" />

      <white-button
        bordered
        class="white-button"
        :data-tn="`phase-type-three-single-button-${index}`"
        :selected="previouslySelectedType === CONNECTION_TYPE.THREE_SINGLE"
        @click="threeSingleClicked"
      >
        3 x SINGLE PHASE
      </white-button>

      <div class="small-text" />

      <white-button
        bordered
        class="white-button"
        :data-tn="`phase-type-one-three-button-${index}`"
        :selected="previouslySelectedType === CONNECTION_TYPE.ONE_TRIPLE"
        @click="oneThreeClicked"
      >
        1 x THREE PHASE
      </white-button>

      <div class="small-text" />

      <white-button
        v-if="Number($route.params.index) !== 0"
        bordered
        class="white-button"
        :data-tn="`no-connection`"
        :selected="previouslySelectedType === CONNECTION_TYPE.NO_CONNECTION"
        @click="noConnectionClicked"
      >
        NO CONNECTION
      </white-button>
    </div>

    <back-button v-if="previouslySelectedType === ''" class="back" />
    <back-and-next
      v-else
      class="back"
      :next-on-click="navigate"
      data-tn="phase-type-choose-next"
    />

    <yellow-modal v-model="noteVisible" icon padding="62px 26px 21px 26px">
      <div :style="{ fontSize: '21px', marginBottom: '1em' }">
        If the R1, W1, B1 output from the SolShare is connected to individual
        units, select <span class="bold">3 x single-phase</span>. <br />
        If they're connected to a single, three-phase unit, select
        <span class="bold"> 1 x three-phase </span>.
      </div>
    </yellow-modal>
  </div>
</template>

<script>
import BlackISVG from "../assets/svg/black-i-filled.svg";
import { CONNECTION_TYPE, NO_CONNECTION } from "../util/constants";
import { labelPrefix } from "../util/unit-input-label";

export default {
  components: { BlackISVG },
  data() {
    return {
      labelPrefix,
      CONNECTION_TYPE,
      noteVisible: false,
    };
  },
  computed: {
    index() {
      return Number(this.$route.params.index);
    },
    previouslySelectedType() {
      return this.$store.state.project.connections[this.index].type;
    },
  },
  methods: {
    threeSingleClicked() {
      this.$store.commit("setConnectionType", {
        index: this.index,
        type: CONNECTION_TYPE.THREE_SINGLE,
      });
    },
    oneThreeClicked() {
      this.$store.commit("setConnectionType", {
        index: this.index,
        type: CONNECTION_TYPE.ONE_TRIPLE,
      });
    },
    noConnectionClicked() {
      this.$store.commit("setConnection", {
        index: this.index,
        connection: {
          type: CONNECTION_TYPE.NO_CONNECTION,
          L1: NO_CONNECTION,
          L2: NO_CONNECTION,
          L3: NO_CONNECTION,
        },
      });
    },
    navigate() {
      switch (this.$store.state.project.connections[this.index].type) {
        case CONNECTION_TYPE.NO_CONNECTION:
          if (this.index === 4) {
            this.$router.push(`/commission/15`);
          } else {
            this.$router.push(`/commission/12/${this.index + 1}`);
          }
          return;
        case CONNECTION_TYPE.THREE_SINGLE:
          this.$router.push(`/commission/13/${this.index}`);
          return;
        case CONNECTION_TYPE.ONE_TRIPLE:
          this.$router.push(`/commission/14/${this.index}`);
          return;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  .info-text {
    margin-top: 29px;
    margin-bottom: 10px;
  }

  .info-icon {
    margin-bottom: 20px;
  }

  .small-text {
    margin-bottom: 13px;
    text-align: center;
  }

  .back {
    margin-top: 20px;
  }
}
</style>
