import styled from "vue-styled-components";
import { Input } from "ant-design-vue";

export const WhiteInput = styled(Input)`
  .ant-input-group-addon {
    border-radius: 5px 0 0 5px;
    width: 46px;
    padding: 0;
  }
`;

export const GreenInput1 = styled(Input)`
  .ant-input-group-addon {
    border-radius: 5px 0 0 5px;
    width: 46px;
    padding: 0;
    background-color: #e7f7f1 !important;
  }
`;

export const GreenInput2 = styled(Input)`
  .ant-input-group-addon {
    border-radius: 5px 0 0 5px;
    width: 46px;
    padding: 0;
    background-color: #c7edde !important;
  }
`;

export const RedInput = styled(Input)`
  .ant-input-group-addon {
    border-color: #f00;
    color: #f00;
    border-radius: 5px 0 0 5px;
    width: 46px;
    padding: 0;
  }
`;

export const BlueInput = styled(Input)`
  .ant-input-group-addon {
    border-color: #31adff;
    color: #0091ff;
    border-radius: 5px 0 0 5px;
    width: 46px;
    padding: 0;
  }
`;
