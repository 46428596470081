var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "content"
  }, [_vm._m(0), _c('img', {
    staticClass: "safety-label",
    attrs: {
      "src": require("../assets/png/safety-label-1.png")
    }
  }), _vm._m(1), _c('img', {
    staticClass: "safety-label",
    attrs: {
      "src": require("../assets/png/safety-label-2.png")
    }
  }), !_vm.$route.query.info ? _c('yellow-button', {
    attrs: {
      "to": "/commission/1",
      "data-tn": "safety-label-confirm"
    }
  }, [_vm._v(" CONFIRM ")]) : _vm._e(), _c('back-button')], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "text-1"
  }, [_vm._v(" Before commissioning ensure that this label is placed "), _c('span', {
    staticClass: "bold"
  }, [_vm._v("on the electricity meter panel")]), _vm._v(": ")]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "text-2"
  }, [_vm._v(" And this label is placed "), _c('span', {
    staticClass: "bold"
  }, [_vm._v("adjacent the Tenancy Main Switches")])]);

}]

export { render, staticRenderFns }