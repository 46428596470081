export const project = Object.freeze({
  serialNumber: "",
  solarDeliveryMethod: null,
  CTRating: null,
  address: {
    solshareName: null,
    address: "",
    latitude: null,
    longitude: null,
    country: null,
  },
  spec: {
    dcSize: "",
    acSize: "",
    inverter: "",
  },
  connections: [
    { type: "", L1: "", L2: "", L3: "" },
    { type: "", L1: "", L2: "", L3: "" },
    { type: "", L1: "", L2: "", L3: "" },
    { type: "", L1: "", L2: "", L3: "" },
    { type: "", L1: "", L2: "", L3: "" },
  ],
  algorithmWeight: [
    [
      [0, 0, null],
      [0, 1, null],
      [0, 2, null],
      [0, 3, null],
      [0, 4, null],
    ],
    [
      [1, 0, null],
      [1, 1, null],
      [1, 2, null],
      [1, 3, null],
      [1, 4, null],
    ],
    [
      [2, 0, null],
      [2, 1, null],
      [2, 2, null],
      [2, 3, null],
      [2, 4, null],
    ],
  ],
});
