var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "content"
  }, [_c('progress-bar', {
    attrs: {
      "percent": 10
    }
  }), _c('div', {
    staticClass: "info-text"
  }, [_vm._v("Entering unit serial number manually.")]), _c('BlackISVG', {
    staticClass: "info-icon",
    on: {
      "click": function ($event) {
        _vm.noteVisible = true;
      }
    }
  }), _c('a-form-model', {
    ref: "form",
    attrs: {
      "model": _vm.form,
      "rules": _vm.rules,
      "layout": "vertical"
    }
  }, [_c('a-form-model-item', {
    attrs: {
      "prop": "serial",
      "label": "Please enter below the serial number located on the side of this SolShare:"
    }
  }, [_c('a-input', {
    staticClass: "input-box",
    attrs: {
      "size": "large",
      "placeholder": "3P-35A-XXXX",
      "data-tn": "enter-serial-input"
    },
    model: {
      value: _vm.form.serial,
      callback: function ($$v) {
        _vm.$set(_vm.form, "serial", $$v);
      },
      expression: "form.serial"
    }
  })], 1)], 1), _c('yellow-button', {
    staticClass: "manual-button",
    attrs: {
      "data-tn": "enter-serial-confirm",
      "loading": _vm.loading
    },
    on: {
      "click": _vm.confirmClicked
    }
  }, [_vm._v(" CONFIRM ")]), _c('back-button'), _c('yellow-modal', {
    attrs: {
      "icon": "",
      "padding": "62px 26px 21px 26px"
    },
    scopedSlots: _vm._u([{
      key: "footer",
      fn: function () {
        return [_c('img', {
          style: {
            width: '100%'
          },
          attrs: {
            "src": require("../assets/png/serial-location.png")
          }
        })];
      },
      proxy: true
    }]),
    model: {
      value: _vm.noteVisible,
      callback: function ($$v) {
        _vm.noteVisible = $$v;
      },
      expression: "noteVisible"
    }
  }, [_c('div', {
    style: {
      fontSize: '21px',
      marginBottom: '1em'
    }
  }, [_vm._v(" The serial number can be found on the bottom right hand side of the SolShare. ")])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }