<template>
  <component
    :is="label.startsWith('L') ? phaseIdxToColor(phase) : coloredInput[color]"
    v-model="text"
    size="large"
    class="input-box"
    placeholder="Type in unit name"
    :disabled="disabled || !checked"
    :data-tn="`unit-input-${label}`"
  >
    <template #addonBefore>
      <span class="label"> {{ label }} </span>
    </template>
    <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
      <slot :name="slot" v-bind="scope" />
    </template>
    <template v-if="disabled" #suffix>
      <a-checkbox class="checkbox" :checked="checked" />
    </template>
  </component>
</template>

<script>
import {
  BlueInput,
  GreenInput1,
  GreenInput2,
  RedInput,
  WhiteInput,
} from "./StyledInput";
import { COLORS } from "../util/constants";

export default {
  name: "UnitInputBox",
  components: { WhiteInput, BlueInput, RedInput },
  props: {
    label: { type: String, default: "1" },
    value: { type: String, default: "" },
    color: { type: String, default: COLORS.WHITE },
    phase: { type: Number, default: 1 },
    checked: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
  },
  data() {
    return {
      coloredInput: {
        [COLORS.WHITE]: WhiteInput,
        [COLORS.RED]: RedInput,
        [COLORS.BLUE]: BlueInput,
        [COLORS.GREEN1]: GreenInput1,
        [COLORS.GREEN2]: GreenInput2,
      },
    };
  },
  computed: {
    text: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    phaseIdxToColor(phase) {
      switch (phase) {
        case 1:
          return WhiteInput;
        case 2:
          return GreenInput1;
        case 3:
          return GreenInput2;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.label {
  font-size: 19px;
  font-family: "Helvetica Light", sans-serif;
}
.checkbox {
  margin-right: 14px;
}
</style>
