<template>
  <div class="content">
    <progress-bar :percent="70" />
    <FlashSVG class="flash-icon" />

    <yellow-button
      data-tn="commission-solshare-button"
      @click="commissionClicked"
    >
      COMMISSION SOLSHARE
    </yellow-button>
    <back-button :style="{ textAlign: 'left' }" />
  </div>
</template>

<script>
import FlashSVG from "../assets/svg/flash.svg";

export default {
  components: { FlashSVG },
  methods: {
    commissionClicked() {
      this.$store.dispatch("commission", { uploadConfig: true });
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  text-align: center;

  .flash-icon {
    margin-top: 45px;
    margin-bottom: 46px;
  }
}
</style>
