var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('a-button', {
    staticClass: "white-button",
    style: {
      border: _vm.bordered ? '1px solid #005149' : 0,
      background: _vm.selected ? _vm.textColor : _vm.bgColor,
      color: _vm.selected ? _vm.bgColor : _vm.textColor
    },
    attrs: {
      "data-tn": _vm.dataTn,
      "shape": "round",
      "block": _vm.block,
      "size": "large",
      "loading": _vm.loading
    },
    on: {
      "click": _vm.onClick
    }
  }, [_vm._t("default")], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }